<template>
  <div class="reload-step step--with-stepper">
    <!--  TODO uncomment this when backend implements filtering by order type and
          add auto-filling recent form data -->
    <!-- <info-box class="info-box--yellow" >
      Dane uzupełniają się automatycznie na podstawie poprzedniego zamówienia.
      Przed przejściem do kolejnego kroku upewnij się, że dane są prawidłowe.
    </info-box> -->

    <h2 class="step-form__title">Dane</h2>
    <!-- TODO: Add a subtitle here when we have copy -->

    <dynamic-list
      ref="dynamicStepList"
      :steps="steps"
      :loading.sync="loading"
    />
    <div class="buttons-container">
      <button class="button--secondary" @click="handlePreviousReloadFormStep">
        Wstecz
      </button>
      <button
        class="button--primary"
        :disabled="loading"
        @click="handleGoToNextStep"
      >
        Dalej
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DynamicList from "@/views/components/formFields/DynamicList.vue";
import ReloadOrderInvoiceDetails from "@/views/components/formFields/invoice_details/ReloadOrderInvoiceDetails.vue";
import ReloadOrderInvoiceRecipientDetails from "@/views/components/formFields/invoice_details/ReloadOrderInvoiceRecipientDetails.vue";
import ReloadOrderOrderingPerson from "@/views/components/formFields/ordering_person/ReloadOrderOrderingPerson.vue";
import ReloadCardOrderAdditionalDetails from "@/views/components/formFields/additional_details/ReloadCardOrderAdditionalDetails.vue";
import iconCardInvoice from "@/images/icon-card-invoice.svg";
import iconUser from "@/images/icon-user.svg";
import iconRecipient from "@/images/icon-recipient.svg";
import iconMoreData from "@/images/icon-user-more.svg";

export default {
  name: "FormReloadStep2",
  components: {
    DynamicList,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("reloads", ["getActiveReloadFormInvoiceOptions"]),
    steps() {
      return [
        {
          icon: iconCardInvoice,
          name: "Dane do faktury",
          component: ReloadOrderInvoiceDetails,
        },
        {
          icon: iconUser,
          name: "Osoba składająca zamówienie",
          component: ReloadOrderOrderingPerson,
        },
        {
          icon: iconRecipient,
          name: "Dane odbiorcy",
          component: ReloadOrderInvoiceRecipientDetails,
          hide: !this.getActiveReloadFormInvoiceOptions.showRecipient,
        },
        {
          icon: iconMoreData,
          name: "Informacja o rachunku bankowym",
          component: ReloadCardOrderAdditionalDetails,
        },
      ];
    },
  },
  async beforeMount() {
    this.reloadActiveReloadFormData();
  },
  methods: {
    ...mapActions("reloads", [
      "reloadActiveReloadFormData",
      "handlePreviousReloadFormStep",
      "handleNextReloadFormStep",
    ]),
    async handleGoToNextStep() {
      this.loading = true;
      try {
        await this.$refs.dynamicStepList.validate();
        this.handleNextReloadFormStep();
      } catch (error) {
        if (error.name !== "DynamicListValidationError") {
          throw error;
        }
        console.error({
          message: "Form reload step 2 dynamic list validation error",
          error,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
