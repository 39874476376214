var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nof-add-cards"},[_c('h2',{staticClass:"step-form__title"},[_vm._v("Zdefiniuj karty")]),_vm._l((_vm.cards),function(card,cardIndex){return _c('div',{key:`card-row-${cardIndex}`,staticClass:"form-group-double card-row"},[_c('text-input',{staticClass:"card-row__input",attrs:{"value":_vm.cards[cardIndex].quantity,"errors":_vm.getCardErrors(cardIndex, 'quantity'),"input-type":"number"},on:{"input":(value) =>
          _vm.handleCardInput({
            key: 'quantity',
            value,
            cardIndex,
          })}},[_vm._v(" Liczba kart ")]),_c('text-input',{staticClass:"card-row__input",attrs:{"value":_vm.cards[cardIndex].amount,"errors":_vm.getCardErrors(cardIndex, 'amount'),"input-type":"number"},on:{"input":(value) =>
          _vm.handleCardInput({
            key: 'amount',
            value,
            cardIndex,
          })}},[_vm._v(" Wartość doładowania ")]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(cardIndex > 0),expression:"cardIndex > 0"}],staticClass:"icon-trash",attrs:{"src":require("@/images/icon-trash.svg"),"alt":""},on:{"click":() => _vm.handleDeleteCardRow(cardIndex)}})],1)}),_c('div',{staticClass:"add-card-row",on:{"click":_vm.handleAddCardRow}},[_c('img',{staticClass:"icon-add",attrs:{"src":require("@/images/icon-add.svg"),"alt":""}}),_c('p',{staticClass:"add-card-row__text"},[_vm._v("Dodaj kolejny wiersz")])]),_c('div',{staticClass:"buttons-container--center"},[_c('action-button',{attrs:{"loading":_vm.loadingAddedCards},on:{"click":_vm.addCards}},[_vm._v(" Dodaj do zamówienia ")])],1),_c('error-item',{attrs:{"errors":_vm.addressError}}),_c('error-item',{attrs:{"errors":_vm.errors.non_field_errors}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }