<template>
  <div class="input-radio-group">
    <input-radio
      v-for="(item, itemIndex) in items"
      :key="`${name}-${itemIndex}`"
      :name="name"
      :value="item[valueKey]"
      :active-radio="activeRadio"
      :disabled="disabled"
      @change="handleChange"
    >
      {{ item[labelKey] }}
    </input-radio>
    <error-item v-if="errors" :errors="errors" />
  </div>
</template>

<script>
import InputRadio from "@/views/components/formFields/InputRadio.vue";

export default {
  name: "InputRadioGroup",
  components: {
    InputRadio,
  },
  props: {
    items: {
      // An array of Objects with value and label properties
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    activeRadio: {
      type: [String, Boolean, Object, Number],
      default: null,
    },
    labelKey: {
      type: String,
      default: "label",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    errors: {
      type: [Array, Object, String],
      default() {
        return {};
      },
    },
  },
  methods: {
    handleChange(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style lang="scss" scoped></style>
