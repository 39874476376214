<template>
  <app-popup>
    <template v-slot:title> Wystąpił błąd podczas weryfikacji pliku </template>

    <img class="excel-modal__img" src="~@/images/order-error.svg" alt="" />
    <p class="popup__subtitle">
      Przykro nam, podczas weryfikacji pliku napotkaliśmy błędy, które
      uniemożliwiają załadowanie danych. Poniżej możesz zobaczyć jakiego rodzaju
      błędy napotkaliśmy. Gdy poprawisz błędy w pliku, wgraj go ponownie.
    </p>

    <p
      v-for="(generalError, generalErrorIndex) in errorsData.general"
      :key="`general-error-${generalErrorIndex}`"
      class="field-error text-color-error"
    >
      {{ generalError }}
    </p>

    <div class="text-center">
      <div
        v-if="errorsData.address_errors && errorsData.address_errors.length"
        class="address-errors"
      >
        <p class="modal-info__title">Błędy adresu:</p>
        <div
          v-for="(
            [addressErrorLocation, addressErrorFields], addressErrorIndex
          ) in errorsData.address_errors"
          :key="`address-errors-arr-${addressErrorIndex}`"
          class="address-error-row"
        >
          <p class="modal-row__title">
            Wiersz:
            <span class="text-color-error">{{ addressErrorLocation.row }}</span>
          </p>
          <div
            v-for="(addressFieldErrors, addressFieldKey) in addressErrorFields"
            :key="`address-field-errors-${addressErrorLocation.row}-${addressFieldKey}`"
            class="field-error-row"
          >
            <p class="field-name">
              Pole <span class="field-name-key">{{ addressFieldKey }}</span
              >:
            </p>
            <p
              v-for="(fieldError, fieldErrorIndex) in addressFieldErrors"
              :key="`address-field-error-${addressErrorLocation.row}-${addressFieldKey}-${fieldErrorIndex}`"
              class="field-error text-color-error"
            >
              {{ fieldError }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="errorsData.card_errors && errorsData.card_errors.length">
        <p class="modal-info__title">Błędy kart:</p>
        <div
          v-for="(
            [cardErrorLocation, cardErrorFields], cardErrorIndex
          ) in errorsData.card_errors"
          :key="`card-errors-arr-${cardErrorIndex}`"
          class="card-error-row"
        >
          <p class="modal-row__title">
            Wiersz:
            <span class="text-color-error">{{ cardErrorLocation.row }}</span>
          </p>
          <div
            v-for="(cardFieldErrors, cardFieldKey) in cardErrorFields"
            :key="`card-field-errors-${cardErrorLocation.row}-${cardFieldKey}`"
            class="field-error-row"
          >
            <p class="field-name">
              Pole <span class="field-name-key">{{ cardFieldKey }}</span
              >:
            </p>
            <p
              v-for="(fieldError, fieldErrorIndex) in cardFieldErrors"
              :key="`card-field-error-${cardErrorLocation.row}-${cardFieldKey}-${fieldErrorIndex}`"
              class="field-error text-color-error"
            >
              {{ fieldError }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:actions>
      <button class="button--primary" @click="$emit('popup-close')">
        Spróbuj ponownie
      </button>
    </template>
  </app-popup>
</template>

<script>
import AppPopup from "@/views/components/utilityComponents/AppPopup.vue";

export default {
  name: "PopupExcellError",
  components: {
    AppPopup,
  },
  props: {
    errorsData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_breakpoints.scss";

.modal-info__title {
  font-size: $h3-font-size;
  margin-bottom: 12px;
  font-weight: $font-weight-bold;
}

.modal-row__title {
  font-weight: $font-weight-bold;
  font-size: $basic-font-size;
  margin-bottom: 4px;
}

.address-error-row,
.card-error-row {
  border-bottom: 1px solid $border-color-light;
  margin-bottom: 15px;
}

.field-error-row {
  font-size: $summary-font-size;
  display: flex;
  padding-bottom: 10px;
  @include breakpoint-mobile-narrow {
    flex-direction: column;
  }
}

.field-name {
  padding-right: 7px;
  text-align: left;
  @include breakpoint-mobile-narrow {
    padding-right: 0;
    text-align: center;
  }
}

.field-name-key {
  font-weight: $font-weight-bold;
  text-align: left;
  @include breakpoint-mobile-narrow {
    text-align: center;
  }
}

.address-errors {
  margin-bottom: 24px;
}
</style>
