<template>
  <error-page
    message="Wystąpił problem w czasie łączenia z serwerem aplikacji."
    button-label="Spróbuj ponownie"
    @action-clicked="handleReload"
  />
</template>

<script>
import ErrorPage from "@/views/errors/ErrorPage.vue";

export default {
  name: "InitializationError",
  components: { ErrorPage },
  methods: {
    handleReload() {
      window.location.reload();
    },
  },
};
</script>
