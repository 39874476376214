<template>
  <div
    :class="[
      'order-summary-item',
      isLastItem ? 'order-summary-item--last' : '',
    ]"
  >
    <span class="order-summary-row__text">{{ name }}</span>
    <span
      class="order-summary-row__quantity order-summary-value"
      :title="count"
    >
      {{ count }}
    </span>
    <span class="order-summary-row__value order-summary-value" :title="amount">
      {{ amount }}
    </span>
    <span class="order-summary-row__sum order-summary-value" :title="sumAmount">
      {{ sumAmount }}
    </span>
  </div>
</template>

<script>
export default {
  name: "OrderSummaryItem",
  props: {
    name: { type: String, required: true },
    count: { type: [Number, String], default: "" },
    amount: { type: [Number, String], default: "" },
    sumAmount: { type: [Number, String], default: "" },
    isLastItem: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.order-summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  .order-summary-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--last {
    border-top: 1px solid $color-primary;
    padding-top: 15px;
    font-weight: $font-weight-bold;
    font-size: $basic-font-size;
  }
}
</style>
