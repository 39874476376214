<template>
  <div class="nof-add-cards">
    <h2 class="step-form__title">Zdefiniuj karty</h2>

    <div
      v-for="(card, cardIndex) in cards"
      :key="`card-row-${cardIndex}`"
      class="form-group-double card-row"
    >
      <text-input
        class="card-row__input"
        :value="cards[cardIndex].quantity"
        :errors="getCardErrors(cardIndex, 'quantity')"
        input-type="number"
        @input="
          (value) =>
            handleCardInput({
              key: 'quantity',
              value,
              cardIndex,
            })
        "
      >
        Liczba kart
      </text-input>

      <text-input
        :value="cards[cardIndex].amount"
        class="card-row__input"
        :errors="getCardErrors(cardIndex, 'amount')"
        input-type="number"
        @input="
          (value) =>
            handleCardInput({
              key: 'amount',
              value,
              cardIndex,
            })
        "
      >
        Wartość doładowania
      </text-input>
      <img
        v-show="cardIndex > 0"
        class="icon-trash"
        src="~@/images/icon-trash.svg"
        alt=""
        @click="() => handleDeleteCardRow(cardIndex)"
      />
    </div>
    <div class="add-card-row" @click="handleAddCardRow">
      <img class="icon-add" src="~@/images/icon-add.svg" alt="" />
      <p class="add-card-row__text">Dodaj kolejny wiersz</p>
    </div>
    <div class="buttons-container--center">
      <action-button :loading="loadingAddedCards" @click="addCards">
        Dodaj do zamówienia
      </action-button>
    </div>
    <error-item :errors="addressError" />
    <error-item :errors="errors.non_field_errors" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { orderedCardAPIPoint } from "@/apiEndpoints";

import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";

import TextInput from "@/views/components/formFields/TextInput.vue";

export default {
  components: { TextInput },
  data() {
    return {
      errors: {},
      loadingAddedCards: false,
      cardsErrors: [],
      addressError: null,
      cards: [{ quantity: "", amount: "" }],
    };
  },
  computed: {
    ...mapGetters("orders", ["getActiveCardOrderId"]),
    apiEndpoint() {
      const { getActiveCardOrderId } = this;
      return `${orderedCardAPIPoint}?card_order=${getActiveCardOrderId}`;
    },
    isDirty() {
      const firstCard = this.cards[0];
      if (firstCard && (firstCard.amount || firstCard.quantity)) {
        return true;
      }

      return false;
    },
  },
  watch: {
    isDirty(val) {
      if (val) {
        this.$emit("is-dirty", true);
      } else {
        this.$emit("is-dirty", false);
      }
    },
  },
  methods: {
    ...mapActions("orders", ["reloadActiveFormData", "updateBillingData"]),
    async postCards(data) {
      const { apiEndpoint } = this;
      return await fetchFromApi(apiEndpoint, {
        method: "POST",
        data,
      });
    },
    async handleCardsPostSuccess() {
      const { reloadActiveFormData, updateBillingData } = this;
      await reloadActiveFormData();
      await updateBillingData();
    },
    getCardErrors(cardIndex, property) {
      return this.cardsErrors[cardIndex]
        ? this.cardsErrors[cardIndex][property]
        : undefined;
    },
    async handlePostCards() {
      const data = {
        batch: this.cards.map((card) => ({
          ...card,
          card_order: this.getActiveCardOrderId,
        })),
      };

      try {
        await this.postCards(data);
        await this.handleCardsPostSuccess();
        this.cards = [{ quantity: "", amount: "" }];
        this.cardsErrors = [];
        this.addressError = null;
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.cardsErrors = errors;
        const deliveryAddressError = errors[0].delivery_address;
        if (deliveryAddressError) {
          this.addressError = deliveryAddressError;
        }
        return this.cardsErrors || deliveryAddressError;
      }
    },
    async addCards() {
      this.loadingAddedCards = true;
      const errors = await this.handlePostCards();
      this.loadingAddedCards = false;
      return errors;
    },
    handleAddCardRow() {
      this.cards = [...this.cards, { quantity: "", amount: "" }];
    },
    handleDeleteCardRow(rowIndex) {
      const { cards, cardsErrors } = this;
      const updatedCards = [...cards];
      updatedCards.splice(rowIndex, 1);
      const updatedCardsErrors = [...cardsErrors];
      updatedCardsErrors.splice(rowIndex, 1);
      this.cards = updatedCards;
      this.cardsErrors = updatedCardsErrors;
    },
    handleCardInput({ key, value, cardIndex }) {
      const cardsCopy = [...this.cards];
      const cardObj = cardsCopy[cardIndex] || {};
      cardsCopy.splice(cardIndex, 1, { ...cardObj, [key]: value });
      this.cards = cardsCopy;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-card-row {
  display: flex;
  align-items: center;
  margin-top: 13px;
  cursor: pointer;
}

.add-card-row__text {
  font-size: $summary-font-size;
  font-weight: $font-weight-bold;
  margin-left: 11px;
}

.card-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-right: 40px;

  .icon-trash {
    position: absolute;
    right: 0;
    top: 47px; // makes it positioned at height of input bottom border
    transform: translateY(-50%);
    cursor: pointer;
    padding: 6px;
  }
}
</style>
