<template>
  <div class="step step--with-stepper">
    <div>
      <label class="label-checkbox">
        <input
          :checked="isEveryStatementChecked"
          type="checkbox"
          @change="handleCheckAllStatements"
        />
        <span> Zaznacz wszystkie</span>
      </label>
      <p>Oświadczam, że:</p>
      <div class="statements-wrapper">
        <label
          v-for="(statement, idx) in statements"
          :key="idx"
          class="label-checkbox"
        >
          <input v-model="statement.state" type="checkbox" required />
          <span>
            {{ statement.label }}
          </span>
        </label>
      </div>
    </div>
    <ul>
      <li>
        <a download class="link" target="_blank" :href="OnePager">
          Podstawa prawna zbierania danych osobowych
        </a>
      </li>
      <li>
        <a download class="link" target="_blank" :href="Clause">
          Klauzula informacyjna o przetwarzaniu danych osobowych
        </a>
      </li>
    </ul>
    <h2 class="step-form__title">
      Dane osoby/osób składającej/cych oświadczenie zgodnie z reprezentacją w
      KRS/CEIDG
    </h2>
    <AppRepeater
      ref="repeaterRef"
      :template="submitterTemplate"
      :component="repeaterComponent"
      :delete-handler="deleteSubmitter"
      :patch-handler="patchSubmitter"
      :post-handler="postSubmitter"
      :load-handler="loadSubmitters"
      :items="submitters"
      :loading.sync="loading"
    />

    <error-item
      v-if="declaration.state === 'error'"
      errors="Wystąpił problem w czasie zapisywania danych na serwerze"
    />

    <div class="buttons-container">
      <action-button secondary @click="setPrevFormStep"> Wstecz </action-button>
      <action-button
        :disabled="!isEveryStatementChecked"
        :loading="loading"
        @click="complete"
      >
        Zakończ
      </action-button>
    </div>
  </div>
</template>

<script>
import Submitter from "@/views/components/aml/AmlSubmitter.vue";
import { mapActions, mapState } from "vuex";
import { obtainFetchError } from "@/utils/errors";
import OnePager from "@/pdf/onepager_podstawa prawna zbierania danych osobowych AML_PLN.pdf";
import Clause from "@/pdf/Klauzula informacyjna dot. przetwarzania danych osobowych w procesie AML.pdf";
import AppRepeater from "@/views/components/formFields/AppRepeater.vue";

export default {
  name: "AMLFormStep6",
  components: {
    AppRepeater,
  },
  data() {
    return {
      loading: false,
      repeaterComponent: Submitter,
      errors: {},
      OnePager,
      Clause,
      submitterTemplate: {
        name: "",
        position: "",
      },
      statements: [
        {
          state: false,
          label:
            "przedpłacone Karty Edenred nie zostaną wykorzystane przez Klienta do innych celów niż wskazane w powyższych punktach, w tym nie będą w żadnym razie wykorzystane w celach związanych chociażby pośrednio do prania pieniędzy lub finansowania terroryzmu;",
        },
        {
          state: false,
          label:
            "środki, z których sfinansowanych będzie zamówienie na wydanie lub doładowanie przedpłaconych kart Edenred, są legalne oraz nie zostały pozyskane przez Klienta w wyniku jakiejkolwiek działalności przestępczej lub naruszającej obowiązujące przepisy prawa;",
        },
        {
          state: false,
          label: "informacje podane w oświadczeniu są prawdziwe;",
        },
        {
          state: false,
          label:
            "jestem/-y osobą/-ami uprawnioną/-ymi do złożenia niniejszego oświadczenia w imieniu Klienta;",
        },
        {
          state: false,
          label:
            "jestem/-śmy świadoma/-y/-i odpowiedzialności karnej za złożenie fałszywego oświadczenia w zakresie powyższych informacji, w tym o statusie osoby zajmującej eksponowane stanowisko polityczne;",
        },
        {
          state: false,
          label:
            "zobowiązuję/-emy się w imieniu Klienta do niezwłocznego powiadomienia Edenred, jeżeli któreś z powyższych danych ulegnie zmianie.",
        },
      ],
    };
  },
  computed: {
    ...mapState("aml", ["declaration", "submitters"]),
    isEveryStatementChecked() {
      return Object.values(this.statements).every((v) => v.state);
    },
  },
  methods: {
    ...mapActions("aml", [
      "setPrevFormStep",
      "completeDeclaration",
      "patchDeclaration",
      "loadDeclaration",
      "loadSubmitters",
      "postSubmitter",
      "deleteSubmitter",
      "patchSubmitter",
    ]),
    async complete() {
      this.errors = {};
      this.generalErrors = {};
      this.loading = true;
      try {
        await this.$refs.repeaterRef.submit();
        await this.patchDeclaration(this.form);
        await this.completeDeclaration();
        await this.fetchDeclarationWhileStatusIsNotAwaiting();
        this.$router.push(`/aml/${this.declaration.id}/sign`);
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      } finally {
        this.loading = false;
      }
    },
    async fetchDeclarationWhileStatusIsNotAwaiting() {
      await this.loadDeclaration(this.declaration.id);
      if (
        ["awaiting_client_confirmation", "error"].includes(
          this.declaration?.state,
        )
      ) {
        return;
      }
      await this.wait(2000);
      await this.fetchDeclarationWhileStatusIsNotAwaiting();
    },
    async wait(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    handleCheckAllStatements(e) {
      const { checked } = e.target;
      if (!checked) return;

      this.statements.forEach((statement) => {
        statement.state = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.step-1-header-personalizations {
  display: flex;
  flex-direction: column;
}

.statements-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin: 0 0 2rem;
}
</style>
