import { fetchFromApi } from "@/utils/api";

export default {
  async requestMfaDefaultChallenge({ commit }) {
    const response = await fetchFromApi(
      "/mfa/devices/request_default_challenge/",
      {
        method: "POST",
        useVersionPrefix: false,
      },
    );
    commit("setMfaDeviceId", response.device_id);
  },
  async verifyMfa(
    { commit, state: { mfaDeviceId } },
    { mfa_token, device_id = mfaDeviceId },
  ) {
    const response = await fetchFromApi(`/mfa/devices/${device_id}/verify/`, {
      method: "POST",
      data: { mfa_token },
      useVersionPrefix: false,
    });
    commit("setMfaAuthenticated", response.code === "verified");
  },
  async loadMfaDevices({ commit }) {
    const response = await fetchFromApi("/mfa/devices/", {
      useVersionPrefix: false,
    });
    commit("setMfaDevices", response);
  },
  async confirmMfaDevice(store, { device_id, mfa_token }) {
    return await fetchFromApi(`/mfa/devices/${device_id}/confirm/`, {
      method: "POST",
      data: { mfa_token },
      useVersionPrefix: false,
    });
  },
  async addEmailMfaDevice() {
    return await fetchFromApi("/mfa/devices/", {
      method: "POST",
      useVersionPrefix: false,
      data: {
        method: "otp_email.emaildevice",
      },
    });
  },
  getMfaError(state, errorData) {
    const { code, message } = errorData;
    if (message) return message;

    switch (code) {
      case "invalid_token":
        return "Nieprawidłowy kod, spróbuj ponownie.";
      case "verify_not_allowed":
        return "Przekroczono limit prób weryfikacji kodu. Spróbuj ponownie później.";
      case "request_challenge_not_allowed":
        return "Przekroczono limit prób wysłania kodu. Spróbuj ponownie później.";
      default:
        return "Wystąpił problem połączenia z serwerem. Spróbuj ponownie.";
    }
  },
};
