import Vue from "vue";
import VCalendar from "v-calendar";
import PortalVue from "portal-vue";
import VueTippy from "vue-tippy";
import * as Sentry from "@sentry/vue";
import InfiniteLoading from "vue-infinite-loading";
import VueAnalytics from "vue-analytics";
import VueToastification from "vue-toastification";

import router from "@/router";
import { store } from "@/store";

import AppInfiniteLoadingLoader from "@/views/components/vue-infinite-loading/AppInfiniteLoadingLoader.vue";

const { isProduction } = store.state;

if (isProduction) {
  Sentry.init({
    Vue,
    dsn: "https://24ed787f5fc64f21b994cb84b4c200f4@o234745.ingest.sentry.io/5659857",
    logErrors: true,
    debug: !isProduction, // enabled on testing server
    environment: isProduction ? "prod" : "test",
    release: `edenred_wof`,
    normalizeDepth: 5,
  });
}

const googleAnalyticsId = document
  .querySelector("[data-ga-id]")
  .getAttribute("data-ga-id");
if (googleAnalyticsId && isProduction) {
  Vue.use(VueAnalytics, {
    id: googleAnalyticsId,
    router,
    debug: {
      sendHitTask: true,
    },
  });
}

Vue.use(VCalendar, {
  locales: {
    "pl-custom": {
      id: "pl",
      firstDayOfWeek: 1,
      masks: {
        input: ["DD/MM/YYYY"],
      },
    },
  },
});

Vue.use(PortalVue);

Vue.use(VueTippy);

Vue.use(InfiniteLoading, {
  slots: {
    error: "Ups, coś poszło nie tak...",
    noMore: "",
    spinner: AppInfiniteLoadingLoader,
    noResults: "",
  },
});

Vue.use(VueToastification);
