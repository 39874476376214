<template>
  <div class="popup__overlay" @click.self="$emit('overlay-click')">
    <div class="popup__modal">
      <div class="popup__title" :class="{ 'text-center': titleCenter }">
        <slot name="title"></slot>
      </div>
      <div class="popup__content">
        <slot></slot>
      </div>
      <div class="popup__errors">
        <error-item :errors="errors" />
      </div>
      <div class="popup__actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppPopup",
  props: {
    titleCenter: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: [Array, Object, String],
      default() {
        return {};
      },
    },
  },
  destroyed() {
    this.$emit("destroyed");
  },
};
</script>
