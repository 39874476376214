<template>
  <div>
    <info-box>
      W tym miejscu możesz dołączyć własny wizerunek karty zgodny z wytycznymi
      dostępnymi niżej.<br />
      <a class="link" :href="pictureSpec" target="_blank"
        >Pobierz plik z wytycznymi</a
      >
    </info-box>

    <input-radio-group
      v-model="isPictureSelected"
      :items="pictureSelectedItems"
      name="picture-selected-radio-btns"
      :active-radio="isPictureSelected"
      :disabled="updatingOrder"
    />
    <info-box v-if="isPictureSelected" margin-top="30px" margin-bottom="0">
      Załącz tylko jeden plik w formacie zgodnym z wytycznymi.
    </info-box>

    <file-upload
      v-show="isPictureSelected"
      class="file-uploader"
      file-upload-type="image"
      field-name="picture_card_file"
      http-method="PATCH"
      :url-to-upload="fileUploadUrl"
      :files="uploadedFiles"
      @file-removed="onFileRemoved"
      @file-upload-error="handleFileUploadError"
      @file-uploaded-successfully="handleFileUploadSuccess"
    />
    <error-item
      v-show="!errors.has_picture_card && !activeForm.has_picture_card"
      :errors="fileUploadErrors"
    />

    <loading-indicator :loading="updatingOrder" />
    <error-item :errors="errors" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

import updateOrderMixin from "@/mixins/updateOrderMixin";

import FileUpload from "@/views/components/formFields/FileUpload.vue";
import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";

import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

import { fetchFromApi } from "@/utils/api";
import { obtainFetchError, FormValidationError } from "@/utils/errors";

import { cardOrderAPIPoint } from "@/apiEndpoints";

import pictureSpec from "@/pdf/Specyfikacja_Edenred_PICTURE_CARDS.pdf";

export default {
  name: "CustomPicture",
  components: {
    FileUpload,
    LoadingIndicator,
    InfoBox,
    InputRadioGroup,
  },
  mixins: [updateOrderMixin],
  data() {
    return {
      pictureSpec,
      isPictureSelected: null,
      pictureSelectedItems: [
        { label: "Karta ze standardowym wizerunkiem", value: false },
        { label: "Karta z własnym wizerunkiem", value: true },
      ],
      uploadedFiles: [],
      fileUploadErrors: {},
    };
  },
  computed: {
    ...mapGetters("orders", ["getActiveCardOrderId"]),
    ...mapState("orders", ["activeForm", "cardOrderOptions"]),
    fileUploadUrl() {
      const { getActiveCardOrderId } = this;
      return `${cardOrderAPIPoint}${getActiveCardOrderId}/`;
    },
    hasValidationErrors() {
      const { has_picture_card } = this.activeForm;
      return has_picture_card === null || Object.keys(this.errors).length;
    },
  },
  watch: {
    async isPictureSelected(isSelected) {
      const { updateOrder, updateBillingData } = this;

      await updateOrder({ has_picture_card: isSelected });
      if (!isSelected) {
        this.uploadedFiles = [];
        this.fileUploadErrors = {};
      }
      updateBillingData();
    },
    hasValidationErrors(hasErrors) {
      if (hasErrors) {
        this.$emit("form-error");
        return;
      }
      this.$emit("form-success");
    },
    // user can pick has_logo or has_picture_card
    // these options can't be selected simultaneously
    // this watcher clears related error message when user
    // corrects his form in CustomLogo component
    "activeForm.has_logo": {
      async handler() {
        if (this.errors.has_picture_card) {
          await this.updateOrder({ has_picture_card: this.isPictureSelected });
        }
      },
    },
  },
  async mounted() {
    const {
      activeForm: { has_picture_card, picture_card_file },
    } = this;
    this.isPictureSelected = has_picture_card;

    if (picture_card_file) {
      this.uploadedFiles = [
        {
          source: picture_card_file,
          options: {
            type: "local",
          },
        },
      ];
    }

    if (!this.hasValidationErrors) {
      this.$emit("form-success");
    }
  },
  methods: {
    ...mapActions("orders", ["updateBillingData", "reloadActiveFormData"]),
    async clearPictureFile() {
      const { fileUploadUrl } = this;

      const formData = new FormData();
      formData.append("picture_card_file", "");
      try {
        await fetchFromApi(fileUploadUrl, {
          method: "PATCH",
          body: formData,
        });
        this.reloadActiveFormData();
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      }
    },
    onFileRemoved() {
      this.clearPictureFile();
    },
    handleFileUploadError(errors) {
      this.fileUploadErrors = errors;
      this.uploadedFiles = [];
    },
    handleFileUploadSuccess() {
      this.fileUploadErrors = {};
      this.reloadActiveFormData();
    },
    beforeComponentChange() {
      if (this.hasValidationErrors) {
        throw new FormValidationError(
          "CustomPicture dynamic list validation error",
        );
      }
    },
  },
};
</script>

<style scoped>
.file-uploader {
  margin-top: 32px;
}

.step-form__title {
  margin-top: 40px;
}
</style>
