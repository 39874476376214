<template>
  <div class="repeater">
    <ul class="list-none">
      <li v-for="(item, idx) in localItems" :key="item.id || item.localId">
        <component
          :is="component"
          :value="item"
          :errors="errors[idx]"
          @input="(v) => localItems.splice(idx, 1, v)"
          :index="idx"
        />
        <error-item :errors="removeErrors[item.id]" />
        <div class="buttons-container">
          <action-button
            v-if="localItems.length > 1"
            secondary
            :loading="removeLoading[item.id]"
            @click="() => handleRemove(idx)"
          >
            Usuń
          </action-button>
          <action-button
            v-if="idx === localItems.length - 1"
            @click="() => handleAdd()"
          >
            Dodaj
          </action-button>
        </div>
      </li>
    </ul>
    <error-item :error="nonFieldErrors" />
  </div>
</template>

<script>
import { obtainFetchError } from "@/utils/errors";

export default {
  name: "AppRepeater",
  props: {
    component: {
      type: Object,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    deleteHandler: {
      type: Function,
      required: true,
    },
    patchHandler: {
      type: Function,
      required: true,
    },
    postHandler: {
      type: Function,
      required: true,
    },
    loadHandler: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nonFieldErrors: {},
      removeLoading: {},
      removeErrors: {},
      errors: {},
      localItems: [],
    };
  },
  created() {
    if (this.items.length) {
      this.localItems = [...this.items];
      return;
    }

    this.localItems = [
      {
        localId: 1,
        ...this.template,
      },
    ];
  },
  computed: {
    anyRemoveLoading() {
      return Object.values(this.removeLoading).some((v) => v);
    },
  },
  methods: {
    async handleRemove(idx) {
      const item = this.localItems[idx];
      if (!item.id) {
        this.localItems.splice(idx, 1);
        return;
      }
      this.removeErrors = {
        ...this.removeErrors,
        [item.id]: {},
      };
      this.removeLoading = {
        ...this.removeLoading,
        [item.id]: true,
      };
      this.$emit("update:loading", true);
      try {
        await this.deleteHandler(item.id);
        await this.loadHandler();
        this.localItems.splice(idx, 1);
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.removeErrors = {
          ...this.removeErrors,
          [item.id]: errors,
        };
      } finally {
        this.removeLoading = {
          ...this.removeLoading,
          [item.id]: false,
        };
        this.$emit("update:loading", false);
      }
    },
    handleAdd() {
      this.localItems.push({
        localId: this.localItems.length + 1,
        ...this.template,
      });
    },
    async handleItem(item) {
      const shouldPatch =
        !item.localId &&
        this.localItems.findIndex((v) => v.id === item.id) !== -1;
      if (shouldPatch) {
        return await this.patchHandler(item);
      }

      const shouldPost = item.localId;
      if (shouldPost) {
        return await this.postHandler(item);
      }
    },
    async submit() {
      this.$emit("update:loading", true);
      this.errors = {};
      try {
        const promises = this.localItems.map(this.handleItem);
        const results = await Promise.allSettled(promises);
        const hasErrors = results.some(
          (result) => result.status === "rejected",
        );
        if (hasErrors) {
          const errors = {};
          results.forEach((result, idx) => {
            if (result.status === "rejected") {
              errors[idx] = result.reason.errors;
            }
          });
          this.errors = errors;
          this.$emit("update:loading", false);
          throw errors;
        }

        await this.loadHandler();
        if (this.items.length) {
          this.localItems = [...this.items];
        }
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.nonFieldErrors = errors;
      } finally {
        this.$emit("update:loading", false);
      }
    },
  },
};
</script>

<style scoped>
.buttons-container {
  justify-content: flex-start;
  gap: 16px;
}
</style>
