<template>
  <div class="date-picker-wrap">
    <label v-if="$slots.default" class="label">
      <slot></slot> {{ required ? "*" : "" }}
    </label>
    <v-date-picker
      v-model="localDate"
      locale="pl-custom"
      :min-date="minDate"
      :disabled-dates="disabledDates"
      class="date-picker"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div class="date-picker__input-wrap">
          <loading-indicator
            v-show="loading"
            class="date-loading-indicator"
            :loading="loading"
          />
          <input
            v-show="!loading"
            class="date-picker__input"
            readonly
            :placeholder="placeholder"
            :value="inputValue"
            v-on="inputEvents"
          />
        </div>
      </template>
    </v-date-picker>
    <error-item v-if="errors" :errors="errors" />
  </div>
</template>

<script>
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

// TODO: better loading experience? ( popup is jumping )
export default {
  name: "InputDatePicker",
  components: {
    LoadingIndicator,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: Date,
      default: null,
    },
    placeholder: {
      type: String,
      default: "DD/MM/YYYY",
    },
    disabledDates: {
      type: Object,
      default() {
        return {
          weekdays: [],
        };
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: [Array, Object, String],
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      localDate: null,
    };
  },
  watch: {
    localDate(localDate) {
      const { formatDate } = this;
      const formatted = formatDate(localDate);
      this.$emit("input", formatted);
    },
    value: {
      handler(value) {
        if (!value) {
          this.localDate = null;
          return;
        }
        this.localDate = new Date(value);
      },
      immediate: true,
    },
  },
  methods: {
    formatDate(dateObj) {
      if (!(dateObj instanceof Date)) {
        return null;
      }
      const dateWithNoUTCOffset = new Date(
        dateObj.getTime() - dateObj.getTimezoneOffset() * 60000,
        // getTimezoneOffset returns minutes
        // min * 60000 = ms
      );
      return new Date(dateWithNoUTCOffset).toISOString().slice(0, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.date-loading-indicator {
  width: 100%;
  top: 2px;
  position: relative;
}

.label {
  margin-bottom: 6px;
}

.date-picker-wrap {
  margin-bottom: 20px;
}
</style>
