<template>
  <input-select
    :value="value"
    :options="countries"
    :errors="errors"
    label="Kraj"
    placeholder="Kraj"
    :clearable="false"
    :loading="!countries.length"
    @input="(v) => $emit('input', v)"
  />
</template>

<script>
import InputSelect from "@/views/components/formFields/InputSelect.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "InputSelectCountry",
  components: { InputSelect },
  props: {
    value: {
      type: Object,
      default() {
        return null;
      },
    },
    errors: {
      type: [Array, Object, String],
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState("countries", ["countries"]),
  },
  mounted() {
    this.loadCountries();
  },
  methods: {
    ...mapActions("countries", ["loadCountries"]),
  },
};
</script>
