<template>
  <div class="dynamic-list">
    <div class="step__content-row">
      <div class="step__content-column list">
        <div v-for="(step, i) in steps" :key="`step-item-${i}`">
          <div
            class="list__item"
            :class="{
              'list__item--active': i === activeStepIndex,
              'has-success': formSuccess[i],
              'has-errors': formSuccess[i] === false,
              'is-not-displayed': step.hide,
            }"
            @click="setActiveStepIndex(i)"
          >
            <div class="icon">
              <img :src="step.icon" alt="" />
            </div>
            {{ step.name }}
          </div>

          <div class="step__component-wrap">
            <portal-target
              class="dynamic-list__mobile-component-target"
              :name="`step-mobile-component-target-${i}`"
            >
            </portal-target>
          </div>
        </div>
      </div>
      <div
        class="step__content-column step__content-column-desktop list-content"
      >
        <div ref="scrollAnchorRef" class="scroll-anchor"></div>
        <div
          v-for="(step, i) in steps"
          v-show="i === activeStepIndex"
          :key="`step-components-${i}`"
        >
          <portal-target :name="`step-desktop-component-target-${i}`">
          </portal-target>
        </div>
      </div>
    </div>

    <portal
      v-for="(step, i) in steps"
      :key="`dynamic-list-portal-${i}`"
      :to="
        viewport.gt.md
          ? `step-desktop-component-target-${i}`
          : `step-mobile-component-target-${i}`
      "
    >
      <component
        :is="step.component"
        v-show="i === activeStepIndex"
        ref="stepComponentsRef"
        @form-success="(event) => handleFormSuccess(i, event)"
        @form-error="(event) => handleFormError(i, event)"
        @is-dirty="() => handleComponentDirty(i)"
      />
    </portal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DynamicListValidationError } from "@/utils/errors";

export default {
  name: "DynamicList",
  props: {
    steps: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeStepIndex: 0,
      errors: {},
      formSuccess: {},
      dirtyComponents: {},
    };
  },
  computed: {
    ...mapState("viewport", ["viewport"]),
    visibleStepIndexes() {
      return this.steps
        .map((step, stepIndex) => (!step.hide ? stepIndex : undefined))
        .filter((v) => typeof v !== "undefined");
    },
    invalidComponentsIndexes() {
      const invalidComponentsIndexes = this.visibleStepIndexes
        .map((stepIndex) =>
          !this.formSuccess[stepIndex] ? stepIndex : undefined,
        )
        .filter((step) => typeof step !== "undefined");
      return invalidComponentsIndexes;
    },
    dirtyComponentsIndexes() {
      const dirtyComponentsIndexes = this.visibleStepIndexes
        .map((stepIndex) =>
          this.dirtyComponents[stepIndex] ? stepIndex : undefined,
        )
        .filter((step) => typeof step !== "undefined");
      return dirtyComponentsIndexes;
    },
    componentIndexesToValidate() {
      const componentIndexesToValidate = Array.from(
        new Set([
          ...this.invalidComponentsIndexes,
          ...this.dirtyComponentsIndexes,
        ]),
        // removes duplicate indexes
      );
      return componentIndexesToValidate;
    },
  },
  created() {
    this.activeStepIndex = this.visibleStepIndexes[0];
  },
  methods: {
    handleFormSuccess(i) {
      this.formSuccess = {
        ...this.formSuccess,
        [i]: true,
      };
    },
    handleFormError(i) {
      this.formSuccess = {
        ...this.formSuccess,
        [i]: false,
      };
    },
    handleComponentDirty(i) {
      this.dirtyComponents = {
        ...this.dirtyComponents,
        [i]: true,
      };
    },
    handleComponentSuccess(componentIndex) {
      this.formSuccess = {
        ...this.formSuccess,
        [componentIndex]: true,
      };
      this.dirtyComponents = {
        ...this.dirtyComponents,
        [componentIndex]: false,
      };
    },
    async setActiveStepIndex(i) {
      const { activeStepIndex, viewport } = this;
      if (i === activeStepIndex) {
        return;
      }

      const activeStepComponent =
        this.$refs.stepComponentsRef[this.activeStepIndex];
      if (
        activeStepComponent &&
        activeStepComponent.beforeComponentChange &&
        this.componentIndexesToValidate.indexOf(this.activeStepIndex) !== -1
      ) {
        this.$emit("update:loading", true);
        try {
          await activeStepComponent.beforeComponentChange();
          this.handleComponentSuccess(this.activeStepIndex);
        } catch (error) {
          if (!["FetchError", "FormValidationError"].includes(error.name)) {
            throw error;
          }
          this.formSuccess = {
            ...this.formSuccess,
            [this.activeStepIndex]: false,
          };
        } finally {
          this.$emit("update:loading", false);
        }
      }

      this.activeStepIndex = i;

      if (viewport.gt.md) {
        const {
          $refs: { scrollAnchorRef },
        } = this;
        const topPos =
          scrollAnchorRef.getBoundingClientRect().top + window.pageYOffset;
        const offset = -40;
        // added offset so that page doesn't scroll directly to top of the element, but 40 px above it which is more user friendly
        window.scrollTo({ top: topPos + offset });
      }
    },
    async validate() {
      this.$emit("update:loading", true);

      const promises = this.componentIndexesToValidate.map(async (index) => {
        const compRef = this.$refs.stepComponentsRef[index];
        if (compRef.beforeComponentChange) {
          try {
            const result = await compRef.beforeComponentChange();
            return {
              result,
              componentIndex: index,
            };
          } catch (error) {
            const errorObject = { error, componentIndex: index };
            throw errorObject;
          }
        }
        return true;
      });

      const results = await Promise.allSettled(promises);
      const errorsArray = [];
      results.forEach(({ reason, value: { componentIndex } = {} }) => {
        // if promise rejected
        if (reason) {
          errorsArray.push(reason);
          return;
        }
        this.handleComponentSuccess(componentIndex);
      });

      this.$emit("update:loading", false);
      if (errorsArray.length) {
        errorsArray.forEach(({ error, componentIndex }) => {
          if (!["FetchError", "FormValidationError"].includes(error.name)) {
            throw errorsArray;
          }

          this.formSuccess = {
            ...this.formSuccess,
            [componentIndex]: false,
          };
        });

        throw new DynamicListValidationError(errorsArray);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_breakpoints.scss";

.list__item {
  &.has-errors {
    border-color: $error-color;
  }
  &.has-success {
    border-color: $color-active;
  }

  &.is-not-displayed {
    display: none !important;
  }
}
</style>

<style lang="scss">
.dynamic-list__mobile-component-target > div {
  margin-bottom: 24px;
}
</style>
