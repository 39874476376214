<template>
  <div>
    <additional-details-form
      ref="additionalDetailsRef"
      :api-endpoint="apiEndpoint"
      :form-data-object="getAdditionalReloadCardOrderDetails"
      @form-success="handleFormSuccess"
      @request-update="reloadActiveReloadFormData"
      @is-dirty="$emit('is-dirty')"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import { orderAPIPoint } from "@/apiEndpoints";

import AdditionalDetailsForm from "@/views/components/formFields/additional_details/AdditionalDetailsForm.vue";

export default {
  name: "CardReloadOrderAdditionalDetails",
  components: { AdditionalDetailsForm },
  computed: {
    ...mapState("reloads", ["activeReloadOrderId"]),
    ...mapGetters("reloads", [
      "getActiveCardReloadOrderId",
      "getAdditionalReloadCardOrderDetails",
    ]),
    apiEndpoint() {
      return `${orderAPIPoint}${this.getActiveCardReloadOrderId}/?card_reload_order=${this.activeReloadOrderId}`;
    },
  },
  methods: {
    ...mapActions("reloads", ["reloadActiveReloadFormData"]),
    handleFormSuccess(event) {
      this.$emit("form-success", event);
    },
    // used by DynamicList.vue component
    async beforeComponentChange() {
      const {
        $refs: {
          additionalDetailsRef: { beforeComponentChange },
        },
      } = this;
      if (beforeComponentChange) {
        await beforeComponentChange();
      }
    },
  },
};
</script>
