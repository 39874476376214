const NON_AUTH_ROUTES = [
  "register",
  "login",
  "forgotPassword",
  "resetPassword",
  "nof",
  "nofOrderSuccessView",
];

import LayoutLogin from "@/layouts/LayoutLogin.vue";
import LayoutDefault from "@/layouts/LayoutDefault.vue";
import LayoutNof from "@/layouts/LayoutNof.vue";

const routes = [
  {
    path: "/",
    redirect: "/moje-zamowienia",
  },
  {
    path: "/moje-zamowienia",
    component: LayoutDefault,
    children: [
      {
        path: "",
        name: "orders",
        component: () =>
          import(
            /* webpackChunkName: "ListingMyOrders" */ "@/views/orders/ListingMyOrders.vue"
          ),
      },
      {
        path: ":id",
        name: "orderDetails",
        component: () =>
          import(
            /* webpackChunkName: "OrderDetails" */ "@/views/order-details/OrderDetails.vue"
          ),
      },
      {
        path: "doladowanie/:id",
        name: "orderDetailsReload",
        component: () =>
          import(
            /* webpackChunkName: "OrderDetailsReload" */ "@/views/order-details/OrderDetailsReload.vue"
          ),
      },
    ],
  },
  {
    path: "/moje-karty",
    component: LayoutDefault,
    children: [
      {
        path: "",
        name: "listingMyCards",
        component: () =>
          import(
            /* webpackChunkName: "ListingMyCards" */ "@/views/my-cards/ListingMyCards.vue"
          ),
      },
    ],
  },
  {
    path: "/zamowienie/",
    component: LayoutDefault,
    children: [
      {
        path: "",
        name: "productSelectView",
        component: () =>
          import(
            /* webpackChunkName: "ProductSelectView" */ "@/views/order/ProductSelectView.vue"
          ),
      },
      {
        path: "sukces",
        name: "orderSuccessView",
        component: () =>
          import(
            /* webpackChunkName: "OrderSuccessView" */ "@/views/order/OrderSuccessView.vue"
          ),
      },
      {
        path: ":orderId",
        name: "order",
        component: () =>
          import(
            /* webpackChunkName: "MultistepFormCardOrder" */ "@/views/order/MultistepFormCardOrder.vue"
          ),
      },
      {
        path: "nof/:orderId",
        name: "nofForm",
        component: () =>
          import(
            /* webpackChunkName: "nofForm" */ "@/views/nof/NofMultistepForm.vue"
          ),
      },
    ],
  },
  {
    path: "/doladowanie/",
    component: LayoutDefault,
    children: [
      {
        path: "",
        name: "reloadTypeSelect",
        component: () =>
          import(
            /* webpackChunkName: "ReloadTypeSelect" */ "@/views/reload-cards/ReloadTypeSelect.vue"
          ),
      },
      {
        path: "karty",
        name: "listingCardsToReload",
        component: () =>
          import(
            /* webpackChunkName: "ListingCardsToReload" */ "@/views/reload-cards/ListingCardsToReload.vue"
          ),
      },
      {
        path: "zamowienia",
        name: "listingOrderBasedReload",
        component: () =>
          import(
            /* webpackChunkName: "ListingOrderBasedReload" */ "@/views/reload-cards/ListingOrderBasedReload.vue"
          ),
      },
      {
        path: "sukces",
        name: "reloadOrderSuccessView",
        component: () =>
          import(
            /* webpackChunkName: "ReloadOrderSuccessView" */ "@/views/reload-cards/ReloadOrderSuccessView.vue"
          ),
      },
      {
        path: ":id",
        name: "reloadCardsEdit",
        component: () =>
          import(
            /* webpackChunkName: "ReloadCards" */ "@/views/reload-cards/MultistepFormReloadCards.vue"
          ),
      },
    ],
  },
  {
    path: "/udostepnianie",
    component: LayoutDefault,
    children: [
      {
        path: "",
        name: "shareTypeSelect",
        component: () =>
          import(
            /* webpackChunkName: "ShareTypeSelect" */ "@/views/cards-sharing/ShareTypeSelect.vue"
          ),
      },
      {
        path: "karty",
        name: "listingCardsToShare",
        component: () =>
          import(
            /* webpackChunkName: "ListingCardsToShare" */ "@/views/cards-sharing/ListingCardsToShare.vue"
          ),
      },
      {
        path: "zamowienia",
        name: "listingOrderBasedSharing",
        component: () =>
          import(
            /* webpackChunkName: "ListingOrderBasedSharing" */ "@/views/cards-sharing/ListingOrderBasedSharing.vue"
          ),
      },
      {
        path: "udostepnij",
        name: "shareCards",
        component: () =>
          import(
            /* webpackChunkName: "ShareCards" */ "@/views/cards-sharing/ShareCards.vue"
          ),
      },
      {
        path: "sukces",
        name: "cardsSharingSuccessView",
        component: () =>
          import(
            /* webpackChunkName: "CardsSharingSuccessView" */ "@/views/cards-sharing/CardsSharingSuccessView.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/moj-profil",
    component: LayoutDefault,
    children: [
      {
        path: "",
        name: "myProfile",
        component: () =>
          import(
            /* webpackChunkName: "myprofile-view" */ "@/views/my-profile/MyProfileTabsPage.vue"
          ),
      },
    ],
  },
  {
    path: "/aml",
    component: LayoutDefault,
    children: [
      {
        path: "",
        name: "aml-listing",
        component: () =>
          import(
            /* webpackChunkName: "ListingAML" */ "@/views/components/listings/ListingAML.vue"
          ),
      },
      {
        path: ":declarationId",
        name: "aml-declaration",
        component: () =>
          import(
            /* webpackChunkName: "MultistepFormAML" */ "@/views/aml/MultistepFormAML.vue"
          ),
      },
      {
        path: ":declarationId/sign",
        name: "aml-declaration-sign",
        component: () =>
          import(/* webpackChunkName: "SignAML" */ "@/views/aml/SignAML.vue"),
      },
      {
        path: ":declarationId/download",
        name: "aml-declaration-download",
        component: () =>
          import(
            /* webpackChunkName: "DownloadAML" */ "@/views/aml/DownloadAML.vue"
          ),
      },
    ],
  },
  {
    path: "/resetowanie-hasla",
    component: LayoutLogin,
    children: [
      {
        path: "",
        name: "resetPassword",
        component: () =>
          import(
            /* webpackChunkName: "ChangePasswordPage" */ "@/views/login/ChangePasswordPage.vue"
          ),
      },
    ],
  },
  {
    path: "/odzyskiwanie-hasla",
    component: LayoutLogin,
    children: [
      {
        path: "",
        name: "forgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "ForgotPasswordPage" */ "@/views/login/ForgotPasswordPage.vue"
          ),
      },
    ],
  },
  {
    path: "/logowanie",
    props: true,
    component: LayoutLogin,
    children: [
      {
        path: "",
        name: "login",
        component: () =>
          import(
            /* webpackChunkName: "login-view" */ "@/views/login/LoginPage.vue"
          ),
      },
    ],
  },
  {
    path: "/mfa",
    props: true,
    component: LayoutLogin,
    children: [
      {
        path: "",
        name: "mfa",
        component: () =>
          import(/* webpackChunkName: "mfa-view" */ "@/views/mfa/MfaPage.vue"),
      },
      {
        path: "enable",
        name: "mfaEnable",
        component: () =>
          import(
            /* webpackChunkName: "mfa-view" */ "@/views/mfa/MfaEnablePage.vue"
          ),
      },
    ],
  },
  {
    path: "/rejestracja/:id",
    component: LayoutLogin,
    children: [
      {
        path: "",
        name: "register",
        component: () =>
          import(
            /* webpackChunkName: "register-view" */ "@/views/login/RegisterPage.vue"
          ),
      },
    ],
  },
  {
    path: "/zamow/",
    redirect: "/moje-zamowienia",
    component: LayoutNof,
    children: [
      {
        path: "sukces",
        name: "nofOrderSuccessView",
        component: () =>
          import(
            /* webpackChunkName: "NofOrderSuccessView" */ "@/views/nof/NofOrderSuccessView.vue"
          ),
      },
      {
        path: ":productSlug",
        name: "nof",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "nofMultistepForm" */ "@/views/nof/NofMultistepForm.vue"
          ),
      },
    ],
  },
  {
    path: "*",
    component: LayoutDefault,
    children: [
      {
        path: "",
        name: "not-found",
        component: () => import("@/views/errors/NotFoundError.vue"),
      },
    ],
  },
];

export default routes;

export { NON_AUTH_ROUTES };
