import { getDefaultState } from "./state";

export default {
  resetOrdersState(state) {
    Object.assign(state, getDefaultState());
  },
  resetActiveForm(state) {
    state.activeForm = {};
    state.activeOrderId = null;
    state.activeFormBilling.billingArray = [];
    state.activeFormBilling.isBillingFinal = null;
    state.activeFormStep = 0;
    state.activeFormInvoiceOptions = {
      showRecipient: false,
    };
  },
  setActiveFormBilling(state, { billingArray, isBillingFinal }) {
    state.activeFormBilling = {
      billingArray,
      isBillingFinal,
    };
  },
  setActiveFormBillingReloadError(state, payload) {
    state.activeFormBillingReloadError = payload;
  },
  setActiveFormBillingLoading(state, payload) {
    state.activeFormBillingLoading = payload;
  },
  updateActiveFormInvoiceOptions(state, payload) {
    state.activeFormInvoiceOptions = {
      ...state.activeFormInvoiceOptions,
      ...payload,
    };
  },
  setActiveForm(state, form) {
    state.activeForm = form;
  },
  /** Set active edited order ID */
  setActiveOrderId(state, id) {
    state.activeOrderId = id;
  },
  /** Method that is used to navigation between steps in main form  */
  goToNextFormStep(state) {
    state.activeFormStep++;
  },
  /** Method that is used to navigation between steps in main form  */
  goToPreviousFormStep(state) {
    state.activeFormStep--;
  },
  /** Sets active form step  */
  setActiveFormStep(state, step) {
    state.activeFormStep = step;
  },
  setCardOrderOptions(state, cardOrderOptions) {
    state.cardOrderOptions = cardOrderOptions;
  },
  setOrderOptions(state, orderOptions) {
    state.orderOptions = orderOptions;
  },
  setActiveOrder(state, activeOrder) {
    state.activeOrder = activeOrder;
  },
  setOrderNotAuthorizedError(state, error) {
    state.orderNotAuthorizedError = error;
  },
  setCardsOptions(state, cardsOptions) {
    state.cardsOptions = cardsOptions;
  },
};
