import { getDefaultState } from "./state";

export default {
  resetSharingState(state) {
    Object.assign(state, getDefaultState());
  },
  setCardsToShare(state, cardsArray) {
    state.cardsToShare = cardsArray;
  },
};
