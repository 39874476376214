<template>
  <div>
    <div
      v-for="(error, errorIndex) in errorsList"
      :key="`error-${errorIndex}`"
      class="form-error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import _flatten from "lodash.flatten";

export default {
  name: "ErrorItem",
  props: {
    errors: {
      type: [Array, Object, String],
      default() {
        return [];
      },
    },
  },
  computed: {
    errorsList() {
      function getErrorsList(errors) {
        if (!errors) {
          return [];
        }
        if (typeof errors === "string") {
          return [errors];
        }
        if (Array.isArray(errors)) {
          return _flatten(errors.map(getErrorsList));
        }
        // error with code, like { code: "some_code", message: "some message"}
        if (typeof errors === "object" && errors.code) {
          return [errors.message];
        }
        // list of errors, like { field1: f1_errors, field2: f2_errors }
        return _flatten(Object.values(errors).map(getErrorsList));
      }
      return getErrorsList(this.errors);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-error {
  padding-top: 5px;
}
</style>
