<template>
  <div>
    <info-box
      >Jeśli chcesz zamieścić własną grafikę na kopercie, zaznacz opcję poniżej.
      Po złożeniu zamówienia skontaktujemy się, w celu ustalenia
      szczegółów.</info-box
    >
    <input-radio-group
      v-model="hasEnvelope"
      :items="envelopeChoices"
      name="has_envelope_radio"
      :active-radio="hasEnvelope"
      :disabled="updatingOrder"
    />
    <loading-indicator :loading="updatingOrder" />
    <error-item :errors="errors" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { FormValidationError } from "@/utils/errors";

import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";
import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";

import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

import updateOrderMixin from "@/mixins/updateOrderMixin";

export default {
  name: "CustomEnvelope",
  components: { InputRadioGroup, LoadingIndicator, InfoBox },
  mixins: [updateOrderMixin],
  data() {
    return {
      hasEnvelope: null,
      envelopeChoices: [
        { label: "Karta bez indywidualnej koperty", value: false },
        { label: "Karta z indywidualną kopertą", value: true },
      ],
    };
  },
  computed: {
    ...mapState("orders", ["activeForm"]),
    hasValidationErrors() {
      const { has_individual_envelope } = this.activeForm;
      if (has_individual_envelope === null) {
        return true;
      }
      return false;
    },
  },
  watch: {
    async hasEnvelope(value) {
      await this.updateOrder({ has_individual_envelope: value });
      this.updateBillingData();
    },
    hasValidationErrors(hasErrors) {
      if (hasErrors) {
        this.$emit("form-error");
        return;
      }
      this.$emit("form-success");
    },
  },
  beforeMount() {
    const {
      activeForm: { has_individual_envelope },
    } = this;
    this.hasEnvelope = has_individual_envelope;

    if (!this.hasValidationErrors) {
      this.$emit("form-success");
    }
  },
  methods: {
    ...mapActions("orders", ["updateBillingData"]),
    beforeComponentChange() {
      if (this.hasValidationErrors) {
        throw new FormValidationError(
          "CustomEnvelope dynamic list validation error",
        );
      }
    },
  },
};
</script>
