<template>
  <div class="carousel-container">
    <VueSlickCarousel
      ref="carousel"
      class="carousel"
      :dots="true"
      v-bind="settings"
      @afterChange="pictureChanged"
    >
      <slot></slot>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "SlickCarousel",
  components: { VueSlickCarousel },
  props: {
    initialPictureIndex: {
      type: Number,
      required: false,
      default() {
        return 0;
      },
    },
    errors: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      settings: {
        infinite: false,
        dots: true,
        initialSlide: this.initialPictureIndex,
      },
    };
  },
  methods: {
    pictureChanged(payload) {
      this.$emit("picture-changed", payload);
    },
  },
};
</script>

<style lang="scss">
@import "~vue-slick-carousel/dist/vue-slick-carousel.css";
@import "~vue-slick-carousel/dist/vue-slick-carousel-theme.css";

@import "~@/scss/_breakpoints.scss";

.carousel-title {
  font-size: $basic-font-size;
}

.carousel-container {
  display: block;
  width: 100%;
  margin-bottom: 50px;
}

.slick-slider {
  margin: 0 25px 0 0;
  @include breakpoint-mobile {
    margin: 0 25px;
  }
}

.slick-slide {
  img {
    border-radius: $small-radius;
  }
}

.slick-prev,
.slick-next {
  background-repeat: no-repeat;
  width: 18px;
  height: 12px;
  &::before {
    color: transparent;
    content: "";
  }
}

.slick-prev {
  background-image: url("~@/images/arrow_left.svg");
  &:focus,
  &:hover {
    background-image: url("~@/images/arrow_left.svg");
  }
}

.slick-next {
  background-image: url("~@/images/arrow_right.svg");
  &:focus,
  &:hover {
    background-image: url("~@/images/arrow_right.svg");
  }
}

.slick-dots {
  li {
    button::before {
      content: "";
      height: 6px;
      width: 6px;
      border-radius: 3px;
      background-color: $color-secondary-light;
    }
    &.slick-active {
      button::before {
        width: 20px;
        background-color: $color-secondary;
      }
    }
  }
}
</style>
