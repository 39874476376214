<template>
  <div class="expansion-panel" :class="{ 'is-expanded': value }">
    <div class="expansion-panel__content">
      <slot></slot>
    </div>
    <div ref="expandableRef" class="expandable">
      <div class="expandable__content">
        <slot name="expandable"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppExpansionPanel",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler(isExpanded) {
        this.$nextTick(() => {
          const { expandableRef } = this.$refs;

          if (isExpanded) {
            expandableRef.style.maxHeight = `${expandableRef.scrollHeight}px`;
          } else {
            expandableRef.style.maxHeight = null;
          }
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.expansion-panel {
  &.is-expanded {
    .expandable__content {
      opacity: 1;
    }
  }
}

.expandable {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.18s;
  position: relative;
}

.expandable__content {
  transition: opacity 0.24s;
  opacity: 0;
}
</style>
