import AMLFormStep1 from "@/views/components/aml/steps/AMLFormStep1.vue";
import AMLFormStep2 from "@/views/components/aml/steps/AMLFormStep2.vue";
import AMLFormStep3 from "@/views/components/aml/steps/AMLFormStep3.vue";
import AMLFormStep4 from "@/views/components/aml/steps/AMLFormStep4.vue";
import AMLFormStep5 from "@/views/components/aml/steps/AMLFormStep5.vue";
import AMLFormStep6 from "@/views/components/aml/steps/AMLFormStep6.vue";

const getDefaultState = () => ({
  activeFormStep: 0,
  declaration: null,
  representatives: [],
  authorizedPersons: [],
  beneficialOwners: [],
  submitters: [],
  formSteps: [
    {
      description: "Dane klienta",
      component: AMLFormStep1,
    },
    {
      description: "Dane reprezentantów",
      component: AMLFormStep2,
    },
    {
      description: "Osoby upoważnione do działania w imieniu klienta",
      component: AMLFormStep3,
    },
    {
      description: "Beneficjenci rzeczywiści",
      component: AMLFormStep4,
    },
    {
      description: "Informacje o celu transakcji gospodarczej",
      component: AMLFormStep5,
    },
    {
      description: "Oświadczenia i podpis",
      component: AMLFormStep6,
    },
  ],
});

const state = getDefaultState();

export default state;

export { getDefaultState };
