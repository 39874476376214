var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dynamic-list"},[_c('div',{staticClass:"step__content-row"},[_c('div',{staticClass:"step__content-column list"},_vm._l((_vm.steps),function(step,i){return _c('div',{key:`step-item-${i}`},[_c('div',{staticClass:"list__item",class:{
            'list__item--active': i === _vm.activeStepIndex,
            'has-success': _vm.formSuccess[i],
            'has-errors': _vm.formSuccess[i] === false,
            'is-not-displayed': step.hide,
          },on:{"click":function($event){return _vm.setActiveStepIndex(i)}}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":step.icon,"alt":""}})]),_vm._v(" "+_vm._s(step.name)+" ")]),_c('div',{staticClass:"step__component-wrap"},[_c('portal-target',{staticClass:"dynamic-list__mobile-component-target",attrs:{"name":`step-mobile-component-target-${i}`}})],1)])}),0),_c('div',{staticClass:"step__content-column step__content-column-desktop list-content"},[_c('div',{ref:"scrollAnchorRef",staticClass:"scroll-anchor"}),_vm._l((_vm.steps),function(step,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(i === _vm.activeStepIndex),expression:"i === activeStepIndex"}],key:`step-components-${i}`},[_c('portal-target',{attrs:{"name":`step-desktop-component-target-${i}`}})],1)})],2)]),_vm._l((_vm.steps),function(step,i){return _c('portal',{key:`dynamic-list-portal-${i}`,attrs:{"to":_vm.viewport.gt.md
        ? `step-desktop-component-target-${i}`
        : `step-mobile-component-target-${i}`}},[_c(step.component,{directives:[{name:"show",rawName:"v-show",value:(i === _vm.activeStepIndex),expression:"i === activeStepIndex"}],ref:"stepComponentsRef",refInFor:true,tag:"component",on:{"form-success":(event) => _vm.handleFormSuccess(i, event),"form-error":(event) => _vm.handleFormError(i, event),"is-dirty":() => _vm.handleComponentDirty(i)}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }