<template>
  <div class="label-description">
    <h2 class="step-form__title">Co ma się znaleźć na etykiecie</h2>
    <p class="step-form__subtitle">
      Wybierz poniższe opcje (maksymalnie {{ maxChoiceCount }})
    </p>
    <label
      v-for="choice in labelDescriptionChoices"
      :key="choice.value"
      class="label-checkbox"
      ><input
        v-model="chosenValues"
        type="checkbox"
        :value="choice.value"
        :disabled="updatingOrder"
        @input="handleInput"
      />
      <span>
        {{ choice.display_name }}
      </span>
    </label>
    <loading-indicator :loading="!labelDescriptionChoices || updatingOrder" />
    <error-item :errors="errors" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";
import updateOrderMixin from "@/mixins/updateOrderMixin";

export default {
  name: "EnvelopeLabelDescription",
  components: { LoadingIndicator },
  mixins: [updateOrderMixin],
  data() {
    return {
      labelDescriptionChoices: [],
      chosenValues: null,
      maxChoiceCount: 5,
    };
  },
  computed: {
    ...mapState("orders", ["activeForm", "cardOrderOptions"]),
  },
  watch: {
    chosenValues() {
      this.updateOrder({ label_descriptions: this.chosenValues });
    },
  },
  async mounted() {
    const {
      cardOrderOptions,
      activeForm: { label_descriptions },
    } = this;
    this.chosenValues = label_descriptions;
    this.labelDescriptionChoices =
      cardOrderOptions.actions.POST.label_descriptions.child.choices;
  },
  methods: {
    handleInput(event) {
      if (this.chosenValues.length === this.maxChoiceCount) {
        event.target.checked = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: block;
}

.label-description {
  margin-top: 30px;
  margin-bottom: 10px;
}
</style>
