<template>
  <div>
    <info-box>
      W tym miejscu możesz dołączyć logo zgodne z wytycznymi dostępnymi niżej.
      Możesz zaznaczyć opcję karty z logo i nie załączać plików - w takim
      wypadku skontaktujemy się z Tobą w celu ustalenia szczegółów.<br />
      <a class="link" :href="logoSpec" target="_blank"
        >Pobierz plik z wytycznymi</a
      >
    </info-box>

    <input-radio-group
      v-model="isLogoSelected"
      :items="logoSelectedItems"
      name="logo-selected-radio-btns"
      :active-radio="isLogoSelected"
      :disabled="updatingOrder"
    />

    <div v-show="isLogoSelected">
      <template v-if="logoColors && logoColors.length">
        <h2 class="step-form__title">Kolor logo</h2>
        <p class="step-form__subtitle">Wybierz jedną z poniższych opcji</p>

        <input-radio-group
          v-model="activeLogoColor"
          :items="logoColors"
          name="logo-color-radio-btns"
          :active-radio="activeLogoColor"
          label-key="display_name"
          :disabled="updatingOrder"
        />
      </template>
      <info-box margin-top="30px" margin-bottom="0"
        >Załącz tylko jeden plik w formacie zgodnym z wytycznymi.
      </info-box>
      <file-upload
        v-if="isLogoSelected"
        class="file-uploader"
        file-upload-type="logo"
        field-name="logo_file"
        http-method="PATCH"
        :url-to-upload="fileUploadUrl"
        :files="uploadedFiles"
        @file-removed="onFileRemoved"
        @file-upload-error="handleFileUploadError"
        @file-uploaded-successfully="fileUploadErrors = {}"
      />
      <error-item :errors="fileUploadErrors" />
    </div>

    <loading-indicator :loading="updatingOrder || !logoColors" />
    <error-item :errors="errors" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

import updateOrderMixin from "@/mixins/updateOrderMixin";

import FileUpload from "@/views/components/formFields/FileUpload.vue";
import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";

import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

import { fetchFromApi } from "@/utils/api";
import { obtainFetchError, FormValidationError } from "@/utils/errors";

import { cardOrderAPIPoint } from "@/apiEndpoints";

import logoSpec from "@/pdf/Specyfikacja_Edenred_LOGO_NA_KARCIE.pdf";

export default {
  name: "CustomLogo",
  components: {
    FileUpload,
    LoadingIndicator,
    InfoBox,
    InputRadioGroup,
  },
  mixins: [updateOrderMixin],
  data() {
    return {
      logoSpec,
      isLogoSelected: null,
      logoSelectedItems: [
        { label: "Karta bez własnego logo", value: false },
        { label: "Karta z własnym logo", value: true },
      ],
      logoColors: null,
      activeLogoColor: "",
      uploadedFiles: [],
      fileUploadErrors: {},
    };
  },
  computed: {
    ...mapGetters("orders", ["getActiveCardOrderId"]),
    ...mapState("orders", ["activeForm", "cardOrderOptions"]),
    fileUploadUrl() {
      const { getActiveCardOrderId } = this;
      return `${cardOrderAPIPoint}${getActiveCardOrderId}/`;
    },
    hasValidationErrors() {
      return (
        this.activeForm.has_logo === null || Object.keys(this.errors).length
      );
    },
  },
  watch: {
    async isLogoSelected(isSelected) {
      if (!isSelected) {
        await this.updateOrder({ logo_color: "", has_logo: isSelected });
        this.uploadedFiles = [];
        this.fileUploadErrors = {};
        this.activeLogoColor = "";
        this.updateBillingData();
        return;
      }

      await this.updateOrder({ has_logo: isSelected });
      this.updateBillingData();
    },
    activeLogoColor(activeColor) {
      const { updateOrder } = this;
      updateOrder({ logo_color: activeColor });
    },
    hasValidationErrors(hasErrors) {
      if (hasErrors) {
        this.$emit("form-error");
        return;
      }
      this.$emit("form-success");
    },
    // user can pick has_logo or has_picture_card
    // these options can't be selected simultaneously
    // this watcher clears related error message when user
    // corrects his form in CustomPicture component
    "activeForm.has_picture_card": {
      async handler() {
        if (this.errors.has_logo) {
          await this.updateOrder({ has_logo: this.isLogoSelected });
        }
      },
    },
  },
  async mounted() {
    const {
      cardOrderOptions,
      activeForm: { logo_color, logo_file, has_logo },
    } = this;
    this.isLogoSelected = has_logo;

    const {
      actions: {
        POST: {
          logo_color: { choices },
        },
      },
    } = cardOrderOptions;
    this.logoColors = choices;

    if (logo_color) {
      this.activeLogoColor = logo_color;
    }

    if (logo_file) {
      this.uploadedFiles = [
        {
          source: logo_file,
          options: {
            type: "local",
          },
        },
      ];
    }

    if (!this.hasValidationErrors) {
      this.$emit("form-success");
    }
  },
  methods: {
    ...mapActions("orders", ["updateBillingData"]),
    clearLogoFile() {
      const { fileUploadUrl } = this;

      const formData = new FormData();
      formData.append("logo_file", "");
      try {
        fetchFromApi(fileUploadUrl, {
          method: "PATCH",
          body: formData,
        });
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      }
    },
    onFileRemoved() {
      this.clearLogoFile();
    },
    handleFileUploadError(errors) {
      this.fileUploadErrors = errors;
      this.uploadedFiles = [];
    },
    beforeComponentChange() {
      if (this.hasValidationErrors) {
        throw new FormValidationError(
          "CustomLogo dynamic list validation error",
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  font-weight: $font-weight-bold;
}

.file-uploader {
  margin-top: 32px;
}

.step-form__title {
  margin-top: 40px;
}
</style>
