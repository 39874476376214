<template>
  <div>
    <h2 class="step-form__title">Członek zarządu {{ index + 1 }}</h2>
    <text-input v-model="form.name" :errors="errors.name">
      Imiona i nazwiska
    </text-input>
    <text-input v-model="form.position" :errors="errors.position">
      Stanowisko
    </text-input>
    <text-input
      v-model="form.pesel"
      :errors="errors.pesel"
      @input-blur="handlePESELBlur"
    >
      Numer PESEL
    </text-input>
    <input-date-picker v-model="form.birth_date" :errors="errors.birth_date">
      Data urodzenia
    </input-date-picker>
    <text-input
      v-model="form.birth_place"
      required
      double-star
      :errors="errors.birth_place"
    >
      Miejsce urodzenia
    </text-input>
    <text-input
      v-model="form.address_of_residence"
      required
      double-star
      :errors="errors.address_of_residence"
    >
      Adres zamieszkania
    </text-input>
  </div>
</template>

<script>
import TextInput from "@/views/components/formFields/TextInput.vue";
import InputDatePicker from "@/views/components/formFields/InputDatePicker.vue";
import validatePESEL from "@/utils/validatePESEL";

export default {
  name: "BoardRepresentative",
  components: {
    TextInput,
    InputDatePicker,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    errors: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        localId: "",
        id: "",
        name: "",
        position: "",
        pesel: "",
        birth_date: "",
        birth_place: "",
        address_of_residence: "",
      },
    };
  },
  watch: {
    form: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  methods: {
    handlePESELBlur(value) {
      if (this.form.birth_date) {
        return;
      }

      const { isValid, birthDate } = validatePESEL(value);
      if (!isValid) {
        return;
      }
      this.form.birth_date = birthDate;
    },
  },
  created() {
    Object.keys(this.form).forEach((key) => (this.form[key] = this.value[key]));
  },
};
</script>
