<template>
  <div :class="['form-group', { disabled }]">
    <div class="label"><slot></slot> {{ required ? "*" : "" }}</div>
    <div class="input-wrap">
      <input
        :class="['input', { 'input--error': hasErrors }, 'input-autocomplete']"
        v-bind="$attrs"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        @blur="$emit(`blur`, value)"
        @input="
          $emit(
            'input',
            multiselect
              ? textToArray($event.target.value)
              : $event.target.value,
          )
        "
      />
      <img
        v-if="items.length && !disabled"
        class="icon--toggle"
        :class="isOpen ? 'is-open' : ''"
        src="@/images/icon-chevron-right.svg"
        alt=""
        @click="isOpen = !isOpen"
      />
      <ul v-if="items.length" v-show="isOpen" class="autocomplete-results">
        <li
          v-for="(item, itemIndex) in items"
          :key="`autocomplete-item-${itemIndex}`"
          class="autocomplete-item"
          :class="multiselect ? 'is-multiselect' : ''"
          @click="() => handleItemClick(item)"
        >
          <label v-if="multiselect" class="label-checkbox">
            <input
              v-model="selectedItems"
              :value="item"
              type="checkbox"
              @change="handleItemChange"
            />
            <span>
              {{ item[itemLabel] || item }}
            </span>
          </label>
          <span v-else>
            {{ item[itemLabel] || item }}
          </span>
        </li>
      </ul>
    </div>
    <error-item v-if="errorsWithIndex" :errors="errorsWithIndex" />
  </div>
</template>

<script>
export default {
  name: "InputTextSelect",
  inheritAttrs: false,
  props: {
    errors: {
      type: [Array, Object, String],
      default() {
        return {};
      },
    },
    value: {
      type: [String, Array],
      default() {
        return "";
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    itemLabel: {
      type: String,
      default() {
        return "value";
      },
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      selectedItems: [],
    };
  },
  computed: {
    hasErrors() {
      if (Array.isArray(this.errors)) {
        return this.errors.length > 0;
      } else if (typeof this.errors === "object") {
        return Object.keys(this.errors).length > 0;
      } else if (typeof this.errors === "string" && this.errors) {
        return true;
      } else {
        return false;
      }
    },

    errorsWithIndex() {
      if (Array.isArray(this.errors)) {
        // Errors represented as an array are relevant for whole field, so we wont add any index.
        return this.errors;
      }
      if (typeof this.errors === "object") {
        /*
        Errors may be represented as an object with entries for each element, for example.
        {
          0: ["error regarding first element"],
          4: ["error regarding fifth element", "blabla"],
        }
        We want transform it to something like this:
        [
          "#0: error regarding first element",
          "#4: error regarding fifth element",
          "#4: blabla",
        ]
        */
        let errorsWithIndex = [];
        Object.keys(this.errors).forEach((key) => {
          const i = parseInt(key);
          const indexPrefix = `Błąd w elemencie nr ${i + 1}: `;
          this.errors[key].forEach((error) => {
            errorsWithIndex.push(`${indexPrefix}${error}`);
          });
        });
        return errorsWithIndex;
      }
      return this.errors;
    },

    recognizedItems() {
      const { items, value, multiselect } = this;
      if (!multiselect) {
        return [];
      }
      return items.filter((item) => value.includes(item));
    },
  },
  watch: {
    value: {
      handler() {
        const { multiselect, recognizedItems } = this;
        if (multiselect) {
          this.selectedItems = recognizedItems;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleItemClick(item) {
      const { multiselect } = this;
      if (multiselect) {
        return;
      }

      this.$emit("item-selected", item);
      this.isOpen = false;
    },
    textToArray(text) {
      return text.split(",").map((el) => el.trim());
    },
    handleItemChange(event) {
      const { value } = this;
      const {
        target: { value: itemValue, checked },
      } = event;
      if (checked) {
        if (value && !value[0]) {
          this.$emit("input", [itemValue]);
          return;
        }
        this.$emit("input", [...value, itemValue]);
      } else {
        const valueCopy = [...value];
        const itemIndex = valueCopy.indexOf(itemValue);
        valueCopy.splice(itemIndex, 1);
        this.$emit("input", valueCopy);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrap {
  position: relative;
}

.icon--toggle {
  position: absolute;
  right: 0;
  bottom: 2px;
  transform: rotate(90deg);
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s;

  &.is-open {
    transform: rotate(270deg);
  }
}

.autocomplete-results {
  margin: 0;
  border-left: 1px solid $border-color-light;
  border-right: 1px solid $border-color-light;
  list-style: none;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  z-index: 6;
  background: white;
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.autocomplete-item {
  padding: 12px 12px;
  border-bottom: 1px solid $border-color-light;
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }

  &.is-multiselect {
    padding: 0;
    .label-checkbox {
      padding: 12px 12px;
      cursor: pointer;
    }
  }
}

.input-autocomplete {
  padding-right: 38px;
}
</style>
