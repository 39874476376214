<template>
  <invoice-details-form
    ref="invoiceDetailsRef"
    :api-endpoint="apiEndpoint"
    :form-data-object="getActiveReloadFormRecipientInvoiceData"
    :form-data-object-prefix="formDataPrefix"
    invoice-details-type="recipient"
    @form-success="handleFormSuccess"
    @request-update="reloadActiveReloadFormData"
    @is-dirty="$emit('is-dirty')"
  />
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import { orderAPIPoint } from "@/apiEndpoints";

import InvoiceDetailsForm from "@/views/components/formFields/invoice_details/InvoiceDetailsForm.vue";

export default {
  name: "ReloadOrderInvoiceRecipientDetails",
  components: { InvoiceDetailsForm },
  computed: {
    ...mapState("reloads", ["activeReloadOrderId"]),
    ...mapGetters("reloads", [
      "getActiveFormReloadInvoiceData",
      "getActiveCardReloadOrderId",
      "getActiveReloadFormRecipientInvoiceData",
    ]),
    apiEndpoint() {
      return `${orderAPIPoint}${this.getActiveCardReloadOrderId}/?card_reload_order=${this.activeReloadOrderId}`;
    },
  },
  methods: {
    ...mapActions("reloads", ["reloadActiveReloadFormData"]),
    formDataPrefix() {
      return "invoice_recipient_";
    },
    handleFormSuccess(event) {
      this.$emit("form-success", event);
    },
    // used by DynamicList.vue component
    async beforeComponentChange() {
      const {
        $refs: {
          invoiceDetailsRef: { beforeComponentChange },
        },
      } = this;
      if (beforeComponentChange) {
        await beforeComponentChange();
      }
    },
  },
};
</script>
