<template>
  <div class="person-details">
    <text-input
      id="name"
      v-model="localDataObj.name"
      :errors="errors.name"
      required
    >
      Imię i nazwisko osoby składającej zamówienie
    </text-input>

    <text-input
      id="position"
      v-model="localDataObj.position"
      :errors="errors.position"
      required
    >
      Stanowisko
    </text-input>

    <text-input
      id="telephone"
      v-model="localDataObj.phone_number"
      :errors="errors.phone_number"
      input-type="number"
      required
    >
      Numer telefonu
    </text-input>

    <text-input
      id="email"
      v-model="localDataObj.email_address"
      :errors="errors.email_address"
      required
    >
      E-mail
    </text-input>
  </div>
</template>

<script>
import _isEqual from "lodash.isequal";
import { mapGetters, mapState } from "vuex";

import { orderingPersonAPIPoint } from "@/apiEndpoints";
import TextInput from "@/views/components/formFields/TextInput.vue";
import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";
import { formObjectHasValues } from "@/utils/utilityFunctions";

export default {
  name: "OrderingPersonForm",
  components: { TextInput },
  props: {
    queryParams: {
      type: String,
      default: "",
    },
    formDataObject: {
      type: Object,
      default() {
        return {
          name: "",
          position: "",
          phone_number: "",
          email_address: "",
        };
      },
    },
  },
  data() {
    return {
      errors: {},
      localDataObj: {},
      savedDataObj: this.formDataObject,
    };
  },
  computed: {
    ...mapState("orders", ["activeForm"]),
    ...mapGetters("orders", ["getActiveCardOrderId"]),
    contactPersons() {
      return this.activeForm.contact_persons || [];
    },
    isDirty() {
      return (
        formObjectHasValues(this.savedDataObj) &&
        !_isEqual(this.localDataObj, this.savedDataObj)
      );
    },
  },
  watch: {
    e_invoice_consent(value) {
      if (!value) {
        this.localDataObj.proforma_emails = "";
        this.localDataObj[this.nameWithPrefix("email")] = "";
      }
    },
    isDirty(val) {
      if (val) {
        this.$emit("is-dirty");
      }
    },
  },
  created() {
    this.localDataObj = { ...this.savedDataObj };
    if (formObjectHasValues(this.savedDataObj)) {
      this.$emit("form-success");
    }
  },
  methods: {
    async postOrderingPerson() {
      const {
        localDataObj: { name, position, phone_number, email_address },
      } = this;
      const requestBody = {
        name,
        position,
        phone_number,
        email_address,
      };

      try {
        if (this.contactPersons.length) {
          const { id } = this.contactPersons[0];
          await fetchFromApi(
            `${orderingPersonAPIPoint}${id}/?${this.queryParams}`,
            {
              method: "PATCH",
              data: requestBody,
            },
          );
        } else {
          await fetchFromApi(`${orderingPersonAPIPoint}?${this.queryParams}`, {
            method: "POST",
            data: requestBody,
          });
        }
        this.savedDataObj = { ...this.localDataObj };
        this.errors = {};
        // needed to update data by parent component
        this.$emit("request-update");
        return true;
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
        throw errorObj;
      }
    },
    // used by DynamicList.vue component
    async beforeComponentChange() {
      await this.postOrderingPerson();
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container--center {
  margin-bottom: 50px;
}
</style>
