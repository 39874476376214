<template>
  <additional-details-form
    ref="additionalDetailsRef"
    :api-endpoint="apiEndpoint"
    :form-data-object="getAdditionalCardOrderDetails"
    @form-success="handleFormSuccess"
    @request-update="reloadActiveFormData"
    @is-dirty="$emit('is-dirty')"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { orderAPIPoint } from "@/apiEndpoints";

import AdditionalDetailsForm from "@/views/components/formFields/additional_details/AdditionalDetailsForm.vue";

export default {
  name: "CardOrderAdditionalDetails",
  components: { AdditionalDetailsForm },
  computed: {
    ...mapGetters("orders", [
      "getActiveOrderId",
      "getActiveCardOrderId",
      "getAdditionalCardOrderDetails",
    ]),
    apiEndpoint() {
      return `${orderAPIPoint}${this.getActiveOrderId}/?card_order=${this.getActiveCardOrderId}`;
    },
  },
  methods: {
    ...mapActions("orders", ["reloadActiveFormData"]),
    async handleFormSuccess($event) {
      this.$emit("form-success", $event);
    },
    // used by DynamicList.vue component
    async beforeComponentChange() {
      const {
        $refs: {
          additionalDetailsRef: { beforeComponentChange },
        },
      } = this;
      if (beforeComponentChange) {
        await beforeComponentChange();
      }
    },
  },
};
</script>
