<template>
  <div class="address-wrapper">
    <div v-if="currentAddresses.length" class="data-list">
      <h2 class="data-list-title">{{ title }}</h2>
      <div
        v-if="showHeaders"
        class="data-list-item__header"
        :class="{ 'is-wide': isModifiable }"
      >
        <div v-if="isModifiable" class="data-list-column-number"></div>
        <div class="data-list-column__header"></div>
        <div class="data-list-column__header">Osoba upoważniona</div>
      </div>
      <addresses-list-item
        v-for="(address, i) in currentAddresses"
        :key="`address-${address.id}`"
        :address="address"
        :address-index="i"
        :loading-address="deleteAddressLoading[address.id]"
        :is-deletable="isModifiable"
        :show-index="isModifiable"
        @delete-address="deleteAddress(address.id)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { addressAPIPoint } from "@/apiEndpoints";
import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";

import AddressesListItem from "@/views/components/formFields/AddressesListItem.vue";

export default {
  name: "AddressesList",
  components: { AddressesListItem },
  props: {
    addresses: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: "Lista dodanych adresów",
      required: false,
    },
    isModifiable: {
      type: Boolean,
      default: true,
    },
    showHeaders: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      deleteAddressLoading: {},
      errors: {},
    };
  },
  computed: {
    ...mapGetters("orders", ["getCurrentAddresses", "getActiveCardOrderId"]),
    currentAddresses() {
      if (this.addresses) {
        return this.addresses;
      } else {
        return this.getCurrentAddresses;
      }
    },
  },
  methods: {
    ...mapActions("orders", ["reloadActiveFormData", "updateBillingData"]),
    async deleteAddress(addressId) {
      const {
        deleteAddressLoading,
        getActiveCardOrderId,
        reloadActiveFormData,
        updateBillingData,
      } = this;

      if (deleteAddressLoading[addressId]) {
        return;
      }

      this.deleteAddressLoading = {
        ...deleteAddressLoading,
        [addressId]: true,
      };

      try {
        await fetchFromApi(
          `${addressAPIPoint}${addressId}/?card_order=${getActiveCardOrderId}`,
          {
            method: "DELETE",
            json: false,
          },
        );

        this.newAddress = {};
        this.errors = {};
        await reloadActiveFormData();
        await updateBillingData();
        this.$emit("address-changed");
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      }

      this.deleteAddressLoading = {
        ...deleteAddressLoading,
        [addressId]: false,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.address-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.data-list-column__header {
  width: 33.3%;
}

.data-list-item__header.is-wide {
  .data-list-column__header {
    width: 30%;
  }
}
</style>
