export default {
  getActiveFormBilling: (state) => {
    return state.activeFormBilling.billingArray;
  },
  isFormBillingFinal: (state) => {
    return state.activeFormBilling.isBillingFinal;
  },
  getActiveFormInvoiceOptions: (
    { activeFormInvoiceOptions: { showRecipient } = {} },
    getters,
    {
      orders: { activeForm: { order: { invoice_recipient_vat_id } = {} } = {} },
    },
  ) => {
    return {
      showRecipient: showRecipient || !!invoice_recipient_vat_id,
    };
  },
  isLastFormStep: (state) => {
    return state.activeFormStep === state.formSteps.length - 1;
  },
  getDefaultContractByProductId:
    (state, getters, { user: { defaultContracts } }) =>
    (productId) => {
      return defaultContracts.find(({ commercial_products_set }) =>
        commercial_products_set.some(
          ({ commercial_product: { id } }) => id === productId,
        ),
      );
    },
  getActiveNofContractCardType: ({
    activeNofContract: { card_types = [] } = {},
  }) => {
    return card_types[0] || {};
  },
  getActiveNofContractProductsPictures: (
    { activeNofContract: { commercial_products_set = [] } = {} },
    getters,
    rootState,
    rootGetters,
  ) => {
    const activeProductId = rootGetters["orders/getActiveProductTypeId"];
    const product =
      commercial_products_set.filter(
        (product) => product.commercial_product.id === activeProductId,
      )[0] || {};
    return product.pictures || [];
  },
};
