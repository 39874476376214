<template>
  <order-summary
    :billing-array="activeReloadFormBilling.billingArray"
    :is-billing-final="activeReloadFormBilling.isBillingFinal"
    :errors="activeReloadFormBillingReloadError"
    :loading="activeReloadFormBillingLoading"
  />
</template>

<script>
import { mapState } from "vuex";
import OrderSummary from "@/views/components/OrderSummary/OrderSummary.vue";

export default {
  name: "FormOrderSummary",
  components: { OrderSummary },
  computed: {
    ...mapState("reloads", [
      "activeReloadFormBilling",
      "activeReloadFormBillingReloadError",
      "activeReloadFormBillingLoading",
    ]),
  },
};
</script>
