<template>
  <div :class="['form-group', { disabled }]">
    <label class="label">
      <slot></slot> {{ required ? `${doubleStar ? "*" : ""}*` : "" }}
    </label>
    <div class="text-input-wrap" :class="{ 'has-edit-icon': hasEditIcon }">
      <input
        :class="['input', { 'input--error': hasErrors }, { nospinners }]"
        v-bind="$attrs"
        :type="inputType"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        @blur="$emit(`input-blur`, value)"
        @input="
          $emit(
            'input',
            toArray ? textToArray($event.target.value) : $event.target.value,
          )
        "
      />
      <img
        v-if="hasEditIcon"
        class="edit-icon"
        src="~@/images/icon-pencil.svg"
        alt="Edytuj"
        @click="$emit('edit')"
      />
    </div>
    <error-item v-if="errors" :errors="errors" />
  </div>
</template>

<script>
export default {
  name: "TextInput",
  inheritAttrs: false,
  props: {
    toArray: {
      type: Boolean,
      default() {
        return false;
      },
    },
    inputType: {
      type: String,
      default() {
        return "text";
      },
    },
    errors: {
      type: [Array, Object, String],
      default() {
        return {};
      },
    },
    value: {
      type: [String, Array, Number],
      default() {
        return "";
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    nospinners: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    hasEditIcon: {
      type: Boolean,
      default: false,
    },
    doubleStar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasErrors() {
      if (Array.isArray(this.errors)) {
        return this.errors.length > 0;
      } else if (typeof this.errors === "object" && this.errors !== null) {
        return Object.keys(this.errors).length > 0;
      } else if (typeof this.errors === "string" && this.errors) {
        return true;
      }
      return false;
    },
  },
  methods: {
    textToArray(text) {
      return text.split(",").map((el) => el.trim());
    },
  },
};
</script>
<style lang="scss" scoped>
.nospinners.input[type="number"]::-webkit-inner-spin-button,
.nospinners.input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-input-wrap {
  position: relative;

  &.has-edit-icon {
    .input {
      padding-right: 48px;
    }
  }
}

.edit-icon {
  position: absolute;
  right: 16px;
  bottom: 12px;
  cursor: pointer;
}
</style>
