<template>
  <div class="step step--with-stepper">
    <h2 class="step-form__title">
      Uzupełnij tylko w przypadku, gdy jest zawarta umowa podpisana w imieniu
      klienta, nie przez Zarząd / prokurentów, a tylko przez pełnomocnika. W
      przypadku braku choćby jednej osoby upoważnionej do działania w imieniu
      Klienta należy wskazać „Nie dotyczy”
    </h2>
    <label class="label-checkbox">
      <input v-model="noAuthorizedPersons" type="checkbox" />
      <span> Nie dotyczy</span>
    </label>
    <AppRepeater
      v-if="!noAuthorizedPersons"
      ref="repeaterRef"
      :template="authorizedPersonTemplate"
      :component="repeaterComponent"
      :delete-handler="deleteAuthorizedPerson"
      :patch-handler="patchAuthorizedPerson"
      :post-handler="postAuthorizedPerson"
      :load-handler="loadAuthorizedPersons"
      :items="authorizedPersons"
      :loading.sync="loading"
    />
    <error-item :errors="errors" />
    <div class="buttons-container buttons-container--main">
      <action-button secondary @click="setPrevFormStep"> Wstecz </action-button>
      <action-button :loading="loading" @click="handleNextStep">
        Dalej
      </action-button>
    </div>
  </div>
</template>

<script>
import AuthorizedPerson from "@/views/components/aml/AuthorizedPerson.vue";
import AppRepeater from "@/views/components/formFields/AppRepeater.vue";
import { mapActions, mapState } from "vuex";
import { obtainFetchError } from "@/utils/errors";

export default {
  name: "AMLFormStep3",
  components: {
    AppRepeater,
  },
  data() {
    return {
      loading: false,
      noAuthorizedPersons: false,
      repeaterComponent: AuthorizedPerson,
      authorizedPersonTemplate: {
        name: "",
        citizenship: "",
        has_pesel: true,
        pesel: "",
        birth_date: null,
        birth_country: "",
        identity_document_id: "",
        authorization_document: null,
      },
      deleteLoading: false,
      errors: {},
    };
  },
  computed: {
    ...mapState("aml", ["authorizedPersons"]),
  },
  created() {
    if (this.authorizedPersons.length === 0) {
      this.noAuthorizedPersons = true;
    }
  },
  methods: {
    ...mapActions("aml", [
      "setNextFormStep",
      "setPrevFormStep",
      "postAuthorizedPerson",
      "deleteAuthorizedPerson",
      "deleteAuthorizedPersons",
      "patchAuthorizedPerson",
      "loadAuthorizedPersons",
    ]),
    async handleNextStep() {
      try {
        if (!this.noAuthorizedPersons) {
          await this.$refs.repeaterRef?.submit();
        } else if (this.authorizedPersons.length > 0) {
          this.deleteLoading = true;
          await this.deleteAuthorizedPersons(
            this.authorizedPersons.map((v) => v.id),
          );
        }
        this.setNextFormStep();
      } catch (errorObj) {
        console.error(errorObj);
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      } finally {
        this.deleteLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.label-checkbox {
  margin-bottom: 32px;
}

.buttons-container--main {
  margin-top: 32px;
}
</style>
