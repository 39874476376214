<template>
  <div class="additional-data">
    <h2 class="step-form__title">Informacja o rachunku</h2>
    <!-- TODO: Add a subtitle here when we have copy -->

    <info-box>
      Prosimy o podanie numeru rachunku bankowego, z którego będzie realizowany
      przelew za zamówienie. Numer konta jest niezbędny do wykonywania zwrotu
      błędnych płatności.
    </info-box>
    <text-input
      id="bank-account"
      v-model="localDataObj.bank_account_number"
      :errors="errors.bank_account_number"
      :input-type="`number`"
      :nospinners="true"
    >
      Nr konta bankowego
    </text-input>

    <error-item :errors="errors" />
  </div>
</template>

<script>
import _isEqual from "lodash.isequal";

import TextInput from "@/views/components/formFields/TextInput.vue";
import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";

import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";
import { formObjectHasValues } from "@/utils/utilityFunctions";

export default {
  name: "AdditionalDetailsForm",
  components: { TextInput, InfoBox },
  props: {
    apiEndpoint: { type: String, required: true },
    formDataObject: { type: Object, required: true },
  },
  data() {
    return {
      loadingAdditionalDetails: false,
      localDataObj: {},
      savedDataObj: this.formDataObject,
      errors: {},
    };
  },
  computed: {
    isDirty() {
      return (
        formObjectHasValues(this.savedDataObj) &&
        !_isEqual(this.localDataObj, this.savedDataObj)
      );
    },
  },
  watch: {
    isDirty(val) {
      if (val) {
        this.$emit("is-dirty");
      }
    },
  },
  created() {
    this.localDataObj = { ...this.savedDataObj };
    if (formObjectHasValues(this.savedDataObj)) {
      this.$emit("form-success");
    }
  },
  methods: {
    async addAdditionalDetails() {
      try {
        await fetchFromApi(this.apiEndpoint, {
          method: "PATCH",
          data: { ...this.localDataObj },
        });

        this.savedDataObj = { ...this.localDataObj };
        this.errors = {};
        // needed to update data by parent component
        this.$emit("request-update");
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
        throw errorObj;
      }
    },
    // used by DynamicList.vue component
    async beforeComponentChange() {
      await this.addAdditionalDetails();
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container--center {
  margin-bottom: 50px;
}
</style>
