<template>
  <div>
    <file-pond
      ref="filePond"
      class="orders-upload"
      :name="fieldName"
      :label-idle="uploadInfoText"
      label-file-loading="Wysyłanie pliku..."
      label-file-processing-error="Wystąpił błąd przy wysyłaniu pliku"
      label-file-processing="Wysyłanie pliku..."
      label-file-type-not-allowed="Niedozwolony typ pliku"
      file-validate-type-label-expected-types="Wymagany plik typu: {allButLastType} lub {lastType}"
      label-file-processing-complete="Przesłano poprawnie"
      label-tap-to-retry="spróbuj ponownie"
      label-tap-to-cancel="anuluj wysyłanie"
      label-tap-to-undo="cofnij"
      :allow-multiple="false"
      :server="serverOptions"
      :accepted-file-types="fileTypeToUpload"
      :files="files"
      :instant-upload="instantUpload"
      :allow-process="false"
      :file-validate-type-detect-type="getMIMEType"
      @addfile="handleFileAdded"
      @processfile="fileProcessed"
      @removefile="fileRemoved"
      @error="handleFileError"
    />
    <div class="errors">
      <p v-if="error.main" class="form-error text-color-error">
        {{ error.main }}
      </p>
      <p v-if="error.sub" class="form-error text-color-error">
        {{ error.sub }}
      </p>
    </div>
  </div>
</template>

<script>
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import mime from "mime/lite";
import { mapState } from "vuex";
import { getAuthToken } from "@/utils/authToken";
import { mainAPIPoint } from "@/apiEndpoints";

const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  name: "FileUpload",
  components: { FilePond },
  props: {
    fileUploadType: {
      type: String,
      required: true,
    },
    urlToUpload: {
      type: String,
      default: "",
    },
    loadUrl: {
      type: String,
      default: "",
    },
    fieldName: {
      type: String,
      required: true,
    },
    httpMethod: {
      type: String,
      default: "POST",
    },
    files: {
      type: Array,
      default() {
        return [];
      },
    },
    instantUpload: {
      type: Boolean,
      default: true,
    },
    timeout: {
      type: Number,
      default: 10000,
    },
  },
  data() {
    return {
      uploadInfoText:
        "<div>Przeciągnij i upuść tutaj</div><div>lub</div><div class='upload-file-label'>Przeglądaj pliki</div>",
      acceptedFileTypes: {
        logo: "application/postscript, application/pdf, application/eps, application/x-eps, application/illustrator, image/eps, image/x-eps",
        image: "image/jpeg, image/png",
        excel:
          "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        document:
          "image/jpeg, image/png, application/pdf, application/xml, text/xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        textDocument:
          "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        pdf: "application/pdf",
      },
      error: {
        main: "",
        sub: "",
      },
    };
  },
  computed: {
    ...mapState("user", ["selectedClient"]),
    fileTypeToUpload() {
      return this.acceptedFileTypes[this.fileUploadType];
    },
    serverOptions() {
      const { selectedClient: { id: selectedClientId } = {} } = this;
      const options = {
        process: {
          url: `${mainAPIPoint}${this.urlToUpload}`,
          method: this.httpMethod,
          withCredentials: true,
          headers: { Authorization: `Token ${getAuthToken()}` },
          ...(this.timeout && { timeout: this.timeout }),
          onload: (payload) =>
            this.$emit("file-uploaded-successfully", payload),
          onerror: (payload) => {
            try {
              const payloadObj = JSON.parse(payload);
              this.$emit("file-upload-error", payloadObj);
            } catch (errorObj) {
              this.$emit("file-upload-error", {
                non_field_errors: ["JSON errors parsing error"],
              });
            }
          },
        },
        load: {
          headers: {
            Authorization: `Token ${getAuthToken()}`,
          },
        },
      };
      if (selectedClientId) {
        options.process.headers["Edenred-Client"] = selectedClientId;
        options.load.headers["Edenred-Client"] = selectedClientId;
      }
      return options;
    },
  },
  methods: {
    handleFileAdded() {
      this.$emit("file-added", this.$refs.filePond.getFiles());
    },
    fileProcessed() {
      this.error = {};
      this.$emit("file-processed");
    },
    fileRemoved() {
      this.error = {};
      this.$emit("file-removed");
    },
    processFiles() {
      this.$refs.filePond.processFiles();
    },
    removeFiles() {
      this.$refs.filePond.removeFiles();
    },
    getMIMEType(source, type) {
      return new Promise((resolve) => {
        if (!type) {
          const mimeType = mime.getType(source.name);
          resolve(mimeType);
          return;
        }

        resolve(type);
      });
    },
    handleFileError(error) {
      this.error = error;
      this.$emit("error", error);
    },
  },
};
</script>
<style>
.filepond--drop-label .upload-file-label {
  text-decoration: underline;
  cursor: pointer;
}
</style>
<style lang="scss">
@import "~filepond/dist/filepond.min.css";

.file-uploader {
  margin: 20px 0;
}

.filepond--wrapper {
  border: 1px dashed $border-color;
  border-radius: 4px;
}

.filepond--root {
  height: 168px !important;
  font-family: $font-primary;
  margin: 0;

  .filepond--drop-label {
    border-radius: 4px;
    padding: 20px;
    min-height: 168px;
    flex-direction: column;
    text-align: center;
    font-size: $summary-font-size;
    color: $color-primary;

    &::before {
      display: inline-block;
      content: url("~@/images/icon-upload.svg");
      width: 20px;
      height: 24px;
    }
  }
}

.filepond--panel-root {
  background-color: white;
}

.upload-file-label {
  font-weight: $font-weight-bold;
}

.filepond--file {
  border-radius: 4px;
  padding: 10px;
}

[data-filepond-item-state="processing-complete"] {
  .filepond--item-panel {
    background-color: $accept-box-background;
    border: 1px solid $color-active;
  }
  .filepond--file-status,
  .filepond--file-info .filepond--file-info-sub,
  .filepond--file-info-main,
  .filepond--file-info-sub {
    color: $color-active;
  }
}

[data-filepond-item-state*="invalid"],
[data-filepond-item-state*="error"] {
  .filepond--item-panel {
    background-color: $error-box-background;
    border: 1px solid $error-box-text;
  }
  .filepond--file-status,
  .filepond--file-info .filepond--file-info-sub,
  .filepond--file-info-main,
  .filepond--file-info-sub {
    color: $error-box-text;
  }
}

.filepond--file-status-sub {
  display: none;
}
</style>
