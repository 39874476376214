<template>
  <div v-if="suborders.length > 1" class="data-list suborders-list">
    <h2 class="data-list-title suborders-title">Lista podzamówień</h2>
    <div class="suborders-table-wrapper">
      <table class="suborders-table">
        <thead>
          <tr>
            <th></th>
            <th>Ilość kart</th>
            <th>Suma</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in suborders"
            :key="item.id"
            class="suborders-list__item"
          >
            <td>
              {{ item.product_configuration.public_name }}
            </td>
            <td>
              {{ item.card_count }}
            </td>
            <td>{{ item.total_value }} zł</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("reloads", ["activeReloadForm"]),
    suborders() {
      return this.activeReloadForm.suborders || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.data-list.suborders-list .suborders-title {
  margin-bottom: 16px;
}

.suborders-table {
  width: 100%;

  th {
    font-size: $small-font-size;
    font-weight: $font-weight-bold;
    padding-bottom: 10px;
  }

  tbody {
    background-color: $color-secondary-light;
    border-radius: 4px;

    tr:first-child {
      td {
        padding-top: 16px;
      }
    }

    tr:last-child {
      td {
        padding-bottom: 16px;
      }
    }
  }

  td,
  th {
    &:first-child {
      padding-left: 20px;
    }

    &:nth-child(2) {
      text-align: center;
    }

    &:last-child {
      text-align: right;

      padding-right: 20px;
    }
  }

  td {
    font-size: $summary-font-size;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;

    &:last-child {
      white-space: nowrap;
    }
  }
}

.suborders-table-wrapper {
  overflow: auto;
}
</style>
