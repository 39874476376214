import { cardReloadOrderAPIPoint } from "@/apiEndpoints";

export default {
  getActiveCardReloadOrderApiPoint: ({ activeReloadOrderId }) =>
    `${cardReloadOrderAPIPoint}${activeReloadOrderId}/`,
  getActiveFormReloadInvoiceData: ({
    activeReloadForm: {
      order: {
        vat_id,
        invoice_company_name,
        invoice_address,
        invoice_post_code,
        invoice_town,
        krs,
        proforma_emails,
        invoice_emails,
        client_ref,
        invoice_country,
      },
    },
  }) => ({
    vat_id,
    invoice_company_name,
    invoice_address,
    invoice_post_code,
    invoice_town,
    krs,
    proforma_emails,
    invoice_emails,
    client_ref,
    invoice_country,
  }),
  getActiveReloadFormRecipientInvoiceData: ({
    activeReloadForm: {
      order: {
        invoice_recipient_vat_id,
        invoice_recipient_company_name,
        invoice_recipient_address,
        invoice_recipient_post_code,
        invoice_recipient_town,
        invoice_recipient_country,
      },
    },
  }) => ({
    invoice_recipient_vat_id,
    invoice_recipient_company_name,
    invoice_recipient_address,
    invoice_recipient_post_code,
    invoice_recipient_town,
    invoice_recipient_country,
  }),
  getAdditionalReloadCardOrderDetails: ({
    activeReloadForm: {
      order: { bank_account_number },
    },
  }) => ({ bank_account_number }),
  getActiveReloadFormOrderingPerson: (state) => {
    return state.activeReloadForm.contact_persons[0];
  },
  getActiveCardReloadOrderId: (state) => {
    return state.activeReloadForm.order.id;
  },
  getActiveReloadFormInvoiceOptions: ({
    activeReloadFormInvoiceOptions: { showRecipient },
    activeReloadForm: {
      order: { invoice_recipient_vat_id },
    },
  }) => {
    return {
      showRecipient: showRecipient || !!invoice_recipient_vat_id,
    };
  },
  isLastReloadFormStep: (state) => {
    return state.activeReloadFormStep === state.reloadFormSteps.length - 1;
  },
};
