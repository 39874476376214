<template>
  <div class="step step--with-stepper">
    <div>
      <h2 class="step-form__title">Informacje o zamierzonych działaniach</h2>
      <p>Dla kogo mają być przeznaczone przedpłacone karty Edenred? *</p>
      <label class="label-checkbox">
        <input v-model="form.employees" name="employees" type="checkbox" />
        <span
          >pracownicy lub współpracownicy (osoby fizyczne nieprowadzące
          działalności gospodarczej i współpracujące na podstawie umowy
          cywilnoprawnej)</span
        >
      </label>
      <label class="label-checkbox">
        <input
          v-model="form.campaign_participants"
          name="campaign_participants"
          type="checkbox"
        />
        <span
          >uczestnicy konkursu, akcji marketingowej, programu motywacyjnego,
          lojalnościowego, bonusowego, Incentive & Reward itp.</span
        >
      </label>
      <template v-if="form.employees">
        <p>
          Jaka jest planowana liczba pracowników i współpracowników w okresie
          najbliższego roku kalendarzowego? *
        </p>
        <input-radio-group
          name="card_users_planned_count_employees"
          v-model="form.card_users_planned_count_employees"
          :items="plannedCountChoices"
          :active-radio="form.card_users_planned_count_employees"
          :errors="errors.card_users_planned_count_employees"
        />
      </template>
      <template v-if="form.campaign_participants">
        <p>
          Jaka jest planowana liczba uczestników programu Incentive & Reward w
          okresie najbliższego roku kalendarzowego? *
        </p>
        <input-radio-group
          name="card_users_planned_count_campaign_participants"
          v-model="form.card_users_planned_count_campaign_participants"
          :items="plannedCountChoices"
          :active-radio="form.card_users_planned_count_campaign_participants"
          :errors="errors.card_users_planned_count_campaign_participants"
        />
      </template>
      <p>
        Jaki jest kontekst przekazania przedpłaconych kart Edenred dla osób
        wskazanych powyżej? *
      </p>
      <label class="label-checkbox">
        <input
          v-model="form.card_usage_non_wage_benefit"
          name="card_usage_non_wage_benefit"
          type="checkbox"
        />
        <span
          >pozapłacowy benefit pracowniczy (karty podarunkowe, karty Sport i
          Kultura, karty dziecięce lub karty wakacyjne)</span
        >
      </label>
      <label class="label-checkbox">
        <input
          v-model="form.card_usage_incentive_and_reward"
          name="card_usage_incentive_and_reward"
          type="checkbox"
        />
        <span> Incentive & Reward (karty premiowe) </span>
      </label>
      <p>
        Jaka jest planowana sumaryczna kwota doładowania przedpłaconych kart
        Edenred na najbliższy rok kalendarzowy? *
      </p>
      <input-radio-group
        name="cards_planned_total_value"
        v-model="form.cards_planned_total_value"
        :items="summaryPriceChoices"
        :active-radio="form.cards_planned_total_value"
        :errors="errors.cards_planned_total_value"
      />
      <p>
        Jaka jest planowana średnia kwota doładowania jednej przedpłaconej karty
        Edenred? *
      </p>
      <input-radio-group
        name="cards_planned_average_value"
        v-model="form.cards_planned_average_value"
        :items="averagePriceChoices"
        :active-radio="form.cards_planned_average_value"
        :errors="errors.cards_planned_average_value"
      />
      <p>
        W jaki sposób zostanie sfinansowane zamówienie na wydanie lub
        doładowanie przedpłaconych kart Edenred? *
      </p>
      <label class="label-checkbox">
        <input
          v-model="form.financing_source_current_assets"
          name="financing_source_current_assets"
          type="checkbox"
        />
        <span>ze środków obrotowych</span>
      </label>
      <label class="label-checkbox">
        <input
          v-model="form.financing_source_social_fund"
          name="financing_source_social_fund"
          type="checkbox"
        />
        <span>
          ze środków Zakładowego Funduszu Świadczeń Socjalnych (ZFŚS)
        </span>
      </label>
      <p>
        Czy rachunek bankowy, z którego zostanie opłacone pierwsze zamówienie
        Klienta na rzecz Edenred, jest prowadzony w banku mającym siedzibę na
        terenie UE? *
      </p>
      <input-radio-group
        name="first_transfer_from_ue_based_bank"
        v-model="form.first_transfer_from_ue_based_bank"
        :items="transferChoices"
        :active-radio="form.first_transfer_from_ue_based_bank"
        :errors="errors.first_transfer_from_ue_based_bank"
      />
    </div>
    <div class="buttons-container">
      <action-button secondary @click="setPrevFormStep"> Wstecz </action-button>
      <action-button :loading="loading" @click="handleGoToNextStep">
        Dalej
      </action-button>
    </div>
  </div>
</template>

<script>
import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";
import { mapState, mapActions } from "vuex";
import { obtainFetchError } from "@/utils/errors";

export default {
  name: "AMLFormStep5",
  components: {
    InputRadioGroup,
  },
  data() {
    return {
      loading: false,
      errors: {},
      form: {
        campaign_participants: false,
        employees: false,
        card_users_planned_count_campaign_participants: "",
        cards_planned_total_value: "",
        cards_planned_average_value: "",
        card_usage_non_wage_benefit: false,
        card_usage_incentive_and_reward: false,
        first_transfer_from_ue_based_bank: false,
        financing_source_current_assets: false,
        financing_source_social_fund: false,
      },
      plannedCountChoices: [
        {
          label: "1 - 50",
          value: "1-50",
        },
        {
          label: "51 - 100",
          value: "51-100",
        },
        {
          label: "101 - 500",
          value: "101-500",
        },
        {
          label: "501 - 1000",
          value: "501-1000",
        },
        {
          label: "powyżej 1000",
          value: "1001-",
        },
      ],
      summaryPriceChoices: [
        {
          label: "do 20 000 zł",
          value: "0-20k",
        },
        {
          label: "20 001 zł - 50 000 zł",
          value: "20k-50k",
        },
        {
          label: "50 001 zł - 100 000 zł",
          value: "50k-100k",
        },
        {
          label: "100 001 zł - 500 000 zł",
          value: "100k-500k",
        },
        {
          label: "500 001 zł - 1 000 000 zł",
          value: "500k-1m",
        },
        {
          label: "powyżej 1 000 000 zł",
          value: "1m-",
        },
      ],
      averagePriceChoices: [
        {
          label: "do 2 000 zł",
          value: "0-2k",
        },
        {
          label: "2 001 zł - 10 000 zł",
          value: "2k-10k",
        },
        {
          label: "10 001 zł - 50 000 zł",
          value: "10k-50k",
        },
        {
          label: "50 001 zł - 100 000 zł",
          value: "50k-100k",
        },
        {
          label: "powyżej 100 000 zł",
          value: "100k-",
        },
      ],
      transferChoices: [
        {
          label: "Tak",
          value: true,
        },
        {
          label: "Nie",
          value: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("aml", ["declaration"]),
  },
  methods: {
    ...mapActions("aml", [
      "patchDeclaration",
      "setNextFormStep",
      "setPrevFormStep",
    ]),
    async handleGoToNextStep() {
      this.errors = {};
      this.loading = true;
      try {
        const data = { ...this.form };
        if (data.employees === false) {
          data.card_users_planned_count_employees = "0";
        }
        if (data.campaign_participants === false) {
          data.card_users_planned_count_campaign_participants = "0";
        }
        await this.patchDeclaration(data);
        this.setNextFormStep();
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    Object.keys(this.form).forEach(
      (key) => (this.form[key] = this.declaration[key]),
    );
    const checkboxFields = [
      "campaign_participants",
      "employees",
      "card_usage_non_wage_benefit",
      "card_usage_incentive_and_reward",
      "financing_source_current_assets",
      "financing_source_social_fund",
    ];
    checkboxFields.forEach((field) => {
      if (this.form[field] === null || this.form[field] === undefined) {
        this.form[field] = false;
      }
    });

    if (
      this.declaration.card_users_planned_count_employees &&
      this.declaration.card_users_planned_count_employees !== "0"
    ) {
      this.form.employees = true;
      this.form = {
        ...this.form,
        card_users_planned_count_employees:
          this.declaration.card_users_planned_count_employees,
      };
    }

    if (
      this.declaration.card_users_planned_count_campaign_participants &&
      this.declaration.card_users_planned_count_campaign_participants !== "0"
    ) {
      this.form.campaign_participants = true;
      this.form = {
        ...this.form,
        card_users_planned_count_campaign_participants:
          this.declaration.card_users_planned_count_campaign_participants,
      };
    }
    if (this.declaration.first_transfer_from_ue_based_bank === null)
      this.form.first_transfer_from_ue_based_bank = true;
  },
};
</script>

<style lang="scss" scoped>
.step-form__title {
  font-size: 24px;
}

p {
  font-weight: bold;
}

::v-deep(.input-radio-group) {
  margin-bottom: 24px;
}

.label-checkbox {
  margin-bottom: 16px;
}
</style>
