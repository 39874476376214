export default {
  getClientId: (state) => {
    return state.clientDetails.id;
  },
  clientDeliveryAddresses: ({ clientDetails: { delivery_addresses } }) =>
    delivery_addresses,
  getUserDefaultInvoiceEmailsList: ({
    clientDetails: { default_invoice_emails_list },
  }) => default_invoice_emails_list || [],
  getAvailableClients: ({ userDetails: { owned_clients } }) =>
    owned_clients || [],
  getAvailableShareCardsClients: ({ clientDetails: { card_share_choices } }) =>
    card_share_choices || [],
  getAvailableUserContractProducts: ({
    userContract: { commercial_products_set = [] } = {},
  }) => commercial_products_set,
  clientContacts: ({ clientDetails: { contacts = [] } = {} }) => contacts,
};
