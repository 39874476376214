<template>
  <div class="step step--with-stepper">
    <div class="step__content-row-summary">
      <the-agreements
        :order-agreements.sync="orderAgreements"
        :active-order="activeReloadOrder"
        :loading="loadingOrder"
        @fetch-error="(errors) => (errors = errors)"
      />
      <error-item :errors="orderAgreementsErrors" />
      <app-suborders-list />
      <order-cards-list
        :is-modifiable="false"
        :is-address-visible="false"
        :is-reload-order="true"
        :ordered-cards="reloadedCardsOrderCardsList"
        show-total
        :total-top-up="orderedCardsTopUpTotal"
        :total-quantity="orderedCardsTotalQuantity"
      />

      <reload-form-order-summary class="order-summary--blue" />

      <error-item :errors="errors" />

      <error-item :errors="submitErrors" />
    </div>
    <div class="buttons-container">
      <action-button secondary @click="handlePreviousReloadFormStep">
        Wstecz
      </action-button>
      <action-button :loading="loadingOrderSubmit" @click="placeOrder">
        Złóż zamówienie
      </action-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { obtainFetchError } from "@/utils/errors";

import ReloadFormOrderSummary from "@/views/components/OrderSummary/ReloadFormOrderSummary.vue";

import TheAgreements from "@/views/components/formFields/agreements/TheAgreements.vue";
import OrderCardsList from "@/views/components/formFields/OrderCardsList.vue";
import AppSubordersList from "@/views/components/formFields/AppSubordersList.vue";

export default {
  name: "FormReloadStep3",
  components: {
    ReloadFormOrderSummary,
    TheAgreements,
    OrderCardsList,
    AppSubordersList,
  },
  data() {
    return {
      loadingOrderSubmit: false,
      orderAgreements: {},
      submitErrors: {},
      orderAgreementsErrors: {},
      errors: {},
      loadingOrder: true,
    };
  },
  computed: {
    ...mapState("reloads", ["activeReloadOrder"]),
    reloadedCards() {
      return this.activeReloadOrder?.reloaded_cards || [];
    },
    reloadedCardsOrderCardsList() {
      return this.reloadedCards.map((card) => ({
        ...card,
        has_label: false,
        quantity: 1,
      }));
    },
    orderedCardsTopUpTotal() {
      return this.activeReloadOrder?.order?.billing?.top_up_value;
    },
    orderedCardsTotalQuantity() {
      return this.activeReloadOrder?.order?.billing?.cards_quantity;
    },
  },
  async beforeMount() {
    const { loadActiveReloadOrder } = this;
    await loadActiveReloadOrder();
    this.loadingOrder = false;
    this.updateReloadBillingData({ isBillingFinal: true });
  },
  methods: {
    ...mapActions("orders", ["patchOrder"]),
    ...mapActions("reloads", [
      "updateReloadBillingData",
      "handlePreviousReloadFormStep",
      "loadActiveReloadOrder",
      "submitActiveReloadOrder",
    ]),
    async handlePatchOrderAgreements() {
      try {
        await this.patchOrder({
          orderId: this.activeReloadOrder.order.id,
          cardReloadOrderId: this.activeReloadOrder.id,
          data: this.orderAgreements,
        });
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.orderAgreementsErrors = errors;
        throw errorObj;
      }
    },
    async handleSubmitActiveReloadOrder() {
      try {
        await this.submitActiveReloadOrder();
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.submitErrors = errors;
        throw errorObj;
      }
    },
    async placeOrder() {
      this.loadingOrderSubmit = true;

      try {
        await this.handlePatchOrderAgreements();

        await this.handleSubmitActiveReloadOrder();

        this.$router.replace({ name: "reloadOrderSuccessView" });
        window.scrollTo(0, 0);
      } catch (errorObj) {
        console.error({ msg: "error placing reload order", errorObj });
      } finally {
        this.loadingOrderSubmit = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_breakpoints.scss";

.step__content-column {
  width: 48%;
  @include breakpoint-mobile {
    width: 100%;
  }
}

.company-size {
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>
