const getDefaultState = () => ({
  mfaAuthenticated: true,
  mfaDeviceId: null,
  mfaDevices: [],
});

const state = getDefaultState();

export default state;

export { getDefaultState };
