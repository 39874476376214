import { getDefaultState } from "./state";

export default {
  resetReloadsState(state) {
    Object.assign(state, getDefaultState());
  },
  resetActiveReloadData(state) {
    state.activeReloadForm = {};
    state.activeReloadFormStep = 0;
    state.activeReloadFormInvoiceOptions = {
      showRecipient: false,
    };
    state.activeReloadFormBilling.billingArray = [];
    state.activeReloadFormBilling.isBillingFinal = null;
  },
  setActiveReloadFormBilling(state, { billingArray, isBillingFinal }) {
    state.activeReloadFormBilling = {
      billingArray,
      isBillingFinal,
    };
  },
  setActiveReloadFormBillingReloadError(state, payload) {
    state.activeReloadFormBillingReloadError = payload;
  },
  setActiveReloadFormBillingLoading(state, payload) {
    state.activeReloadFormBillingLoading = payload;
  },
  updateActiveReloadFormInvoiceOptions(state, payload) {
    state.activeReloadFormInvoiceOptions = {
      ...state.activeReloadFormInvoiceOptions,
      ...payload,
    };
  },
  setActiveReloadFormData(state, form) {
    state.activeReloadForm = form;
  },
  setActiveReloadOrderId(state, id) {
    state.activeReloadOrderId = id;
  },
  /** Method that is used to navigation between steps in reload form  */
  goToNextReloadFormStep(state) {
    state.activeReloadFormStep++;
  },
  goToPreviousReloadFormStep(state) {
    state.activeReloadFormStep--;
  },
  setReloadFormStep(state, step) {
    state.activeReloadFormStep = step;
  },
  setActiveReloadOrder(state, activeReloadOrder) {
    state.activeReloadOrder = activeReloadOrder;
  },
  setReloadNotAuthorizedError(state, error) {
    state.reloadNotAuthorizedError = error;
  },
};
