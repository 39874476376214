import { store } from "@/store";

const TOKEN_STORAGE_KEY = "authToken";

export function getAuthToken() {
  return (
    localStorage.getItem(TOKEN_STORAGE_KEY) ||
    sessionStorage.getItem(TOKEN_STORAGE_KEY)
  );
}

export function hasAuthToken() {
  return getAuthToken() !== null;
}

export function clearAuthToken() {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  sessionStorage.removeItem(TOKEN_STORAGE_KEY);
  store.commit("user/setIsAuthenticated", false);
}

export function storeAuthToken(token, permanent = false) {
  if (permanent) {
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
  } else {
    sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
  }
  store.commit("user/setIsAuthenticated", true);
}
