import { render, staticRenderFns } from "./AppExpansionPanel.vue?vue&type=template&id=7027c45f&scoped=true&"
import script from "./AppExpansionPanel.vue?vue&type=script&lang=js&"
export * from "./AppExpansionPanel.vue?vue&type=script&lang=js&"
import style0 from "./AppExpansionPanel.vue?vue&type=style&index=0&id=7027c45f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7027c45f",
  null
  
)

export default component.exports