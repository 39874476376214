class FetchError extends Error {
  constructor(errors, status, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = "FetchError";
    this.errors = errors;
    this.status = status;
    // Custom debugging information
    this.date = new Date();
  }
}

// checks for FetchError and returns it
// otherwise rethrows
function obtainFetchError(errorObj) {
  const { name } = errorObj;
  if (name === "FetchError") {
    return errorObj;
  } else {
    // handle other errors
    throw errorObj;
  }
}

class FormValidationError extends Error {
  constructor(errors, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FormValidationError);
    }

    this.name = "FormValidationError";
    this.errors = errors;
    // Custom debugging information
    this.date = new Date();
  }
}

class DynamicListValidationError extends Error {
  constructor(errors, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DynamicListValidationError);
    }

    this.name = "DynamicListValidationError";
    this.errors = errors;
    // Custom debugging information
    this.date = new Date();
  }
}

class AppInitializationError extends Error {
  constructor(errors, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AppInitializationError);
    }

    this.name = "AppInitializationError";
    this.errors = errors;
    this.date = new Date();
  }
}

class UnauthorisedTokenError extends Error {
  constructor(errors, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnauthorisedTokenError);
    }

    this.name = "UnauthorisedTokenError";
    this.errors = errors;
    this.date = new Date();
  }
}

class MfaRequiredError extends Error {
  constructor(errors, status, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MfaRequiredError);
    }

    this.name = "MfaRequiredError";
    this.errors = errors;
    this.status = status;
    this.date = new Date();
  }
}

export {
  obtainFetchError,
  FetchError,
  FormValidationError,
  DynamicListValidationError,
  AppInitializationError,
  UnauthorisedTokenError,
  MfaRequiredError,
};
