<template>
  <div class="step step--with-stepper">
    <card-definition
      ref="cardDefinitionRef"
      @card-added="hideErrorMessage"
      @card-removed="hideErrorMessage"
      @is-dirty="($event) => (areCardsDirty = $event)"
    />

    <div v-if="showCardsErrorMessage" class="form-error">
      Zamówienie musi posiadać przynajmniej jedno zamówienie kart.
    </div>
    <div class="buttons-container">
      <action-button secondary @click="handlePreviousFormStep">
        Wstecz
      </action-button>
      <action-button :loading="cardsLoading" @click="goToNextStep">
        Dalej
      </action-button>
    </div>
  </div>
</template>

<script>
import CardDefinition from "@/views/components/formFields/CardDefinition.vue";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "FormStep3",
  components: { CardDefinition },
  data() {
    return {
      showCardsErrorMessage: false,
      cardsLoading: false,
      areCardsDirty: false,
    };
  },
  computed: {
    ...mapState("orders", ["activeForm"]),
  },
  methods: {
    ...mapMutations("orders", ["goToNextFormStep", "goToPreviousFormStep"]),
    ...mapActions("orders", ["handleNextFormStep", "handlePreviousFormStep"]),
    hideErrorMessage() {
      this.showCardsErrorMessage = false;
    },
    async goToNextStep() {
      const { areCardsDirty } = this;
      this.cardsLoading = true;

      if (areCardsDirty) {
        const errors = await this.$refs.cardDefinitionRef.addCard();
        this.cardsLoading = false;

        if (errors) {
          return;
        }
      }

      this.cardsLoading = false;
      if (this.activeForm["ordered_cards"].length < 1) {
        this.showCardsErrorMessage = true;
        return;
      }

      this.handleNextFormStep();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-error {
  text-align: center;
  margin-top: 1rem;
}
</style>
