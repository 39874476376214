<template>
  <div class="step step--with-stepper">
    <info-box class="infobox">
      <p class="text-bold">Kim jest beneficjent rzeczywisty?</p>
      <p>
        Beneficjentem rzeczywistym Klienta
        <span class="text-underline">nie jest użytkownik karty</span>, któremu
        Klient przekazuje zamówioną przedpłaconą kartę płatniczą Edenred.
        Pojęcie „beneficjenta rzeczywistego” jest zdefiniowane w przepisach
        prawa o przeciwdziałaniu praniu pieniędzy i finansowaniu terroryzmu.
      </p>
      <p>
        Beneficjentem rzeczywistym Klienta jest zawsze
        <span class="text-underline">osoba fizyczna (człowiek)</span>
        sprawująca bezpośrednio lub pośrednio kontrolę nad Klientem poprzez
        posiadane uprawnienia, które wynikają z okoliczności prawnych lub
        faktycznych, umożliwiające
        <span class="text-underline"
          >wywieranie decydującego wpływu na czynności lub działania podejmowane
          przez Klienta</span
        >, np.:
      </p>
      <ul>
        <li>
          osoba fizyczna posiadająca bezpośrednio pow. 25% udziałów w spółce z
          o.o.,
        </li>
        <li>
          osoba fizyczna, która jako udziałowiec spółki matki innej spółki z
          o.o., posiada pośrednio pow. 25% głosów w tej spółce z o.o.,
        </li>
        <li>
          osoba fizyczna będąca wspólnikiem spółki jawnej uprawnionym do jej
          reprezentacji,
        </li>
        <li>
          członek zarządu spółki akcyjnej, w przypadku udokumentowanego braku
          możliwości ustalenia lub wątpliwości co do tożsamości osób fizycznych
          zgodnie z zasadami określonymi w przepisach prawa,
        </li>
        <li>
          w przypadku osoby fizycznej prowadzącej działalność gospodarczą – taka
          sama osoba jest jednocześnie beneficjentem rzeczywistym.
        </li>
      </ul>
      <p>
        Podmioty takie jak spółki prawa handlowego (w tym spółki z o.o., spółki
        akcyjne), stowarzyszenia wpisane do KRS, fundacje, spółdzielnie i inne
        są obowiązane przepisami prawa do ujawnienia swoich beneficjentów
        rzeczywistych w Centralnym Rejestrze Beneficjentów Rzeczywistych (CRBR)
        dostępnym tutaj:
        <a class="link" href="https://www.podatki.gov.pl/crbr/" target="_blank"
          >https://www.podatki.gov.pl/crbr/</a
        >.
      </p>
    </info-box>
    <AppRepeater
      ref="repeaterRef"
      :template="beneficialOwnerTemplate"
      :component="repeaterComponent"
      :delete-handler="deleteBeneficialOwner"
      :patch-handler="patchBeneficialOwner"
      :post-handler="postBeneficialOwner"
      :load-handler="loadBeneficialOwners"
      :items="beneficialOwners"
      :loading.sync="loading"
    />
    <p class="step__footer">** - pole wymagane do przesłania formularza</p>
    <div class="buttons-container buttons-container--main">
      <action-button secondary @click="setPrevFormStep"> Wstecz </action-button>
      <action-button :loading="loading" @click="handleNextStep">
        Dalej
      </action-button>
    </div>
  </div>
</template>

<script>
import BeneficialOwner from "@/views/components/aml/BeneficialOwner.vue";
import AppRepeater from "@/views/components/formFields/AppRepeater.vue";
import { mapActions, mapState } from "vuex";
import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";

export default {
  name: "AMLFormStep4",
  components: {
    AppRepeater,
    InfoBox,
  },
  data() {
    return {
      loading: false,
      repeaterComponent: BeneficialOwner,
      beneficialOwnerTemplate: {
        name: "",
        country_of_residence: "",
        address_of_residence: "",
        birth_date: null,
        birth_place: "",
        citizenship: "",
        pesel: "",
        identity_document_id: "",
        grounds_description: "",
        funds_source_business_activity: false,
        funds_source_insurance_benefits: false,
        funds_source_own_assets: false,
        funds_source_sold_assets: false,
        funds_source_transferred_assets: false,
        is_pep: false,
        is_pep_family: false,
        is_pep_associate: false,
      },
    };
  },
  computed: {
    ...mapState("aml", ["beneficialOwners"]),
  },
  methods: {
    ...mapActions("aml", [
      "setNextFormStep",
      "setPrevFormStep",
      "postBeneficialOwner",
      "deleteBeneficialOwner",
      "patchBeneficialOwner",
      "loadBeneficialOwners",
    ]),
    async handleNextStep() {
      try {
        await this.$refs.repeaterRef.submit();
        this.setNextFormStep();
      } catch (errorObj) {
        console.error(errorObj);
      }
    },
  },
};
</script>

<style scoped>
.label-checkbox {
  margin-bottom: 32px;
}

.buttons-container--main {
  margin-top: 32px;
}

.infobox .link {
  font-size: 0.866666667rem;
}
</style>
