import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize-css";
import "./scss/main.scss";
import "./js/globalComponents";
import "./js/pluginsComponents";
import "./js/plugins";
import "./js/filters";

import { AppInitializationError } from "@/utils/errors";

Vue.config.productionTip = process.env.NODE_END === "production";

const initializeApp = async (app) => {
  await store.dispatch("initialize");
  // create new user and client
  // for user who was filling out nof and then abandoned it without creating account
  if (store.state.user.clientDetails.is_temporary) {
    await store.dispatch("logout");
    await store.dispatch("initialize");
  }
  app.$mount("#app");
};

new Vue({
  router,
  store,
  render: (h) => h(App),
  async beforeCreate() {
    try {
      store.dispatch("viewport/subscribeViewport");
      try {
        await initializeApp(this);
      } catch (error) {
        if (error.name === "UnauthorisedTokenError") {
          await initializeApp(this);
          return;
        }
        if (error.name === "MfaRequiredError") {
          this.$mount("#app");
          return;
        }

        throw error;
      }
    } catch (error) {
      if (error.name === "NavigationDuplicated") {
        console.warn("NavigationDuplicated error caught", error);
        return;
      }

      this.$mount("#initialization-error");
      throw new AppInitializationError(error);
    }
  },
});
