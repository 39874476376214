<template>
  <div class="step step--with-stepper step-5">
    <div class="step__content-row-summary">
      <h2 class="step-form__title">Uwagi do zamówienia</h2>
      <!-- TODO: Add a subtitle here when we have copy -->

      <input-textarea
        v-model="comment"
        class="input input--comments"
        :errors="activeOrderPatchErrors.comment"
        rows="1"
        :max-length="5000"
      >
      </input-textarea>

      <company-size v-model="company_size" class="company-size" />
      <error-item :errors="activeOrderPatchErrors.company_size" />

      <the-agreements
        :order-agreements.sync="orderAgreements"
        :active-order="activeOrder"
        :loading="loadingOrder"
        @fetch-error="(errors) => (errors = errors)"
      />
      <error-item :errors="orderAgreementsErrors" />

      <addresses-list title="Adresy dostaw" :is-modifiable="false" />

      <order-cards-list
        :is-modifiable="false"
        :is-address-visible="false"
        show-total
        :total-top-up="orderedCardsTopUpTotal"
        :total-quantity="orderedCardsTotalQuantity"
      />

      <order-summary
        :billing-array="billingArray"
        :is-billing-final="true"
        :loading="!activeOrder || !activeOrder.billing"
      />

      <error-item :errors="errors" />

      <error-item :errors="submitErrors" />
    </div>
    <div class="buttons-container">
      <action-button secondary @click="handlePreviousFormStep">
        Wstecz
      </action-button>
      <action-button :loading="loadingOrderSubmit" @click="placeOrder">
        Złóż zamówienie
      </action-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { obtainFetchError } from "@/utils/errors";

import CompanySize from "@/views/components/formFields/CompanySize.vue";
import TheAgreements from "@/views/components/formFields/agreements/TheAgreements.vue";
import InputTextarea from "@/views/components/formFields/InputTextarea.vue";
import OrderCardsList from "@/views/components/formFields/OrderCardsList.vue";
import AddressesList from "@/views/components/formFields/AddressesList.vue";
import OrderSummary from "@/views/components/OrderSummary/OrderSummary.vue";

export default {
  name: "FormStep5",
  components: {
    CompanySize,
    TheAgreements,
    InputTextarea,
    OrderCardsList,
    AddressesList,
    OrderSummary,
  },
  data() {
    return {
      comment: "",
      loadingOrderSubmit: false,
      company_size: null,
      activeOrderPatchErrors: {},
      orderAgreements: {},
      submitErrors: {},
      orderAgreementsErrors: {},
      errors: {},
      loadingOrder: true,
    };
  },
  computed: {
    ...mapState("orders", ["activeOrder"]),
    orderedCardsTopUpTotal() {
      return this.activeOrder?.billing?.top_up_value;
    },
    orderedCardsTotalQuantity() {
      return this.activeOrder?.billing?.cards_quantity;
    },
    billingArray() {
      return this.activeOrder?.billing?.formatted_billing_details || [];
    },
  },
  async beforeMount() {
    const { loadActiveOrder, updateBillingData } = this;
    await loadActiveOrder();
    this.comment = this.activeOrder.comment;
    this.loadingOrder = false;
    updateBillingData({ isBillingFinal: true });
  },
  methods: {
    ...mapActions("orders", [
      "updateBillingData",
      "handlePreviousFormStep",
      "patchActiveOrder",
      "submitActiveCardOrder",
      "loadActiveOrder",
    ]),
    ...mapActions("user", ["loadClientDetails"]),
    async handlePatchActiveOrder() {
      const { patchActiveOrder, company_size, comment } = this;
      try {
        await patchActiveOrder({ company_size, comment });
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.activeOrderPatchErrors = errors;
        throw errorObj;
      }
    },
    async handlePatchOrderAgreements() {
      const { patchActiveOrder, orderAgreements } = this;
      await patchActiveOrder(orderAgreements);

      try {
        await this.patchActiveOrder(this.orderAgreements);
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.orderAgreementsErrors = errors;
        throw errorObj;
      }
    },
    async handleSubmitActiveCardOrder() {
      try {
        await this.submitActiveCardOrder();
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.submitErrors = errors;
        throw errorObj;
      }
    },
    async placeOrder() {
      this.loadingOrderSubmit = true;

      try {
        await this.handlePatchActiveOrder();
        await this.handlePatchOrderAgreements();

        await this.handleSubmitActiveCardOrder();

        this.$router.replace({ name: "orderSuccessView" });
        this.loadClientDetails();
        window.scrollTo(0, 0);
      } catch (errorObj) {
        console.error({ msg: "error placing order", errorObj });
      } finally {
        this.loadingOrderSubmit = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_breakpoints.scss";

.step__content-column {
  width: 48%;
  @include breakpoint-mobile {
    width: 100%;
  }
}

.company-size {
  margin-top: 50px;
  margin-bottom: 50px;
}

.input--comments {
  margin-bottom: 12px;
}
</style>
