import Vue from "vue";
import Router from "vue-router";
import { hasAuthToken } from "@/utils/authToken";
import routes, { NON_AUTH_ROUTES } from "./routes";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (NON_AUTH_ROUTES.includes(to.name) || hasAuthToken()) {
    next();
  } else {
    next({ name: "login", params: { returnTo: to }, replace: true });
  }
});

export default router;
