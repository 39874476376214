import { mapGetters, mapState, mapMutations } from "vuex";
import { fetchFromApi } from "@/utils/api";
import { cardOrderAPIPoint } from "@/apiEndpoints";
import { setByPath } from "@/utils/utilityFunctions";
import { obtainFetchError } from "@/utils/errors";
import _cloneDeep from "lodash.clonedeep";

export default {
  data() {
    return {
      updatingOrder: false,
      errors: {},
    };
  },
  computed: {
    ...mapState("orders", ["activeForm"]),
    ...mapGetters("orders", ["getActiveCardOrderId"]),
  },
  methods: {
    ...mapMutations("orders", ["setActiveForm"]),
    updateLocalStore(data) {
      const activeFormClone = _cloneDeep(this.activeForm);
      for (const [property, value] of Object.entries(data)) {
        setByPath(activeFormClone, property, value);
      }
      this.setActiveForm(activeFormClone);
    },
    async updateOrder(data) {
      this.updatingOrder = true;

      // send to backend
      try {
        await fetchFromApi(
          `${cardOrderAPIPoint}${this.getActiveCardOrderId}/`,
          {
            method: "PATCH",
            data,
          },
        );

        // errors
        this.errors = {};
        this.updateLocalStore(data);
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      } finally {
        this.updatingOrder = false;
      }
    },
  },
};
