<template>
  <paginate
    v-if="pageCount > 1"
    ref="paginateRef"
    :key="viewReset"
    :page-count="pageCount"
    :force-page="currentPage"
    :click-handler="($event) => $emit('click', $event)"
    :page-link-class="'page-link'"
    :prev-text="''"
    :prev-link-class="'prev-link'"
    :next-text="''"
    :next-link-class="'next-link'"
    :container-class="'paginate-pagination'"
    :break-view-class="'break-view'"
    :no-li-surround="true"
    :hide-prev-next="true"
  />
</template>

<script>
import paginate from "vuejs-paginate";

export default {
  name: "AppPagination",
  components: { paginate },
  props: {
    pageCount: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      viewReset: false,
    };
  },
  watch: {
    currentPage() {
      const {
        $refs: { paginateRef },
        currentPage,
      } = this;
      // fixes issue that sometimes force-page doesn't work
      if (paginateRef.innerValue !== currentPage) {
        this.viewReset = !this.viewReset;
      }
    },
  },
};
</script>

<style lang="scss">
.paginate-pagination {
  display: flex;
  justify-content: center;
  max-width: 60%;
  margin: 0 auto;
  padding-left: 0;

  .page-link {
    background-color: $disabled-background-color;
    color: $text-color;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-right: 5px;
    &.active {
      background-color: $color-primary;
      color: white;
    }
  }

  .prev-link {
    padding-right: 10px;
    content: url("~@/images/arrow_left.svg");
  }

  .next-link {
    padding-left: 5px;
    content: url("~@/images/arrow_right.svg");
  }
}
</style>
