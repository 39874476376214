import NofMultistepFormStep1 from "@/views/nof/NofMultistepFormStep1.vue";
import NofMultistepFormStep2 from "@/views/nof/NofMultistepFormStep2.vue";
import NofMultistepFormStep3 from "@/views/nof/NofMultistepFormStep3.vue";

const formSteps = [
  {
    description: "Konfiguracja kart",
    component: NofMultistepFormStep1,
  },
  {
    description: "Dane",
    component: NofMultistepFormStep2,
  },
  {
    description: "Podsumowanie",
    component: NofMultistepFormStep3,
  },
];

export default formSteps;
