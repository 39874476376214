const getDefaultState = () => ({
  authenticated: null,
  userContract: {},
  defaultClientContracts: [],
  defaultContracts: [],
  clientDetails: {},
  userDetails: {},
  selectedClient: undefined,
  cookiesConsent: false,
});

const state = getDefaultState();

export default state;

export { getDefaultState };
