<template>
  <ordering-person-form
    ref="orderingPersonRef"
    :query-params="`card_reload_order=${activeReloadOrderId}`"
    :form-data-object="getActiveReloadFormOrderingPerson"
    @form-success="handleFormSuccess"
    @request-update="reloadActiveReloadFormData"
    @is-dirty="$emit('is-dirty')"
  />
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import OrderingPersonForm from "@/views/components/formFields/ordering_person/OrderingPersonForm.vue";

export default {
  name: "ReloadOrderOrderingPerson",
  components: { OrderingPersonForm },
  computed: {
    ...mapState("reloads", ["activeReloadOrderId"]),
    ...mapGetters("reloads", ["getActiveReloadFormOrderingPerson"]),
  },
  methods: {
    ...mapActions("reloads", ["reloadActiveReloadFormData"]),
    handleFormSuccess(event) {
      this.$emit("form-success", event);
    },
    // used by DynamicList.vue component
    async beforeComponentChange() {
      const {
        $refs: {
          orderingPersonRef: { beforeComponentChange },
        },
      } = this;
      if (beforeComponentChange) {
        await beforeComponentChange();
      }
    },
  },
};
</script>
