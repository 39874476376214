<template>
  <div class="step step--with-stepper">
    <TextInput v-model="form.full_name" required :errors="errors.full_name">
      Pełna nazwa
    </TextInput>
    <TextInput
      v-model="form.organisational_form"
      required
      :errors="errors.organisational_form"
    >
      Forma organizacyjna
    </TextInput>
    <TextInput
      v-model="form.registered_seat_address"
      required
      :errors="errors.registered_seat_address"
    >
      Adres siedziby
    </TextInput>
    <TextInput v-model="form.vat_id" required :errors="errors.vat_id">
      NIP
    </TextInput>
    <TextInput v-model="form.krs_id" :errors="errors.krs_id">KRS</TextInput>
    <InputDatePicker
      v-model="form.date_of_establishment"
      required
      :errors="errors.date_of_establishment"
    >
      Data założenia / powołania
    </InputDatePicker>
    <TextInput
      v-model="form.main_sector_of_activity"
      required
      :errors="errors.main_sector_of_activity"
    >
      Główny sektor działalności
    </TextInput>

    <p class="lei-title">Kod LEI:</p>
    <p class="lei-description">
      (Legal Entity Identifier) to identyfikator podmiotu zgodny z normą
      ISO17442, nadawany przez agencje kodujące akredytowane przez Global Legal
      Entity Identifier Foundation (GLEIF), unikalnie identyfikujący podmioty w
      skali globalnej. W przypadku braku zaznacz „nie dotyczy”.
    </p>
    <label class="label-checkbox">
      <input v-model="hideLei" type="checkbox" />
      <span>Nie dotyczy</span>
    </label>
    <TextInput v-model="form.lei" :errors="errors.lei" required v-if="!hideLei">
      Legal Entity Identifier
    </TextInput>
    <div class="buttons-container--center">
      <action-button :loading="loading" @click="handleGoToNextStep">
        Dalej
      </action-button>
    </div>
  </div>
</template>

<script>
import TextInput from "@/views/components/formFields/TextInput.vue";
import InputDatePicker from "@/views/components/formFields/InputDatePicker.vue";
import { mapState, mapActions } from "vuex";
import { obtainFetchError } from "@/utils/errors";

export default {
  name: "AmlFormStep1",
  components: {
    TextInput,
    InputDatePicker,
  },
  data() {
    return {
      loading: false,
      errors: {},
      hideLei: true,
      form: {
        full_name: "",
        organisational_form: "",
        registered_seat_address: "",
        vat_id: "",
        date_of_establishment: null,
        main_sector_of_activity: "",
        krs_id: "",
        lei: "",
      },
    };
  },
  computed: {
    ...mapState("aml", ["declaration"]),
  },
  methods: {
    ...mapActions("aml", ["patchDeclaration", "setNextFormStep"]),
    async handleGoToNextStep() {
      this.errors = {};
      if (!this.hideLei && !this.form.lei) {
        this.errors.lei = ["To pole jest wymagane"];
        return;
      }

      this.loading = true;
      try {
        await this.patchDeclaration(this.form);
        this.setNextFormStep();
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    Object.keys(this.form).forEach(
      (key) => (this.form[key] = this.declaration[key]),
    );
    if (this.form.lei) {
      this.hideLei = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.step-form__title {
  font-size: 24px;
}

::v-deep(.input-radio-group) {
  margin-bottom: 24px;
}

.label-checkbox {
  margin-bottom: 16px;
}

.lei-title {
  margin-bottom: 8px;
}

.lei-description {
  margin-top: 0;
  font-size: 13px;
  max-width: 800px;
}
</style>
