<template>
  <div class="loader-wrap">
    <LoadingIndicator :loading="true" />
  </div>
</template>

<script>
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

export default {
  components: {
    LoadingIndicator,
  },
};
</script>

<style lang="scss" scoped>
.loader-wrap {
  padding: 24px 0;
}
</style>
