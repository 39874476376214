import { getAuthToken, hasAuthToken } from "@/utils/authToken";
import { apiPrefix, mainAPIPoint } from "@/apiEndpoints";
import {
  FetchError,
  UnauthorisedTokenError,
  MfaRequiredError,
  obtainFetchError,
} from "@/utils/errors";

import router from "@/router";
import store from "@/store";

const GENERAL_CONNECTION_ERROR =
  "Wystąpił problem w czasie zapisywania danych na serwerze";

/**
 * Works the same as the built-in `fetch` function, but it manages the authentication
 * token automatically, prefixes the endpoint path with the API root path,
 * and supports an additional `data` option (the data is automatically
 * json-encoded and send as the body).
 */
export async function fetchFromApi(path, init = {}) {
  // copy to avoid side-effects on the passed object
  const options = { json: true, useVersionPrefix: true, ...init };

  if (!options["headers"]) {
    options["headers"] = {};
  }

  // Add the auth token
  if (hasAuthToken()) {
    options["headers"]["Authorization"] = `Token ${getAuthToken()}`;
  }

  const {
    state: {
      user: { selectedClient: { id: selectedClientId } = {} },
    },
  } = store;
  if (selectedClientId) {
    options["headers"]["Edenred-Client"] = selectedClientId;
  }

  // Handle the data
  if (options["data"]) {
    console.assert(!options["body"] || !options["data"]);
    if (!options.formData) {
      options["headers"]["Content-Type"] = "application/json";
      options["body"] = JSON.stringify(options["data"]);
    } else {
      options["body"] = options["data"];
    }
  }

  // Add API path prefix
  const prefix = options.useVersionPrefix ? mainAPIPoint : apiPrefix;

  const fullPath = prefix + path;

  const response = await fetch(fullPath, options);

  if (response.ok) {
    if (options.json) {
      const bodyText = await response.text();
      if (bodyText) {
        return JSON.parse(bodyText);
      }
      return null;
    }
    return response;
  }

  if (response.status === 401 && hasAuthToken()) {
    // Unauthorised - token no longer valid
    await store.dispatch("logout");
    router.replace({ name: "login" });
    throw new UnauthorisedTokenError();
  }

  if (response.status === 403) {
    const responseData = await response.json();
    if (
      responseData.code === "mfa_required" &&
      store.state.mfa.mfaAuthenticated
    ) {
      await store.commit("mfa/setMfaAuthenticated", false);
      try {
        await store.dispatch("mfa/requestMfaDefaultChallenge");
      } catch (error) {
        const { errors, status } = obtainFetchError(error);
        if (status === 404) {
          await router.replace({
            name: "mfaEnable",
            params: {
              returnTo: router.currentRoute.fullPath,
            },
          });
          throw new MfaRequiredError(errors, status);
        }
      }

      await router.replace({
        name: "mfa",
        params: {
          returnTo: router.currentRoute.fullPath,
        },
      });
      throw new MfaRequiredError(responseData, response.status);
    }
  }

  if (response.status === 400) {
    const errors = await response.json();
    const error = new FetchError(errors, response.status);
    console.error(error);
    throw error;
  } else {
    const errors = {
      non_field_errors: [GENERAL_CONNECTION_ERROR],
    };
    const error = new FetchError(errors, response.status);
    console.error(error);
    throw error;
  }
}
