<template>
  <div :class="['form-group', { disabled }]">
    <label v-if="$slots.default" class="label"
      ><slot></slot> {{ required ? "*" : "" }}</label
    >
    <div class="input-wrap">
      <textarea
        :class="['input', { 'input--error': hasErrors }, 'input-autocomplete']"
        v-bind="$attrs"
        :value="value"
        :disabled="disabled"
        :maxlength="maxLength"
        v-on="inputListeners"
      >
      </textarea>
      <span v-if="maxLength" class="character-counter">
        {{ value.length }}/{{ maxLength }}
      </span>
    </div>
    <error-item v-if="errors" :errors="errors" />
  </div>
</template>

<script>
export default {
  name: "InputTextarea",
  inheritAttrs: false,
  props: {
    errors: {
      type: [Array, Object, String],
      default() {
        return {};
      },
    },
    value: {
      type: String,
      default() {
        return "";
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasErrors() {
      if (Array.isArray(this.errors)) {
        return this.errors.length > 0;
      } else if (typeof this.errors === "object") {
        return Object.keys(this.errors).length > 0;
      } else if (typeof this.errors === "string" && this.errors) {
        return true;
      } else {
        return false;
      }
    },
    inputListeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit("input", event.target.value),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.character-counter {
  font-size: $small-font-size;
  margin: 8px 0;
}
</style>
