<template>
  <div>
    <text-input v-model="form.name" :errors="errors.name" required
      >Imię i nazwisko</text-input
    >
    <text-input v-model="form.position" :errors="errors.position" required
      >Stanowisko/funkcja</text-input
    >
  </div>
</template>

<script>
import TextInput from "@/views/components/formFields/TextInput.vue";

export default {
  name: "AmlSubmitter",
  components: {
    TextInput,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    errors: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        localId: "",
        id: "",
        name: "",
        position: "",
      },
    };
  },
  watch: {
    form: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  created() {
    Object.keys(this.form).forEach((key) => (this.form[key] = this.value[key]));
  },
};
</script>
<style scoped>
.label-checkbox {
  margin-bottom: 16px;
}
</style>
