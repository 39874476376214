import { fetchFromApi } from "@/utils/api";
import { userAPIPoint } from "@/apiEndpoints";

export default {
  async handleNextFormStep({ commit, state: { activeFormStep, formSteps } }) {
    if (activeFormStep + 1 < formSteps.length) {
      commit("goToNextFormStep");
      window.scrollTo(0, 0);
    }
  },
  async handlePreviousFormStep({ commit, state: { activeFormStep } }) {
    if (activeFormStep > 0) {
      commit("goToPreviousFormStep");
      window.scrollTo(0, 0);
    }
  },
  async handleCreateAnonymousUser(context, recaptchaResponse) {
    return await fetchFromApi(`${userAPIPoint}/create_anonymous_access/`, {
      method: "POST",
      data: {
        recaptcha: recaptchaResponse,
      },
    });
  },
};
