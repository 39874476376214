<template>
  <div class="card-definition-component">
    <info-box>
      Tutaj decydujesz o liczbie zamawianych kart, ich wartości i
      {{
        isVirtualProductActive
          ? `adresach email na które mają być wysłane karty`
          : `adresie dostawy lub dostaw`
      }}.
    </info-box>

    <div class="step__content-row">
      <div class="step__content-column list">
        <div
          class="list__item"
          @click="
            isVirtualProductActive
              ? (selectedCardType = CARD_TYPES.virtual)
              : (selectedCardType = CARD_TYPES.withoutInscription)
          "
        >
          <div class="icon">
            <img src="~@/images/icon-card.svg" alt="" />
          </div>
          Zamów {{ !!getOrderedCards.length ? `kolejne` : `` }} karty
          {{
            canAddCardsWithLabel && !isVirtualProductActive ? `bez etykiet` : ``
          }}
        </div>
        <div
          v-if="canAddCardsWithLabel && !isVirtualProductActive"
          class="list__item"
          @click="selectedCardType = CARD_TYPES.withInscription"
        >
          <div class="icon">
            <img src="~@/images/icon-card-user.svg" alt="" />
          </div>
          Zamów {{ !!getOrderedCards.length ? `kolejną` : `` }} kartę z etykietą
        </div>
      </div>
      <div class="step__content-column list-content">
        <info-box v-if="selectedCardType === CARD_TYPES.withInscription">
          Na etykiecie możesz zamieścić przykładowo takie informacje jak: imię,
          nazwisko, numer pracowniczy, dział, nazwę firmy lub inną dowolną
          wartość.
        </info-box>

        <div
          v-if="
            selectedCardType !== CARD_TYPES.notSelected &&
            !isVirtualProductActive
          "
          class="form-group form-group-select"
        >
          <span class="label">Wybierz adres dostawy</span>
          <select v-model="currentCardDetail.delivery_address" class="select">
            <option
              v-for="(address, i) in getCurrentAddresses"
              :key="`address-${i}`"
              :value="address.id"
            >
              {{ address.name }}
            </option>
          </select>
          <error-item
            :errors="
              errors.delivery_address || cardsWithoutInscriptionAddressError
            "
          />
        </div>
        <div v-if="selectedCardType === CARD_TYPES.withInscription">
          <div class="form-group-double">
            <text-input
              v-model="currentCardDetail.quantity"
              :disabled="selectedCardType === CARD_TYPES.withInscription"
              :errors="errors.quantity"
              :input-type="`number`"
            >
              Liczba kart
            </text-input>
            <text-input
              v-model="currentCardDetail.amount"
              :errors="errors.amount"
              :input-type="`number`"
            >
              Wartość doładowania
            </text-input>
          </div>
        </div>
        <div v-if="selectedCardType === CARD_TYPES.withoutInscription">
          <div
            v-for="(card, cardIndex) in currentCardDetail.cards"
            :key="`card-row-${cardIndex}`"
            class="form-group-double card-row"
          >
            <text-input
              class="card-row__input"
              :value="currentCardDetail.cards[cardIndex].quantity"
              :disabled="selectedCardType === CARD_TYPES.withInscription"
              :errors="getCardWithoutInscriptionErrors(cardIndex, 'quantity')"
              :input-type="`number`"
              @input="
                (value) =>
                  handleCardInput({
                    key: 'quantity',
                    value,
                    cardIndex,
                  })
              "
            >
              Liczba kart
            </text-input>

            <text-input
              :value="currentCardDetail.cards[cardIndex].amount"
              class="card-row__input"
              :errors="getCardWithoutInscriptionErrors(cardIndex, 'amount')"
              :input-type="`number`"
              @input="
                (value) =>
                  handleCardInput({
                    key: 'amount',
                    value,
                    cardIndex,
                  })
              "
            >
              Wartość doładowania
            </text-input>
            <img
              v-show="cardIndex > 0"
              class="icon-trash"
              src="~@/images/icon-trash.svg"
              alt=""
              @click="() => handleDeleteCardRow(cardIndex)"
            />
          </div>
          <div class="add-card-row" @click="handleAddCardRow">
            <img class="icon-add" src="~@/images/icon-add.svg" alt="" />
            <p class="add-card-row__text">Dodaj kolejny wiersz</p>
          </div>
        </div>
        <div
          v-if="selectedCardType === CARD_TYPES.withInscription"
          class="labeled-card"
        >
          <div>
            <div class="form-group">
              <label class="label" for="name"
                >Pierwsza linia na etykiecie</label
              >
              <input
                id="name"
                v-model="currentCardDetail.label_line_1"
                type="text"
                class="input"
              />
            </div>
            <div class="form-group">
              <label class="label" for="surname"
                >Druga linia na etykiecie</label
              >
              <input
                id="surname"
                v-model="currentCardDetail.label_line_2"
                type="text"
                class="input"
              />
            </div>
            <div class="form-group">
              <label class="label" for="additional-field-1"
                >Trzecia linia na etykiecie</label
              >
              <input
                id="additional-field-1"
                v-model="currentCardDetail.label_line_3"
                type="text"
                class="input"
              />
            </div>
            <div class="form-group">
              <label class="label" for="additional-field-2"
                >Czwarta linia na etykiecie</label
              >
              <input
                id="additional-field-2"
                v-model="currentCardDetail.label_line_4"
                type="text"
                class="input"
              />
            </div>
            <div class="form-group">
              <label class="label" for="additional-field-3"
                >Piąta linia na etykiecie</label
              >
              <input
                id="additional-field-2"
                v-model="currentCardDetail.label_line_5"
                type="text"
                class="input"
              />
            </div>
          </div>
        </div>
        <div v-if="selectedCardType === CARD_TYPES.virtual">
          <div
            v-for="(card, cardIndex) in currentCardDetail.cards"
            :key="`card-row-${cardIndex}`"
            class="virtial-card__row"
          >
            <div class="form-group-double card-row">
              <text-input
                class="card-row__input"
                :value="currentCardDetail.cards[cardIndex].quantity"
                disabled
                input-type="number"
              >
                Liczba kart
              </text-input>

              <text-input
                :value="currentCardDetail.cards[cardIndex].amount"
                class="card-row__input"
                :errors="getVirtualCardErrors(cardIndex, 'amount')"
                :input-type="`number`"
                @input="
                  (value) =>
                    handleCardInput({
                      key: 'amount',
                      value,
                      cardIndex,
                    })
                "
              >
                Wartość doładowania
              </text-input>
              <img
                v-show="cardIndex > 0"
                class="icon-trash"
                src="~@/images/icon-trash.svg"
                alt=""
                @click="() => handleDeleteCardRow(cardIndex)"
              />
            </div>
            <div class="from-group virtual-card__email">
              <text-input
                :value="
                  currentCardDetail.cards[cardIndex].email_delivery_address
                "
                :errors="
                  getVirtualCardErrors(cardIndex, 'email_delivery_address')
                "
                @input="
                  (value) =>
                    handleCardInput({
                      key: 'email_delivery_address',
                      value,
                      cardIndex,
                    })
                "
              >
                Adres e-mail na który ma być przesłana karta
              </text-input>
            </div>
          </div>

          <div class="add-card-row" @click="handleAddCardRow">
            <img class="icon-add" src="~@/images/icon-add.svg" alt="" />
            <p class="add-card-row__text">Dodaj kolejny wiersz</p>
          </div>
        </div>
        <div class="buttons-container--center">
          <action-button
            v-if="selectedCardType !== CARD_TYPES.notSelected"
            :loading="loadingAddedCards"
            @click="addCard"
          >
            Dodaj do zamówienia
          </action-button>
        </div>
      </div>
    </div>
    <info-box
      v-show="getOrderedCards.length"
      ref="addCardSuccessMessage"
      class="info-box--green"
      margin-top="40px"
      margin-bottom="15px"
    >
      Zamówienie kart zostało dodane poprawnie. Możesz dodać kolejne wypełniając
      formularz ponownie lub przejść do kolejnego kroku zamówienia.
    </info-box>
    <order-cards-list @card-removed="$emit('card-removed')" />

    <error-item :errors="errors.non_field_errors" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

import { orderedCardAPIPoint } from "@/apiEndpoints";
import { CARD_TYPES } from "@/definitions";

import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";

import TextInput from "@/views/components/formFields/TextInput.vue";
import OrderCardsList from "@/views/components/formFields/OrderCardsList.vue";

import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";

export default {
  components: { TextInput, OrderCardsList, InfoBox },
  data() {
    return {
      selectedCardType: 0,
      currentCardDetail: {},
      errors: {},
      loadingAddedCards: false,
      has_label: false,
      CARD_TYPES,
      cardsWithoutInscriptionCardErrors: [],
      cardsWithoutInscriptionAddressError: null,
      virtualCardsErrors: [],
    };
  },
  computed: {
    ...mapGetters("orders", [
      "getCurrentAddresses",
      "getActiveCardOrderId",
      "getOrderedCards",
      "isVirtualProductActive",
    ]),
    ...mapState("orders", ["activeForm"]),
    canAddCardsWithLabel() {
      const {
        activeForm: { label_location },
      } = this;
      return label_location !== "none";
    },
    apiEndpoint() {
      return `${orderedCardAPIPoint}?card_order=${this.getActiveCardOrderId}`;
    },
    isDirty() {
      const { currentCardDetail = {} } = this;
      const { cards = [], amount } = currentCardDetail;
      const firstCard = cards[0];
      if (firstCard && (firstCard.amount || firstCard.quantity)) {
        return true;
      }
      if (amount) {
        return true;
      }
      return false;
    },
  },
  watch: {
    isDirty(val) {
      if (val) {
        this.$emit("is-dirty", true);
      } else {
        this.$emit("is-dirty", false);
      }
    },
    selectedCardType(selectedCardType) {
      if (this.getCurrentAddresses.length) {
        const firstAddressId = this.getCurrentAddresses[0].id;
        this.currentCardDetail.delivery_address = firstAddressId;
      }

      if (selectedCardType === CARD_TYPES.withoutInscription) {
        this.currentCardDetail.has_label = false;
        this.currentCardDetail.label_line_1 = "";
        this.currentCardDetail.label_line_2 = "";
        this.currentCardDetail.label_line_3 = "";
        this.currentCardDetail.label_line_4 = "";
        this.currentCardDetail.label_line_5 = "";
        this.currentCardDetail.cards = [
          {
            quantity: "",
            amount: "",
          },
        ];
      } else if (selectedCardType === CARD_TYPES.withInscription) {
        this.currentCardDetail.has_label = true;
        this.currentCardDetail.quantity = 1;
      } else if (selectedCardType === CARD_TYPES.virtual) {
        this.currentCardDetail.has_label = false;
        this.currentCardDetail.cards = [
          {
            quantity: "1",
            amount: "",
            email_delivery_address: "",
          },
        ];
      }
      this.errors = {};
      this.cardsWithoutInscriptionCardErrors = [];
      this.cardsWithoutInscriptionAddressError = null;
    },
  },
  mounted() {
    if (this.isVirtualProductActive) {
      this.selectedCardType = this.CARD_TYPES.virtual;
    } else if (!this.canAddCardsWithLabel) {
      this.selectedCardType = this.CARD_TYPES.withoutInscription;
    }
  },
  methods: {
    ...mapActions("orders", ["reloadActiveFormData", "updateBillingData"]),
    async postCards(data) {
      return await fetchFromApi(this.apiEndpoint, {
        method: "POST",
        data,
      });
    },
    async handleCardsPostSuccess() {
      const { reloadActiveFormData, updateBillingData } = this;
      await reloadActiveFormData();
      await updateBillingData();
      this.$emit("card-added");

      this.$nextTick(() => {
        const cardSuccessMessage = this.$refs["addCardSuccessMessage"].$el;
        cardSuccessMessage.scrollIntoView({ behavior: "smooth", block: "end" });
      });
    },
    getCardWithoutInscriptionErrors(cardIndex, property) {
      const { cardsWithoutInscriptionCardErrors } = this;
      return cardsWithoutInscriptionCardErrors[cardIndex]
        ? cardsWithoutInscriptionCardErrors[cardIndex][property]
        : undefined;
    },
    getVirtualCardErrors(cardIndex, property) {
      return this.virtualCardsErrors[cardIndex]
        ? this.virtualCardsErrors[cardIndex][property]
        : undefined;
    },
    async addCardWithoutInscription() {
      const {
        postCards,
        currentCardDetail,
        getActiveCardOrderId,
        handleCardsPostSuccess,
      } = this;

      const data = {
        batch: currentCardDetail.cards.map((card) => ({
          ...card,
          card_order: getActiveCardOrderId,
          delivery_address: currentCardDetail.delivery_address,
        })),
      };

      try {
        await postCards(data);
        await handleCardsPostSuccess();
        this.currentCardDetail = {};
        this.cardsWithoutInscriptionCardErrors = [];
        this.cardsWithoutInscriptionAddressError = null;
        const cardType = this.selectedCardType;
        this.selectedCardType = CARD_TYPES.notSelected;
        this.$nextTick(() => {
          this.selectedCardType = cardType;
        });
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.cardsWithoutInscriptionCardErrors = errors;
        const deliveryAddressError = errors[0]
          ? errors[0].delivery_address
          : null;
        if (deliveryAddressError) {
          this.cardsWithoutInscriptionAddressError = deliveryAddressError;
        }
        return this.cardsWithoutInscriptionCardErrors || deliveryAddressError;
      }
    },
    async addCardVirtual() {
      const data = {
        batch: this.currentCardDetail.cards.map((card) => ({
          ...card,
          card_order: this.getActiveCardOrderId,
        })),
      };

      try {
        await this.postCards(data);
        await this.handleCardsPostSuccess();
        this.currentCardDetail = {};
        this.virtualCardsErrors = [];
        const cardType = this.selectedCardType;
        this.selectedCardType = CARD_TYPES.notSelected;
        this.$nextTick(() => {
          this.selectedCardType = cardType;
        });
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.virtualCardsErrors = errors;
        return this.virtualCardsErrors;
      }
    },
    async addCardWithInscription() {
      const {
        postCards,
        currentCardDetail,
        getActiveCardOrderId,
        handleCardsPostSuccess,
      } = this;

      const data = {
        card_order: getActiveCardOrderId,
        ...currentCardDetail,
      };

      try {
        await postCards(data);
        await handleCardsPostSuccess();
        this.currentCardDetail = {};
        this.errors = {};
        this.selectedCardType = CARD_TYPES.notSelected;
        this.$nextTick(() => {
          this.selectedCardType = CARD_TYPES.withInscription;
        });
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
        return errors;
      }
    },
    async addCard() {
      const { selectedCardType, CARD_TYPES } = this;

      if (selectedCardType === CARD_TYPES.notSelected) {
        return;
      }

      this.loadingAddedCards = true;

      let errors;
      if (selectedCardType === CARD_TYPES.withInscription) {
        errors = await this.addCardWithInscription();
      } else if (selectedCardType === CARD_TYPES.withoutInscription) {
        errors = await this.addCardWithoutInscription();
      } else if (selectedCardType === CARD_TYPES.virtual) {
        errors = await this.addCardVirtual();
      }

      this.loadingAddedCards = false;
      return errors;
    },
    handleAddCardRow() {
      const {
        currentCardDetail: { cards },
      } = this;
      this.currentCardDetail = {
        ...this.currentCardDetail,
        cards: [
          ...cards,
          {
            quantity: this.isVirtualProductActive ? "1" : "",
            amount: "",
            ...(this.isVirtualProductActive && { email_delivery_address: "" }),
          },
        ],
      };
    },
    handleDeleteCardRow(rowIndex) {
      const {
        currentCardDetail: { cards },
        cardsWithoutInscriptionCardErrors,
      } = this;
      const updatedCards = [...cards];
      updatedCards.splice(rowIndex, 1);
      const updatedCardErrors = [...cardsWithoutInscriptionCardErrors];
      updatedCardErrors.splice(rowIndex, 1);
      this.currentCardDetail = {
        ...this.currentCardDetail,
        cards: updatedCards,
      };
      this.cardsWithoutInscriptionCardErrors = updatedCardErrors;
    },
    handleCardInput({ key, value, cardIndex }) {
      const cardDetailCopy = { ...this.currentCardDetail };
      const { cards = [] } = cardDetailCopy;
      const cardObj = cards[cardIndex] || {};
      cards.splice(cardIndex, 1, { ...cardObj, [key]: value });
      this.currentCardDetail = cardDetailCopy;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-card-row {
  display: flex;
  align-items: center;
  margin-top: 13px;
  cursor: pointer;
}

.add-card-row__text {
  font-size: $summary-font-size;
  font-weight: $font-weight-bold;
  margin-left: 11px;
}

.card-row,
.virtual-card__email {
  padding-right: 40px;
}

.card-row {
  display: flex;
  justify-content: space-between;
  position: relative;

  .icon-trash {
    position: absolute;
    right: 0;
    top: 47px; // makes it positioned at height of input bottom border
    transform: translateY(-50%);
    cursor: pointer;
    padding: 6px;
  }
}
</style>
