<template>
  <div class="invoice-details">
    <error-item :errors="errors.non_field_errors" />
    <div class="form-group-double form-group-left">
      <text-input
        v-model="localDataObj[nameWithPrefix('vat_id')]"
        :errors="errors[nameWithPrefix('vat_id')]"
        :nospinners="true"
        required
        :readonly="!!savedDataObj[nameWithPrefix('vat_id')]"
      >
        NIP
      </text-input>
    </div>

    <text-input
      v-model="localDataObj[nameWithPrefix('company_name')]"
      :errors="errors[nameWithPrefix('company_name')]"
      required
    >
      Nazwa firmy
    </text-input>

    <text-input
      v-model="localDataObj[nameWithPrefix('address')]"
      :errors="errors[nameWithPrefix('address')]"
      required
    >
      Adres
    </text-input>

    <div class="form-group-double">
      <text-input
        v-model="localDataObj[nameWithPrefix('post_code')]"
        :errors="errors[nameWithPrefix('post_code')]"
        required
      >
        Kod pocztowy
      </text-input>

      <text-input
        v-model="localDataObj[nameWithPrefix('town')]"
        :errors="errors[nameWithPrefix('town')]"
        required
      >
        Miasto
      </text-input>
    </div>
    <!-- Only some formDataObject objects support the KRS field -->
    <div class="form-group-double items-end">
      <text-input v-model="localDataObj.krs" :errors="errors.krs">
        KRS
      </text-input>
      <input-select-country
        v-model="localDataObj[nameWithPrefix('country')]"
        class="form-group"
        :errors="errors[nameWithPrefix('country')]"
        required
      />
    </div>

    <template v-if="localDataObj.hasOwnProperty(nameWithPrefix('emails'))">
      <div class="form-group form-group--invoice-consent">
        <label class="label-checkbox">
          <input
            v-model="e_invoice_consent"
            :checked="e_invoice_consent"
            type="checkbox"
            required
          />
          <span> Zgoda na przesłanie faktury drogą elektroniczną </span>
        </label>
        <error-item
          v-if="errors.e_invoice_consent"
          :errors="errors.e_invoice_consent"
        />
      </div>

      <info-box margin-top="25px">
        <div>
          Możesz wprowadzić kilka adresów e-mail po przecinku (maksymalna liczba
          znaków 200)
        </div>
        <div v-if="getUserDefaultInvoiceEmailsList.length" class="text-bold">
          Adresy mailowe, które są w rozwijanej liście obowiązywały w
          dotychczasowej współpracy. Upewnij się czy chcesz je zmienić.
        </div>
      </info-box>

      <input-text-select
        v-model="localDataObj[nameWithPrefix('emails')]"
        :items="getUserDefaultInvoiceEmailsList"
        :errors="errors[nameWithPrefix('emails')]"
        :disabled="!e_invoice_consent"
        required
        multiselect
      >
        E-mail dla dok. księgowych (jeden lub więcej, wpisz po przecinku)
      </input-text-select>
    </template>

    <input-text-select
      v-if="localDataObj.hasOwnProperty('proforma_emails')"
      v-model="localDataObj.proforma_emails"
      :items="getUserDefaultInvoiceEmailsList"
      :errors="errors.proforma_emails"
      required
      multiselect
    >
      <!-- TODO proforma_emails return another structure of errors -->
      E-mail do faktury proforma (jeden lub więcej, wpisz po przecinku)
    </input-text-select>

    <text-input
      v-if="localDataObj.hasOwnProperty('client_ref')"
      ref="client_ref"
      v-model="localDataObj.client_ref"
      :errors="errors.client_ref"
    >
      <info-box margin-top="25px">
        <div>
          Jeśli potrzebujesz numer PO na dokumentach księgowych uzupełnij pole
          niżej.
        </div>
        <div class="text-bold">
          Po złożeniu zamówienia nie będzie możliwości umieszczenia numeru PO na
          dokumentach księgowych.
        </div>
      </info-box>
      Numer referencyjny klienta (PO)
    </text-input>

    <slot></slot>
  </div>
</template>

<script>
import _isEqual from "lodash.isequal";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import { clientAPIPoint } from "@/apiEndpoints";

import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";

import TextInput from "@/views/components/formFields/TextInput.vue";
import InputTextSelect from "@/views/components/formFields/InputTextSelect.vue";
import InputSelectCountry from "@/views/components/formFields/InputSelectCountry.vue";

import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";

export default {
  name: "InvoiceDetailsForm",
  components: { TextInput, InputTextSelect, InfoBox, InputSelectCountry },
  props: {
    apiEndpoint: {
      type: String,
      required: true,
    },
    formDataObject: {
      type: Object,
      default() {
        return {};
      },
    },
    formDataObjectPrefix: {
      type: Function,
      required: true,
    },
    invoiceDetailsType: {
      type: String,
      default() {
        return "order";
      },
    },
  },
  data() {
    return {
      detailsType: {
        order: "invoice_",
        recipient: "invoice_recipient_",
      },
      currentOrderDetail: {},
      client: {},
      errors: {},
      localDataObj: {},
      savedDataObj: this.formDataObject,
    };
  },
  computed: {
    ...mapState("user", ["clientDetails"]),
    ...mapGetters("user", ["getClientId", "getUserDefaultInvoiceEmailsList"]),
    clientDetailAPIPoint() {
      return `${clientAPIPoint}${this.getClientId}/`;
    },
    detailsPrefix() {
      return this.detailsType[this.invoiceDetailsType];
    },
    e_invoice_consent: {
      get() {
        return this.clientDetails.e_invoice_consent;
      },
      set(value) {
        this.setClientEInvoiceConsent(value);
      },
    },
    isDirty() {
      return !_isEqual(this.localDataObj, this.savedDataObj);
    },
  },
  watch: {
    e_invoice_consent(value) {
      if (!value) {
        this.localDataObj[this.nameWithPrefix("emails")] = [];
      }
    },
    isDirty(val) {
      if (val) {
        this.$emit("is-dirty");
      }
    },
  },
  created() {
    // if backend previously saved required field
    if (this.savedDataObj[this.nameWithPrefix("company_name")]) {
      this.$emit("form-success");
    }
    this.localDataObj = { ...this.savedDataObj };
  },
  methods: {
    ...mapActions("user", ["loadClientDetails"]),
    ...mapMutations("user", ["setClientEInvoiceConsent"]),
    nameWithPrefix(name) {
      return `${this.formDataObjectPrefix(name)}${name}`;
    },
    async submitForm() {
      let clientDetailErrors;
      try {
        const { e_invoice_consent } = this;
        try {
          await fetchFromApi(this.clientDetailAPIPoint, {
            method: "PATCH",
            data: { e_invoice_consent },
          });
          // needed to update e_invoice_consent getter
          this.loadClientDetails();
        } catch (error) {
          const { errors } = obtainFetchError(error);
          clientDetailErrors = errors;
        }

        const payload = {
          ...this.localDataObj,
        };
        await fetchFromApi(this.apiEndpoint, {
          method: "PATCH",
          data: payload,
        });
        this.errors = {};
        this.savedDataObj = { ...this.localDataObj };
        // needed to update data by parent component
        this.$emit("request-update");
        return true;
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = clientDetailErrors
          ? { ...clientDetailErrors, ...errors }
          : errors;
        throw errorObj;
      }
    },
    // used by DynamicList.vue component
    async beforeComponentChange() {
      await this.submitForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group-left {
  justify-content: flex-start;
  align-items: center;
  .form-group {
    margin-right: 6%;
  }
}
.buttons-container--center {
  margin-bottom: 50px;
}
.link {
  font-weight: $font-weight-bold;
}

.form-group--invoice-consent {
  margin-top: 13px;
  margin-bottom: 22px;
}

.items-end {
  align-items: flex-end;
}
</style>
