import { orderAPIPoint } from "@/apiEndpoints";

import Step1 from "@/views/components/formSteps/FormStep1.vue";
import Step2 from "@/views/components/formSteps/FormStep2.vue";
import Step3 from "@/views/components/formSteps/FormStep3.vue";
import Step4 from "@/views/components/formSteps/FormStep4.vue";
import Step5 from "@/views/components/formSteps/FormStep5.vue";

export default {
  formSteps: (state, getters) => {
    const formSteps = [
      {
        description: "Konfiguracja kart",
        component: Step1,
      },
      {
        description: "Adresy dostaw",
        component: Step2,
      },
      {
        description: "Zamówienie kart",
        component: Step3,
      },
      {
        description: "Dane",
        component: Step4,
      },
      {
        description: "Podsumowanie",
        component: Step5,
      },
    ];

    if (getters.isVirtualProductActive) {
      formSteps[1].description = "Sposób zamówienia";
    }
    return formSteps;
  },
  getActiveFormBilling: (state) => {
    return state.activeFormBilling.billingArray;
  },
  isFormBillingFinal: (state) => {
    return state.activeFormBilling.isBillingFinal;
  },
  getActiveFormInvoiceData: ({
    activeForm: {
      order: {
        vat_id,
        invoice_company_name,
        invoice_address,
        invoice_post_code,
        invoice_town,
        krs,
        proforma_emails,
        invoice_emails,
        client_ref,
        invoice_country,
      },
    },
  }) => {
    return {
      vat_id,
      invoice_company_name,
      invoice_address,
      invoice_post_code,
      invoice_town,
      krs,
      proforma_emails,
      invoice_emails,
      client_ref,
      invoice_country,
    };
  },
  getActiveFormRecipientInvoiceData: ({
    activeForm: {
      order: {
        invoice_recipient_vat_id,
        invoice_recipient_company_name,
        invoice_recipient_address,
        invoice_recipient_post_code,
        invoice_recipient_town,
        invoice_recipient_country,
      },
    },
  }) => {
    return {
      invoice_recipient_vat_id,
      invoice_recipient_company_name,
      invoice_recipient_address,
      invoice_recipient_post_code,
      invoice_recipient_town,
      invoice_recipient_country,
    };
  },
  getAdditionalCardOrderDetails: (state) => {
    return {
      bank_account_number: state.activeForm.order.bank_account_number,
    };
  },
  getActiveFormOrderingPerson: (state) => {
    return state.activeForm.contact_persons[0];
  },
  getOrderedCards: (state) => {
    return state.activeForm.ordered_cards || [];
  },
  getCurrentAddresses: (state) => {
    return state.activeForm.delivery_addresses;
  },
  getCurrentAddressesLength: (state) => {
    return state.activeForm.delivery_addresses.length;
  },
  getCardTypeId: (state) => {
    return state.activeForm.card_type;
  },
  getCardPictureId: (state) => {
    return state.activeForm.picture;
  },
  getActiveProduct: (state) => {
    return state.activeForm.product;
  },
  isVirtualProductActive: (state, getters) => {
    const { getActiveProduct } = getters;
    if (!getActiveProduct) {
      return false;
    }
    return getActiveProduct.virtual;
  },
  getActiveProductTypeId: (state) => {
    try {
      return state.activeForm.product.id;
    } catch (error) {
      return null;
    }
  },
  getActiveCardOrderId: (state) => {
    return state.activeOrderId;
  },
  getActiveOrderId: ({ activeForm: { order: { id } = {} } }) => {
    return id;
  },
  getLabelLocation: (state) => {
    return state.activeForm.label_location;
  },
  getActiveFormInvoiceOptions: (state) => {
    return {
      showRecipient:
        state.activeFormInvoiceOptions.showRecipient ||
        !!state.activeForm.order.invoice_recipient_vat_id,
    };
  },
  isLastFormStep: (state, getters) => {
    return state.activeFormStep === getters.formSteps.length - 1;
  },
  getActiveProductContractPictures: (
    state,
    { getActiveProductTypeId },
    { user: { userContract } },
  ) => {
    if (!userContract.commercial_products_set) {
      return [];
    }

    const product =
      userContract.commercial_products_set.filter(
        (product) => product.commercial_product.id === getActiveProductTypeId,
      )[0] || {};
    return product.pictures || [];
  },
  getActiveOrderApiPoint: (state, { getActiveOrderId, getActiveCardOrderId }) =>
    `${orderAPIPoint}${getActiveOrderId}/?card_order=${getActiveCardOrderId}`,
};
