<template>
  <div class="order-summary">
    <loading-indicator v-if="loading" :loading="loading" class="loader" />
    <template v-else-if="billingArray.length > 0 || errors">
      <h3 class="order-summary__title">Podsumowanie zamówienia</h3>
      <template v-if="billingArray.length > 0">
        <div class="header-row">
          <span class="header-row__text"></span>
          <span class="header-row__quantity"></span>
          <span class="header-row__value">Wartość</span>
          <span class="header-row__sum">Suma</span>
        </div>
        <div class="order-summary__list">
          <order-summary-item
            v-for="([name, count, amount, sumAmount], i) in billingArray"
            :key="`item-${i}`"
            :name="name"
            :count="count"
            :amount="amount"
            :sum-amount="sumAmount"
            :is-last-item="i === billingArray.length - 1 && isBillingFinal"
          />
        </div>

        <info-box v-if="isBillingFinal" margin-top="10px">
          Podatek VAT jest liczony od wszystkich pozycji poza wartością
          doładowania kart.
        </info-box>
      </template>
      <error-item :errors="errors" />
    </template>
  </div>
</template>

<script>
import OrderSummaryItem from "@/views/components/OrderSummary/OrderSummaryItem.vue";

import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

export default {
  name: "OrderSummary",
  components: { OrderSummaryItem, InfoBox, LoadingIndicator },
  props: {
    billingArray: {
      type: Array,
      default() {
        return [];
      },
    },
    isBillingFinal: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: [String, Object, Array],
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "~@/scss/_breakpoints.scss";

.order-summary {
  background-color: white;
  padding-bottom: 20px;

  @include breakpoint-mobile {
    padding: 10px;
  }
}

.order-summary__side {
  padding: 20px;
}

.order-summary__list {
  box-shadow: 0 0 30px $shadow-color-light;
  border-radius: math.div($small-radius, 2);
  padding: 20px;
  font-size: $summary-font-size;
}

.header-row,
.order-summary-row {
  display: flex;
  justify-content: space-between;
  &__text {
    width: 35%;
    text-align: left;
  }
  &__quantity,
  &__value,
  &__sum {
    width: calc(65% / 3);
    padding-left: 5px;
  }
  &__quantity,
  &__value {
    text-align: center;
  }
  &__sum {
    text-align: right;
  }
}

.header-row {
  font-weight: $font-weight-bold;
  font-size: $small-font-size;
  padding: 0 20px 10px;
}

.step-5,
.order-details,
.order-details__summary,
.order-summary--blue {
  .order-summary {
    &__title {
      font-size: $basic-font-size;
    }
    &__list {
      background-color: $color-secondary-light;
    }
    &__value {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $color-primary;
      padding-top: 15px;
      font-weight: $font-weight-bold;
      font-size: $basic-font-size;
    }
  }
}

.loader {
  margin-top: 30px;
}
</style>
