<template>
  <div class="step step--with-stepper">
    <div class="step__content-row">
      <div class="step__content-column wide-column">
        <card-picture :available-pictures="getActiveProductContractPictures" />
      </div>
      <div
        class="step__content-column narrow-column step-1-header-personalizations"
      >
        <card-type />
      </div>
    </div>

    <template v-if="hasVisiblePersonalizationItems">
      <h2 v-if="personalizationItems" class="step-form__title">
        Dodatkowe opcje
      </h2>
      <dynamic-list
        ref="dynamicStepList"
        :steps="personalizationItems"
        :loading.sync="loading"
      />
    </template>

    <div class="buttons-container--center">
      <action-button :loading="loading" @click="handleGoToNextStep">
        Dalej
      </action-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import DynamicList from "@/views/components/formFields/DynamicList.vue";
import CardType from "@/views/components/personalizations/CardType.vue";
import CardCarrier from "@/views/components/personalizations/CardCarrier.vue";
import CustomEnvelope from "@/views/components/personalizations/CustomEnvelope.vue";
import TheInsert from "@/views/components/personalizations/TheInsert.vue";
import EnvelopeLabel from "@/views/components/personalizations/EnvelopeLabel.vue";
import CardPicture from "@/views/components/personalizations/CardPicture.vue";
import MbaNetwork from "@/views/components/personalizations/MbaNetwork.vue";
import CustomText from "@/views/components/personalizations/CustomText.vue";
import CustomLogo from "@/views/components/personalizations/CustomLogo.vue";
import CustomPicture from "@/views/components/personalizations/CustomPicture.vue";
import CustomCase from "@/views/components/personalizations/CustomCase.vue";

import iconCardUser from "@/images/icon-card-user.svg";
import iconPayer from "@/images/icon-payer.svg";
import iconPhotoDouble from "@/images/icon-photo-double.svg";
import iconDocument from "@/images/icon-document.svg";
import iconCase from "@/images/icon-case.svg";

export default {
  name: "FormStep1",
  components: {
    CardType,
    DynamicList,
    CardPicture,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("user", ["userContract"]),
    ...mapGetters("orders", [
      "getCardTypeId",
      "getActiveProductContractPictures",
    ]),
    hasVisiblePersonalizationItems() {
      const { personalizationItems } = this;
      return personalizationItems.some((item) => !item.hide);
    },
    isMbaNetworkVisible() {
      const {
        userContract: { can_set_mbanetwork, card_types },
        getCardTypeId,
      } = this;
      if (!getCardTypeId) {
        return false;
      }
      const cardType = card_types.find((type) => type.id === getCardTypeId);
      const { loop_restriction_type } = cardType;
      return (
        (loop_restriction_type === "mba" ||
          loop_restriction_type === "flexiloop") &&
        can_set_mbanetwork
      );
    },
    personalizationItems() {
      const { userContract: contract, isMbaNetworkVisible } = this;
      const personalizationItems = [
        {
          icon: iconCase,
          name: "Ozdobne etui",
          component: CustomCase,
          hide: !contract.can_add_etui,
        },
        {
          icon: iconCardUser,
          name: "Etykiety",
          component: EnvelopeLabel,
          hide: !contract.can_add_labels,
        },
        {
          icon: iconPayer,
          name: "Karta z własnym logo",
          component: CustomLogo,
          hide: !contract.can_add_logo,
        },
        {
          icon: iconPhotoDouble,
          name: "Karta z własnym wizerunkiem",
          component: CustomPicture,
          hide: !contract.can_add_picture_card,
        },
        {
          icon: iconPayer,
          name: "Napis na karcie",
          component: CustomText,
          hide: !contract.can_add_inscription,
        },
        {
          icon: iconDocument,
          name: "List lub życzenia",
          component: TheInsert,
          hide: !contract.can_add_insert,
        },
        {
          icon: iconPayer,
          name: "Indywidualna koperta",
          component: CustomEnvelope,
          hide: !contract.can_add_individual_envelope,
        },
        {
          icon: iconPayer,
          name: "Indywidualny list przewodni",
          component: CardCarrier,
          hide: !contract.can_add_card_carrier,
        },
        {
          icon: iconPayer,
          name: "Zakres akceptacji",
          component: MbaNetwork,
          hide: !isMbaNetworkVisible,
        },
      ];
      return personalizationItems;
    },
  },
  methods: {
    ...mapActions("orders", ["handleNextFormStep"]),
    async handleGoToNextStep() {
      this.loading = true;
      try {
        if (this.hasVisiblePersonalizationItems) {
          await this.$refs.dynamicStepList.validate();
        }
        this.handleNextFormStep();
      } catch (error) {
        if (error.name !== "DynamicListValidationError") {
          throw error;
        }
        console.error({
          message: "Form step 1 dynamic list validation error",
          error,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.step-1-header-personalizations {
  display: flex;
  flex-direction: column;
}
</style>
