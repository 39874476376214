<template>
  <div class="app-layout app-layout-default">
    <aside class="aside" :class="{ 'menu-opened-mobile': showMenu }">
      <header class="aside-header">
        <a class="logo-link" href="https://edenred.pl/">
          <img src="~@/images/logo-edenred.svg" alt="Logo Edenred" />
        </a>
      </header>
      <nav class="nav"></nav>
      <portal-target name="order-summary-aside" />
    </aside>
    <div class="main">
      <nav class="topbar">
        <div class="topbar__left">
          <div
            class="mobile-menu-icon"
            title="Menu"
            @click="toggleMobileMenu"
          ></div>
        </div>
      </nav>
      <div class="page-wrap">
        <router-view :key="viewReset" />
        <p class="powered-by">
          Copyright © Edenred {{ new Date().getFullYear() }} <br />
          realizacja:
          <a href="https://laboratorium.ee/" alt="Laboratorium EE"
            >Laboratorium EE</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "LayoutNof",
  data() {
    return {
      showMenu: false,
      // needed to run page component hooks
      // toggle it to trigger reload
      viewReset: false,
    };
  },
  computed: {
    ...mapState("user", ["selectedClient"]),
    ...mapGetters("user", [
      "getAvailableClients",
      "getAvailableShareCardsClients",
    ]),
  },
  watch: {
    $route() {
      const { showMenu } = this;
      if (showMenu) {
        this.showMenu = false;
      }
    },
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapActions("user", ["selectClient"]),
    async handleSelectClient(client) {
      await this.selectClient({ client, storeReset: true });
      if (this.$route.name === "orders") {
        this.viewReset = !this.viewReset;
      } else {
        this.$router.replace({ name: "orders" });
      }
    },
    async handleLogout() {
      this.logout();
      this.$router.push("/logowanie");
    },
    toggleMobileMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_breakpoints.scss";

.client-select {
  min-width: 150px;
}

@include breakpoint-mobile-narrow-up {
  .client-select {
    min-width: 250px;
  }
}

.app-layout {
  display: grid;
  grid-template-columns: 380px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  min-height: 100vh;
  position: relative;

  @include breakpoint-screen-large-down {
    width: 100%;
    grid-template-columns: 1fr;
  }
}

.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.aside {
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 60px $shadow-color;

  @include breakpoint-screen-large-down {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 380px;
    z-index: 25;
  }

  @include breakpoint-screen-large-up {
    display: flex;
  }

  @include breakpoint-mobile-narrow {
    width: 100%;
  }

  &.menu-opened-mobile {
    display: flex;
  }

  .router-link {
    display: block;
    position: relative;
    padding: 20px;
    margin: 15px 0;
    border-radius: $nav-link-radius;
    background-color: $color-secondary-light;
    color: $color-primary;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: $font-weight-bold;

    svg {
      margin-right: 10px;
    }
  }

  .router-link-active {
    background-color: $color-primary;
    color: white;
    svg path {
      fill: white;
    }
  }
}

.nav {
  width: 100%;
  border-bottom-right-radius: 200px 40px;
  border-bottom-left-radius: 200px 40px;
  background: url("~@/images/aside-bg.svg") no-repeat;
  background-size: contain;
  background-color: $color-secondary;
  background-position: bottom left;
  padding: 50px 30px;
  min-height: 470px;
}

.aside-header {
  background-color: $color-secondary;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 0;

  .right-side-menu {
    display: none;
  }

  @include breakpoint-screen-large-down {
    justify-content: flex-end;
  }

  @include breakpoint-mobile-narrow {
    padding: 25px 20px 0;
    position: relative;

    .right-side-menu {
      display: flex;
      flex-direction: row;
    }

    .logo-link {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    .router-link__profile {
      display: inline-block;
    }
  }
}

.mobile-menu-icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  background: url("~@/images/icon-mobile-menu.svg") no-repeat;
  z-index: 30;
  @include breakpoint-screen-large-up {
    display: none;
  }
}

.topbar {
  height: 80px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 40px;
  justify-content: space-between;
  @include breakpoint-screen-large-down {
    justify-content: space-between;
  }

  .right-side-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 26;
  }

  @include breakpoint-mobile {
    padding: 0 20px;
    justify-content: space-between;
  }
}

.topbar__left {
  display: flex;
  align-items: center;
}

.mobile-menu-icon {
  margin-right: 40px;
}

.router-link__notifications,
.router-link__profile,
.logout {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.router-link__notifications {
  display: none;
  background: url("~@/images/icon-notification.svg") no-repeat;
}

.router-link__profile {
  background: url("~@/images/icon-user.svg") no-repeat;
}

.logout {
  background: url("~@/images/icon-logout.svg") no-repeat;
  margin-right: 0;
}

.page-wrap {
  position: relative;
  padding-bottom: 40px;
}

.powered-by {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  font-size: $small-font-size;
  text-align: center;

  a {
    color: inherit;
  }
}
</style>
