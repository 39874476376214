<template>
  <order-summary
    :billing-array="activeFormBilling.billingArray"
    :is-billing-final="activeFormBilling.isBillingFinal"
    :errors="activeFormBillingReloadError"
    :loading="activeFormBillingLoading"
  />
</template>

<script>
import { mapState } from "vuex";
import OrderSummary from "@/views/components/OrderSummary/OrderSummary.vue";

export default {
  name: "FormOrderSummary",
  components: { OrderSummary },
  computed: {
    ...mapState("orders", [
      "activeFormBilling",
      "activeFormBillingReloadError",
      "activeFormBillingLoading",
    ]),
  },
};
</script>
