<template>
  <div class="app-layout app-layout-login">
    <aside class="aside">
      <div class="intro">
        <header class="intro-header">
          <router-link class="router-link__logo" to="/">
            <img src="~@/images/logo-edenred.svg" alt="Logo Edenred" />
          </router-link>
        </header>
        <div class="intro-content">
          <h1 class="intro-title">Witaj w&nbsp;Edenred</h1>
          <p class="intro-lead">
            Tutaj wygodnie zamówisz i&nbsp;doładujesz karty Edenred
          </p>
        </div>
        <div class="intro-layer"></div>
      </div>
    </aside>
    <div class="main">
      <nav class="topbar"></nav>
      <div class="page-wrap">
        <router-view />
        <p class="powered-by">
          Copyright © Edenred {{ new Date().getFullYear() }} <br />
          realizacja:
          <a href="https://laboratorium.ee/" alt="Laboratorium EE"
            >Laboratorium EE</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutLogin",
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_breakpoints.scss";

.app-layout {
  display: grid;
  grid-template-columns: 380px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  min-height: 100vh;
  position: relative;

  @include breakpoint-screen-large-down {
    width: 100%;
    grid-template-columns: 1fr;
  }
}

.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.aside {
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 60px $shadow-color;
  @include breakpoint-screen-large-down {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 380px;
    z-index: 25;
  }
  @include breakpoint-screen-large-up {
    display: flex;
  }
  @include breakpoint-mobile-narrow {
    width: 100%;
  }
  &.menu-opened-mobile {
    display: flex;
  }
}

.intro {
  width: 100%;
  border-bottom-right-radius: 200px 40px;
  border-bottom-left-radius: 200px 40px;
  background: url("~@/images/intro-bg.svg") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 500px;
}

.intro-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(22, 32, 86, 0.7) 0%, #162056 100%);
  opacity: 0.85;
  border-bottom-right-radius: 200px 40px;
  border-bottom-left-radius: 200px 40px;
}

.intro-header {
  padding: 20px 30px 0;
}

.intro-header,
.intro-content {
  z-index: 2;
}

.intro-content {
  font-weight: $font-weight-bold;
  color: white;
  padding: 50px 30px;
  h1 {
    color: white;
  }
}

.intro-lead {
  font-weight: $font-weight-bold;
}

.topbar {
  height: 80px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 40px;
  justify-content: space-between;

  @include breakpoint-mobile {
    padding: 0 20px;
    justify-content: space-between;
  }
}

.page-wrap {
  position: relative;
  padding-bottom: 40px;
}

.powered-by {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  font-size: $small-font-size;
  text-align: center;

  a {
    color: inherit;
  }
}
</style>
