<template>
  <div class="app-layout app-layout-default">
    <aside
      class="aside authenticated"
      :class="{ 'menu-opened-mobile': showMenu }"
    >
      <header class="aside-header">
        <router-link class="router-link__logo" to="/">
          <img src="~@/images/logo-edenred.svg" alt="Logo Edenred" />
        </router-link>
        <div class="right-side-menu">
          <router-link
            class="router-link__notifications"
            to="/"
            title="Powiadomienia"
          ></router-link>
          <router-link
            class="router-link__profile"
            to="/moj-profil"
            title="Profil"
          ></router-link>
          <div class="logout" title="Wyloguj mnie" @click="logout"></div>
        </div>
      </header>
      <nav class="nav">
        <router-link class="router-link router-link__order" to="/zamowienie/">
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.582 6.665h20.447v2.599a.79.79 0 101.582 0V2.485A2.485 2.485 0 0021.125 0H2.485A2.485 2.485 0 000 2.485v13.557a2.485 2.485 0 002.485 2.485h6.779a.79.79 0 000-1.581H2.485a.904.904 0 01-.903-.904V6.665zm20.447-4.18v2.599H1.582V2.485c0-.499.404-.903.903-.903h18.64c.5 0 .904.404.904.903zM11.862 19.431a7.57 7.57 0 1015.138 0 7.57 7.57 0 00-15.138 0zm13.556 0a5.987 5.987 0 11-11.975 0 5.987 5.987 0 0111.975 0zM3.39 10.111c0 .437.354.79.79.79h6.78a.79.79 0 000-1.58h-6.78a.79.79 0 00-.79.79zm16.042 13.706a.79.79 0 00.79-.791v-2.804h2.805a.79.79 0 000-1.582h-2.804v-2.804a.79.79 0 10-1.582 0v2.804h-2.804a.79.79 0 100 1.582h2.804v2.804c0 .436.354.79.791.79zM4.18 14.29a.79.79 0 010-1.582h3.389a.79.79 0 010 1.582h-3.39z" fill="#162056"/></svg>
          Utwórz zamówienie
        </router-link>
        <router-link class="router-link" to="/moje-zamowienia">
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.582 14.404h20.447v-2.938a.565.565 0 00-.565-.564H2.146a.565.565 0 00-.564.565v2.937zm0 1.581v8.869c0 .312.252.564.564.564h19.318a.565.565 0 00.565-.564v-8.869H1.582zm23.836-9.32H4.971v.057a.79.79 0 01-1.582 0V2.485A2.485 2.485 0 015.874 0h18.64A2.485 2.485 0 0127 2.485v12.71a.79.79 0 11-1.582 0v-8.53zm0-1.581V2.485c0-.499-.404-.903-.903-.903H5.875c-.5 0-.904.404-.904.903v2.599h20.447zM2.146 9.32h19.318c1.186 0 2.147.961 2.147 2.146v13.388A2.146 2.146 0 0121.464 27H2.146A2.146 2.146 0 010 24.854V11.467C0 10.28.961 9.32 2.146 9.32zM4.18 19.902a.79.79 0 010-1.582h6.778a.79.79 0 110 1.582H4.18zm0 3.389a.79.79 0 010-1.582h3.389a.79.79 0 010 1.582h-3.39z" fill="#162056"/></svg>
          Historia zamówień
        </router-link>
        <router-link class="router-link" to="/moje-karty">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.875 9.375h26.25V6.562a.937.937 0 00-.938-.937H2.813a.937.937 0 00-.937.938v2.812zm0 1.875v12.188c0 .517.42.937.938.937h24.375c.517 0 .937-.42.937-.938V11.25H1.875zm.938-7.5h24.375A2.813 2.813 0 0130 6.563v16.875a2.813 2.813 0 01-2.813 2.812H2.813A2.813 2.813 0 010 23.437V6.563A2.813 2.813 0 012.813 3.75zm1.831 13.363a.879.879 0 010-1.757h7.532a.879.879 0 110 1.757H4.644zm0 3.766a.879.879 0 010-1.758H8.41a.879.879 0 010 1.758H4.644z" fill="#162056"/></svg>
          Moje karty
        </router-link>
        <router-link class="router-link" to="/doladowanie/">
          <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.477 7.406H1.757v10.418c0 .555.45 1.004 1.005 1.004h7.53a.879.879 0 110 1.758h-7.53A2.762 2.762 0 010 17.824V2.762A2.762 2.762 0 012.762 0h20.71a2.762 2.762 0 012.762 2.762v7.53a.879.879 0 01-1.757 0V7.407zm0-1.757V2.762c0-.555-.45-1.005-1.004-1.005H2.76c-.554 0-1.004.45-1.004 1.005v2.887h22.72zm3.766 11.494V15A.879.879 0 1130 15v4.707a.879.879 0 01-.879.879h-4.707a.879.879 0 010-1.758h2.691a6.117 6.117 0 00-11.483.244.879.879 0 01-1.663-.57 7.873 7.873 0 0114.284-1.36zm-13.306 8.894v2.143a.879.879 0 11-1.757 0v-4.707c0-.485.393-.879.879-.879h4.707a.879.879 0 110 1.758h-2.691a6.117 6.117 0 0011.483-.244.879.879 0 111.663.57 7.873 7.873 0 01-14.284 1.359zM4.644 12.113a.879.879 0 010-1.757h7.532a.879.879 0 110 1.757H4.644zm0 3.766a.879.879 0 010-1.758H8.41a.879.879 0 010 1.758H4.644z" fill="#162056"/></svg>
          Doładuj karty
        </router-link>
        <router-link
          v-if="getAvailableShareCardsClients.length"
          class="router-link"
          :to="{ name: 'shareTypeSelect' }"
        >
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.411 24.787l.035-.048.079-.09 3.75-3.75a.937.937 0 011.416 1.221l-.09.105-2.152 2.15H22.5a.937.937 0 01.127 1.866l-.127.009h-8.049l2.15 2.15a.938.938 0 01.09 1.22l-.09.105a.938.938 0 01-1.221.091l-.105-.09-3.75-3.75-.114-.138-.069-.119-.043-.108-.034-.128-.01-.073-.005-.098.004-.086.02-.128.025-.085.028-.073.044-.089.04-.064zM23.684 0a2.846 2.846 0 012.842 2.85V9.5c0 .525-.424.95-.947.95a.949.949 0 01-.947-.95V7.6H1.893v10.45c0 .525.425.95.948.95h7.58c.522 0 .946.425.946.95 0 .525-.424.95-.947.95H2.842A2.846 2.846 0 010 18.05V2.85A2.846 2.846 0 012.842 0h20.842zm2.186 13.934l.105.09 3.75 3.75.045.048.07.09.068.119.043.108.034.128.014.135-.003.127-.02.123-.025.085-.028.073-.044.089-.04.064-.053.07-.06.067-3.75 3.75a.937.937 0 01-1.417-1.22l.09-.105 2.15-2.15H18.75a.938.938 0 01-.929-.81l-.009-.128c0-.474.353-.866.81-.928l.128-.009h8.049l-2.15-2.15a.938.938 0 01-.09-1.22l.09-.105a.938.938 0 011.221-.091zm-17.344.316c.524 0 .948.425.948.95 0 .525-.424.95-.948.95h-3.79a.949.949 0 01-.947-.95c0-.525.425-.95.948-.95h3.79zm3.79-3.8c.523 0 .947.425.947.95 0 .525-.424.95-.947.95h-7.58a.949.949 0 01-.947-.95c0-.525.425-.95.948-.95h7.579zM23.684 1.9H2.842a.949.949 0 00-.947.95L1.894 5.7H24.63V2.85a.949.949 0 00-.947-.95z" fill="#162056"/></svg>
          Udostępnij karty
        </router-link>
        <router-link
          v-if="clientDetails.has_aml_requiring_products"
          class="router-link"
          to="/aml"
        >
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.582 6.665h20.447v2.599a.79.79 0 101.582 0V2.485A2.485 2.485 0 0021.125 0H2.485A2.485 2.485 0 000 2.485v13.557a2.485 2.485 0 002.485 2.485h6.779a.79.79 0 000-1.581H2.485a.904.904 0 01-.903-.904V6.665zm20.447-4.18v2.599H1.582V2.485c0-.499.404-.903.903-.903h18.64c.5 0 .904.404.904.903zM11.862 19.431a7.57 7.57 0 1015.138 0 7.57 7.57 0 00-15.138 0zm13.556 0a5.987 5.987 0 11-11.975 0 5.987 5.987 0 0111.975 0zM3.39 10.111c0 .437.354.79.79.79h6.78a.79.79 0 000-1.58h-6.78a.79.79 0 00-.79.79zm16.042 13.706a.79.79 0 00.79-.791v-2.804h2.805a.79.79 0 000-1.582h-2.804v-2.804a.79.79 0 10-1.582 0v2.804h-2.804a.79.79 0 100 1.582h2.804v2.804c0 .436.354.79.791.79zM4.18 14.29a.79.79 0 010-1.582h3.389a.79.79 0 010 1.582h-3.39z" fill="#162056"/></svg>
          Oświadczenia AML
        </router-link>
      </nav>
      <portal-target name="order-summary-aside" />
    </aside>
    <div class="main">
      <nav class="topbar authenticated">
        <div class="topbar__left">
          <div
            class="mobile-menu-icon"
            title="Menu"
            @click="toggleMobileMenu"
          ></div>
          <input-select
            class="client-select"
            :value="selectedClient"
            :options="getAvailableClients"
            label-key="name"
            :clearable="false"
            :searchable="false"
            :disabled="getAvailableClients.length < 2"
            rounded
            color="blue"
            @input="handleSelectClient"
          />
        </div>

        <div class="right-side-menu">
          <div v-if="clientContacts.length" class="contact-tippy-wrap">
            <tippy
              ref="tippyRef"
              trigger="click"
              interactive
              theme="light"
              placement="bottom-end"
              :arrow="false"
            >
              <template #trigger>
                <div class="contact-toggle">
                  <span class="contact-toggle__text"> Kontakt </span>
                  <div class="contact-icon-wrap">
                    <img
                      class="contact-icon"
                      src="@/images/icon-send-mail.svg"
                      alt="Kontakt"
                    />
                  </div>
                </div>
              </template>
              <div class="contact-popup-wrap">
                <p class="contact-popup__header">Masz pytania?</p>
                <p class="contact-popup__subheader">Skontaktuj się z nami</p>
                <app-contact-person-list-item
                  v-for="manager in clientContacts"
                  :key="manager.email"
                  :manager="manager"
                />
              </div>
            </tippy>
          </div>

          <router-link
            class="router-link__notifications"
            to="/"
            title="Powiadomienia"
          ></router-link>
          <router-link
            class="router-link__profile"
            to="/moj-profil"
            title="Profil"
          ></router-link>
          <div class="logout" title="Wyloguj mnie" @click="handleLogout"></div>
        </div>
      </nav>
      <div class="page-wrap">
        <router-view :key="viewReset" />
        <p class="powered-by">
          Copyright © Edenred {{ new Date().getFullYear() }} <br />
          realizacja:
          <a href="https://laboratorium.ee/" alt="Laboratorium EE"
            >Laboratorium EE</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import InputSelect from "@/views/components/formFields/InputSelect.vue";
import AppContactPersonListItem from "@/views/components/utilityComponents/AppContactPersonListItem.vue";

export default {
  name: "LayoutDefault",
  components: {
    InputSelect,
    AppContactPersonListItem,
  },
  data() {
    return {
      showMenu: false,
      // needed to run page component hooks
      // toggle it to trigger reload
      viewReset: false,
    };
  },
  computed: {
    ...mapState("user", ["selectedClient", "clientDetails"]),
    ...mapGetters("user", [
      "getAvailableClients",
      "getAvailableShareCardsClients",
      "clientContacts",
    ]),
  },
  watch: {
    $route() {
      const { showMenu } = this;
      if (showMenu) {
        this.showMenu = false;
      }
    },
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapActions("user", ["selectClient"]),
    async handleSelectClient(client) {
      await this.selectClient({ client, storeReset: true });
      if (this.$route.name === "orders") {
        this.viewReset = !this.viewReset;
      } else {
        this.$router.replace({ name: "orders" });
      }
    },
    async handleLogout() {
      this.logout();
      this.$router.push("/logowanie");
    },
    toggleMobileMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style lang="scss">
// hack to remove black outline from tippy opener
.contact-tippy-wrap * {
  outline: 0 !important;
}
</style>

<style lang="scss" scoped>
@import "~@/scss/_breakpoints.scss";

$breakpoint-custom-smallest: 380px;

.client-select {
  min-width: 150px;
}

@include breakpoint-mobile-narrow-up {
  .client-select {
    min-width: 250px;
  }
}

.app-layout {
  display: grid;
  grid-template-columns: 380px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  min-height: 100vh;
  position: relative;

  @include breakpoint-screen-large-down {
    width: 100%;
    grid-template-columns: 1fr;
  }
}

.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.aside {
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 60px $shadow-color;

  @include breakpoint-screen-large-down {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 380px;
    z-index: 25;
  }

  @include breakpoint-screen-large-up {
    display: flex;
  }

  @include breakpoint-mobile-narrow {
    width: 100%;
  }

  &.menu-opened-mobile {
    display: flex;
  }

  .router-link {
    display: block;
    position: relative;
    padding: 20px;
    margin: 15px 0;
    border-radius: $nav-link-radius;
    background-color: $color-secondary-light;
    color: $color-primary;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: $font-weight-bold;

    svg {
      margin-right: 10px;
    }
  }

  .router-link__logo {
    &.router-link-active {
      background-color: transparent;
    }
  }

  .router-link-active {
    background-color: $color-primary;
    color: white;
    svg path {
      fill: white;
    }
  }
}

.nav {
  width: 100%;
  border-bottom-right-radius: 200px 40px;
  border-bottom-left-radius: 200px 40px;
  background: url("~@/images/aside-bg.svg") no-repeat;
  background-size: contain;
  background-color: $color-secondary;
  background-position: bottom left;
  padding: 50px 30px;
}

.aside-header {
  background-color: $color-secondary;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 0;
  .right-side-menu {
    display: none;
  }
  @include breakpoint-screen-large-down {
    justify-content: flex-end;
  }
  @include breakpoint-mobile-narrow {
    padding: 25px 20px 0;
    position: relative;
    .right-side-menu {
      display: flex;
      flex-direction: row;
    }
    .router-link__logo {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
    .router-link__profile {
      display: inline-block;
    }
  }
}

.mobile-menu-icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  background: url("~@/images/icon-mobile-menu.svg") no-repeat;
  z-index: 30;
  flex-shrink: 0;
  @include breakpoint-screen-large-up {
    display: none;
  }
}

.topbar {
  height: 80px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 40px;
  justify-content: space-between;

  &.authenticated {
    @include breakpoint-screen-large-down {
      justify-content: space-between;
    }
  }

  .right-side-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 26;
  }

  @include breakpoint-mobile {
    padding: 0 20px;
    justify-content: space-between;
  }

  @media (max-width: $breakpoint-custom-smallest) {
    padding: 0 12px;
  }
}

.topbar__left {
  display: flex;
  align-items: center;
}

.mobile-menu-icon {
  margin-right: 12px;
}

.router-link__notifications,
.router-link__profile,
.logout {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.router-link__notifications {
  display: none;
  background: url("~@/images/icon-notification.svg") no-repeat;
}

.router-link__profile {
  background: url("~@/images/icon-user.svg") no-repeat;
}

.logout {
  background: url("~@/images/icon-logout.svg") no-repeat;
  margin-right: 0;
}

.page-wrap {
  position: relative;
  padding-bottom: 40px;
}

.powered-by {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  font-size: $small-font-size;
  text-align: center;

  a {
    color: inherit;
  }
}

.contact-popup-wrap {
  padding: 31px 2px 14px;
  min-width: 320px;
  color: $color-primary;
}

.contact-toggle {
  margin-right: 12px;
  display: flex;
  align-items: center;
  font-weight: $font-weight-bolder;
  font-size: $summary-font-size;
  cursor: pointer;
  position: relative;
  padding-bottom: 4px;
}

.contact-icon {
  position: relative;
  display: inline-block;
  right: 1.8px;
  user-select: none;
}

.contact-icon-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s;
  }

  &:hover {
    &::after {
      opacity: 0.71;
    }
  }
}

.tippy-active {
  .contact-icon-wrap {
    &::after {
      opacity: 0.71;
    }
  }
}

.contact-popup__header {
  font-size: $h1-font-size;
  line-height: 29.26px;
  font-weight: $font-weight-bolder;
  text-align: center;
  margin: 0;
  margin-bottom: 0.7rem;
}

.contact-popup__subheader {
  font-size: $input-font-size;
  line-height: 18.29px;
  margin: 0;
  margin-bottom: 28px;
  text-align: center;
  color: $text-color;
}

.contact-toggle__text {
  display: none;
}

@media (min-width: $breakpoint-custom-smallest) {
  .mobile-menu-icon {
    margin-right: 40px;
  }

  .contact-toggle {
    margin-right: 16px;
  }

  .contact-icon-wrap {
    &::after {
      width: 50px;
      height: 50px;
    }
  }

  .contact-popup-wrap {
    padding-left: 17px;
    padding-right: 17px;
    min-width: 320px;
    color: $color-primary;
  }
}

@include breakpoint-mobile-up {
  .contact-toggle__text {
    display: inline;
  }

  .contact-icon-wrap {
    margin-left: 20px;
  }

  .router-link__notifications,
  .router-link__profile,
  .logout {
    margin-right: 14px;
  }

  .contact-toggle {
    margin-right: 20px;
  }
}
</style>
