import { validatePolish } from "validate-polish";

function getBirthDate(value) {
  let year = parseInt(value.substring(0, 2), 10);
  let month = parseInt(value.substring(2, 4), 10);
  let day = parseInt(value.substring(4, 6), 10);

  if (month > 80) {
    year = year + 1800;
    month = month - 80;
  } else if (month > 60) {
    year = year + 2200;
    month = month - 60;
  } else if (month > 40) {
    year = year + 2100;
    month = month - 40;
  } else if (month > 20) {
    year = year + 2000;
    month = month - 20;
  } else {
    year += 1900;
  }
  return `${year}-${month}-${day}`;
}

export default function validatePESEL(value) {
  const isValid = validatePolish.pesel(value);
  let birthDate = null;

  if (!isValid) {
    return { isValid, birthDate };
  }
  birthDate = getBirthDate(value);

  return {
    isValid,
    birthDate,
  };
}
