<template>
  <div class="address-wrapper">
    <input-text-select
      v-model="newAddress.name"
      :errors="errors.name"
      :items="clientDeliveryAddresses"
      item-label="name"
      required
      @item-selected="handleSavedDeliveryAddressSelect"
    >
      Nazwa firmy
    </input-text-select>
    <text-input v-model="newAddress.name_alt" :errors="errors.name_alt">
      Nazwa alternatywna
    </text-input>
    <input-select-country
      v-model="newAddress.country"
      class="form-group form-group--country"
      :errors="errors.country"
    />
    <text-input v-model="newAddress.address" :errors="errors.address" required>
      Adres
    </text-input>
    <div class="form-group-double">
      <text-input
        v-model="newAddress.zip_code"
        :errors="errors.zip_code"
        required
      >
        Kod pocztowy
      </text-input>
      <text-input v-model="newAddress.city" :errors="errors.city" required>
        Miasto
      </text-input>
    </div>
    <text-input
      v-model="newAddress.authorized_person_email"
      :errors="errors.authorized_person_email"
      required
    >
      E-mail do wysyłki pliku z nr kart
    </text-input>

    <h2 class="step-form__title authorized-persons__title">
      Osoba upoważniona do odbioru przesyłki
    </h2>
    <p class="step-form__subtitle">
      Tylko ta&nbsp;osoba otrzyma sms z PIN-em do&nbsp;odbioru paczki
      z&nbsp;kartami.
    </p>
    <div class="first-person">
      <text-input
        v-model="newAddress.authorized_person_first_name"
        :errors="errors.authorized_person_first_name"
        required
      >
        Imię
      </text-input>
    </div>
    <div class="first-person">
      <text-input
        v-model="newAddress.authorized_person_last_name"
        :errors="errors.authorized_person_last_name"
        required
      >
        Nazwisko
      </text-input>
    </div>
    <div class="first-person">
      <text-input
        v-model="newAddress.authorized_person_phone"
        :errors="errors.authorized_person_phone"
        required
      >
        Numer telefonu komórkowego
      </text-input>
    </div>
  </div>
</template>

<script>
import _isEqual from "lodash.isequal";
import { mapGetters, mapActions } from "vuex";

import { addressAPIPoint } from "@/apiEndpoints";
import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";
import { formObjectHasValues } from "@/utils/utilityFunctions";

import TextInput from "@/views/components/formFields/TextInput.vue";
import InputTextSelect from "@/views/components/formFields/InputTextSelect.vue";
import InputSelectCountry from "@/views/components/formFields/InputSelectCountry.vue";

export default {
  name: "NofAddressForm",
  components: { InputTextSelect, TextInput, InputSelectCountry },
  data() {
    return {
      initialAddress: {},
      newAddress: {},
      loadingAddedAddress: false,
      errors: {},
    };
  },
  computed: {
    ...mapGetters("user", ["clientDeliveryAddresses"]),
    ...mapGetters("orders", ["getActiveCardOrderId", "getCurrentAddresses"]),
    savedAddress() {
      return this.getCurrentAddresses[0] || {};
    },
    isDirty() {
      const { newAddress, initialAddress } = this;
      return (
        !_isEqual(newAddress, initialAddress) && formObjectHasValues(newAddress)
      );
    },
  },
  watch: {
    isDirty(val) {
      if (val) {
        this.$emit("is-dirty", true);
      } else {
        this.$emit("is-dirty", false);
      }
    },
  },
  created() {
    // if backend previously saved address
    if (formObjectHasValues(this.savedAddress)) {
      this.$emit("form-success");
    }
    this.initialAddress = { ...this.savedAddress };
    this.newAddress = { ...this.savedAddress };
  },
  methods: {
    ...mapActions("orders", ["reloadActiveFormData", "updateBillingData"]),
    async addAddress() {
      this.loadingAddedAddress = true;
      try {
        if (formObjectHasValues(this.savedAddress)) {
          await fetchFromApi(
            `${addressAPIPoint}${this.savedAddress.id}/?card_order=${this.getActiveCardOrderId}`,
            {
              method: "PATCH",
              data: {
                card_order: this.getActiveCardOrderId,
                ...this.newAddress,
              },
            },
          );
        } else {
          await fetchFromApi(
            `${addressAPIPoint}?card_order=${this.getActiveCardOrderId}`,
            {
              method: "POST",
              data: {
                card_order: this.getActiveCardOrderId,
                ...this.newAddress,
              },
            },
          );
        }

        this.newAddress = {};
        this.errors = {};
        await this.reloadActiveFormData();
        await this.updateBillingData();
        this.initialAddress = { ...this.savedAddress };
        this.newAddress = { ...this.savedAddress };
        return true;
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
        throw errorObj;
      } finally {
        this.loadingAddedAddress = false;
      }
    },
    handleSavedDeliveryAddressSelect(addressObj) {
      this.newAddress = {
        ...addressObj,
      };
    },
    // used by DynamicList.vue component
    async beforeComponentChange() {
      await this.addAddress();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_breakpoints.scss";

.authorized-persons__title {
  margin-top: 32px;
}

.first-person {
  position: relative;
  &::after {
    font-size: $label-font-size;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.form-group--country {
  margin-bottom: 24px;
}
</style>
