<template>
  <div class="card-picture">
    <h2 class="step-form__title">{{ title }}</h2>
    <p v-if="cardType" class="card-type">
      {{ cardType }}
    </p>
    <slick-carousel
      v-if="availablePictures.length > 1"
      :initial-picture-index="initialPictureIndex"
      @picture-changed="valueChanged"
    >
      <img
        v-for="picture in availablePictures"
        :key="`picture-${picture.id}`"
        :src="picture.preview_file"
      />
    </slick-carousel>
    <img
      v-else-if="availablePictures.length === 1"
      :src="availablePictures[0].preview_file"
    />
    <error-item :errors="errors" />
  </div>
</template>

<script>
import SlickCarousel from "@/views/components/formFields/SlickCarousel.vue";
import updateOrderMixin from "@/mixins/updateOrderMixin";
import { mapGetters } from "vuex";

export default {
  name: "CardPicture",
  components: { SlickCarousel },
  mixins: [updateOrderMixin],
  props: {
    availablePictures: {
      type: Array,
      default() {
        [];
      },
    },
    cardType: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Wizerunek na karcie",
    },
  },
  computed: {
    ...mapGetters("orders", ["getCardPictureId"]),
    initialPictureIndex() {
      const initialIndex = this.availablePictures.findIndex((picture) => {
        return picture.id === this.getCardPictureId;
      });
      return initialIndex === -1 ? 0 : initialIndex;
    },
  },
  methods: {
    async valueChanged(value) {
      this.updateOrder({
        picture: this.availablePictures[value].id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-picture {
  margin-bottom: 16px;
}

.card-type {
  font-weight: $font-weight-bold;
  font-size: $checkbox-font-size;
  margin: 19px 0 13px;
}
</style>
