<template>
  <div>
    <h2 class="step-form__title">Beneficjent rzeczywisty {{ index + 1 }}</h2>
    <text-input v-model="form.name" :errors="errors.name">
      Imiona i nazwiska
    </text-input>
    <text-input
      v-model="form.country_of_residence"
      :errors="errors.country_of_residence"
    >
      Kraj zamieszkania
    </text-input>
    <text-input
      v-model="form.address_of_residence"
      :errors="errors.address_of_residence"
    >
      Adres zamieszkania
    </text-input>
    <input-date-picker v-model="form.birth_date" :errors="errors.birth_date">
      Data urodzenia
    </input-date-picker>
    <text-input
      v-model="form.birth_place"
      required
      double-star
      :errors="errors.birth_place"
    >
      Miejsce urodzenia
    </text-input>
    <text-input v-model="form.citizenship" :errors="errors.citizenship">
      Obywatelstwo
    </text-input>
    <text-input v-model="form.pesel" :errors="errors.pesel">
      Numer PESEL
    </text-input>
    <text-input
      v-model="form.identity_document_id"
      required
      double-star
      :errors="errors.identity_document_id"
    >
      Seria i numer dokumentu potwierdzającego tożsamość
    </text-input>
    <input-textarea
      v-model="form.grounds_description"
      class="input input--comments"
      rows="2"
      required
      :errors="errors.grounds_description"
    >
      Krótkie uzasadnienie dla uznania osoby za beneficjenta rzeczywistego
    </input-textarea>
    <p class="label">Jakie jest źródło funduszy Beneficjenta Rzeczywistego:</p>
    <label class="label-checkbox">
      <input type="checkbox" v-model="form.funds_source_business_activity" />
      <span
        >działalność gospodarcza / umowa o pracę lub podobne / pełnienie
        obowiązków publicznych</span
      >
    </label>
    <label class="label-checkbox">
      <input type="checkbox" v-model="form.funds_source_own_assets" />
      <span>aktywa własne</span>
    </label>
    <label class="label-checkbox">
      <input type="checkbox" v-model="form.funds_source_insurance_benefits" />
      <span>świadczenia ubezpieczeniowe</span>
    </label>
    <label class="label-checkbox">
      <input type="checkbox" v-model="form.funds_source_transferred_assets" />
      <span>przeniesienie aktywów (np. dziedziczenie, darowizna) </span>
    </label>
    <label class="label-checkbox">
      <input type="checkbox" v-model="form.funds_source_sold_assets" />
      <span>sprzedaż aktywów</span>
    </label>

    <p class="label">
      Czy beneficjent rzeczywisty jest osobą zajmującą eksponowane stanowisko
      polityczne? **
    </p>
    <input-radio-group
      name="is_pep"
      v-model="form.is_pep"
      :items="yesNoChoices"
      :active-radio="form.is_pep"
      :errors="errors.is_pep"
    />
    <p class="label">
      Czy beneficjent rzeczywisty jest członkiem rodziny osoby zajmującej
      eksponowane stanowisko polityczne? **
    </p>
    <input-radio-group
      name="is_pep_family"
      v-model="form.is_pep_family"
      :items="yesNoChoices"
      :active-radio="form.is_pep_family"
      :errors="errors.is_pep_family"
    />
    <p class="label">
      Czy beneficjent rzeczywisty jest osobą znaną jako bliski współpracownik
      osoby zajmującej eksponowane stanowisko polityczne? **
    </p>
    <input-radio-group
      name="is_pep_associate"
      v-model="form.is_pep_associate"
      :items="yesNoChoices"
      :active-radio="form.is_pep_associate"
      :errors="errors.is_pep_associate"
    />
  </div>
</template>

<script>
import TextInput from "@/views/components/formFields/TextInput.vue";
import InputDatePicker from "@/views/components/formFields/InputDatePicker.vue";
import InputTextarea from "@/views/components/formFields/InputTextarea.vue";
import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";

export default {
  name: "BeneficialOwner",
  components: {
    TextInput,
    InputDatePicker,
    InputTextarea,
    InputRadioGroup,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    errors: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        localId: "",
        id: "",
        name: "",
        address_of_residence: "",
        birth_date: null,
        birth_place: "",
        citizenship: "",
        pesel: "",
        identity_document_id: "",
        grounds_description: "",
        funds_source_business_activity: false,
        funds_source_insurance_benefits: false,
        funds_source_own_assets: false,
        funds_source_sold_assets: false,
        funds_source_transferred_assets: false,
        is_pep: false,
        is_pep_family: false,
        is_pep_associate: false,
      },
      yesNoChoices: [
        {
          label: "tak",
          value: true,
        },
        {
          label: "nie",
          value: false,
        },
      ],
    };
  },
  watch: {
    form: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  created() {
    Object.keys(this.form).forEach((key) => (this.form[key] = this.value[key]));
    const checkboxFields = [
      "funds_source_business_activity",
      "funds_source_insurance_benefits",
      "funds_source_own_assets",
      "funds_source_sold_assets",
      "funds_source_transferred_assets",
    ];
    checkboxFields.forEach((field) => {
      if (this.form[field] === null || this.form[field] === undefined) {
        this.form[field] = false;
      }
    });
  },
};
</script>
<style scoped>
.label-checkbox {
  margin-bottom: 16px;
}
</style>
