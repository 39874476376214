export const setViewport = (state, viewport) => {
  state.viewport = viewport;
};

export const setWidth = (state, width) => {
  state.width = width;
};

export const setHeight = (state, height) => {
  state.height = height;
};
