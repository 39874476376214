import formSteps from "./nofFormSteps";

const getDefaultState = () => ({
  activeFormStep: 0,
  formSteps,
  activeFormInvoiceOptions: {
    showRecipient: false,
  },
  activeNofContract: undefined,
  nofOrderNotAuthorizedError: undefined,
});

const state = getDefaultState();

export default state;

export { getDefaultState };
