<template>
  <div
    class="cards-list-item"
    :class="{ 'is-expandable': isExpandable, 'is-expanded': isExpanded }"
  >
    <app-expansion-panel v-model="isExpanded">
      <div class="card-list-item__expander" @click="isExpanded = !isExpanded">
        <div class="expander__icon-wrap">
          <img
            v-if="isExpandable"
            src="~@/images/icon-chevron-right.svg"
            class="expander__icon"
            alt="Rozwiń element"
          />
        </div>
        <div class="cards-list-item__icon">
          <img src="~@/images/icon-card.svg" alt="" />
        </div>
        <div class="cards-list-item__content">
          <div class="basic-info">
            <div class="card-number">
              {{ card.card_serial_number || card.serial_number }}
            </div>
            <div class="card-type">{{ card.card_type_public_name }}</div>
          </div>
          <div class="additional-info">
            <div class="inputs-wrap">
              <!-- @click.stop event is needed to stop isExpanded state toggling on checkbox change  -->
              <input
                v-if="hasInput"
                :value="value"
                class="cards-list-item__input"
                type="number"
                min="0"
                @blur="$emit('blur', { cardId, value })"
                @input="$emit('input', $event.target.value)"
                @click.stop="() => false"
              />
              <div
                v-if="isDeletable"
                class="cards-list-item__delete"
                :class="isDeletingDisabled ? 'cursor-disabled' : ''"
                @click.stop="
                  isDeletingDisabled ? false : $emit('delete', cardId)
                "
              ></div>
            </div>
            <error-item :errors="errors" />
          </div>
        </div>
      </div>
      <template #expandable>
        <div
          v-if="isExpandable"
          ref="cardItemExpandableRef"
          class="card-item__expandable"
        >
          <div class="expandable__content">
            <p
              v-if="card.duplicates_serial_numbers.length"
              class="expandable__title"
            >
              Poprzednie numery seryjne:
            </p>
            <span
              v-for="serialNumber in card.duplicates_serial_numbers"
              :key="serialNumber"
              class="card-item__duplicate-number"
            >
              {{ serialNumber }}
            </span>
          </div>
        </div>
      </template>
    </app-expansion-panel>
  </div>
</template>

<script>
import AppExpansionPanel from "@/views/components/utilityComponents/AppExpansionPanel.vue";

// TODO: fix jumping content when error happens
export default {
  name: "CardsListItem",
  components: {
    AppExpansionPanel,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    value: {
      type: [Number, String],
      default: "",
    },
    hasInput: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: [Array, Object, String],
      default() {
        return {};
      },
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
    isDeletingDisabled: {
      type: Boolean,
      default: false,
    },
    // sets initial accordion state
    initialExpandedState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: this.initialExpandedState,
    };
  },
  computed: {
    cardId() {
      const {
        card: { id },
      } = this;
      return id;
    },
    isExpandable() {
      const { duplicates_serial_numbers } = this.card;
      return duplicates_serial_numbers && duplicates_serial_numbers.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_breakpoints.scss";
@import "~@/scss/global.scss";

.cards-list-item {
  margin-bottom: 10px;
  &.is-expandable {
    .card-list-item__expander {
      cursor: pointer;
    }

    &.is-expanded {
      .expander__icon {
        transform: rotateZ(270deg);
      }
    }
  }
}

.card-list-item__expander {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.expander__icon-wrap {
  margin-right: 15px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  transform: translateX(-100%);
}

.expander__icon {
  transform: rotateZ(90deg);
  transform-origin: center;
  transition: transform 0.2s;
}

.expandable__content {
  padding-left: 97px;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-right: 42px;
}

.expandable__title {
  font-size: $small-font-size;
  font-weight: $font-weight-bold;
  margin: 0 0 4px;
}

.card-item__duplicate-number {
  font-size: $summary-font-size;
  margin-right: 8px;
  margin-bottom: 4px;
  display: inline-block;
}

.basic-info,
.details {
  display: flex;
  flex-direction: column;
}

.additional-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.inputs-wrap {
  display: flex;
  justify-content: flex-end;
}

.card-number {
  font-weight: $font-weight-bold;
}
.card-type {
  font-size: $small-font-size;
}
.cards-list-item__input {
  border: 1px solid $border-color;
  border-radius: $button-radius;
  padding: 10px;
  text-align: right;
  max-width: 180px;
}

.cards-list-item__icon {
  @extend %icon;

  margin-left: 14px;
  flex-shrink: 0;

  img {
    height: 24px;
    width: 24px;
  }
}

.cards-list-item__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border-color-light;
  padding: 15px 0;
  width: calc(100% - 85px);
  > div {
    flex-basis: calc(100% / 2);
  }
}

.cards-list-item__delete {
  cursor: pointer;
  border-radius: 50%;
  background-color: #fbebea;
  background-image: url("~@/images/icon-trash.svg");
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
  width: 26px;
  height: 26px;
  margin-left: 20px;
  align-self: center;
  @include breakpoint-mobile {
    margin-left: 10px;
  }
}
</style>
