import currency from "currency.js";

import Vue from "vue";

Vue.filter("toPln", function (paramValue) {
  let value = paramValue;
  if (typeof paramValue === "string" && paramValue.indexOf(".") !== -1) {
    value = paramValue.replace(".", ",");
  }

  if (value && (typeof value === "number" || typeof value === "string")) {
    return `${currency(value, {
      separator: " ",
      decimal: ",",
      precision: 2,
      symbol: "",
    }).format()} zł`;
  } else {
    return null;
  }
});

Vue.filter("formatDate", function (value) {
  if (value) {
    const myNewDate = new Date(value)
      .toISOString()
      .slice(0, 10)
      .split("-")
      .reverse()
      .join(".");
    return myNewDate;
  } else {
    return "";
  }
});
