<template>
  <invoice-details-form
    ref="invoiceDetailsRef"
    :api-endpoint="apiEndpoint"
    :form-data-object="getActiveFormInvoiceData"
    :form-data-object-prefix="formDataPrefix"
    @form-error="($event) => $emit('form-error', $event)"
    @form-success="handleFormSuccess"
    @request-update="reloadActiveFormData"
    @is-dirty="$emit('is-dirty')"
  >
    <div class="form-group">
      <label class="label-checkbox">
        <input
          v-model="showRecipient"
          type="checkbox"
          :disabled="
            !!getActiveFormRecipientInvoiceData.invoice_recipient_vat_id
          "
        />
        <span> Dane odbiorcy na fakturze są inne niż nabywcy </span>
      </label>
    </div>
  </invoice-details-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import { orderAPIPoint } from "@/apiEndpoints";

import InvoiceDetailsForm from "@/views/components/formFields/invoice_details/InvoiceDetailsForm.vue";

export default {
  name: "NofOrderInvoiceDetails",
  components: { InvoiceDetailsForm },
  computed: {
    ...mapGetters("orders", [
      "getActiveFormInvoiceData",
      "getActiveFormRecipientInvoiceData",
      "getActiveOrderId",
      "getActiveCardOrderId",
    ]),
    ...mapGetters("nof", ["getActiveFormInvoiceOptions"]),
    apiEndpoint() {
      return `${orderAPIPoint}${this.getActiveOrderId}/?card_order=${this.getActiveCardOrderId}`;
    },
    showRecipient: {
      get() {
        return this.getActiveFormInvoiceOptions.showRecipient;
      },
      set(value) {
        this.updateActiveFormInvoiceOptions({ showRecipient: value });
      },
    },
  },
  methods: {
    ...mapActions("orders", ["reloadActiveFormData"]),
    ...mapMutations("nof", ["updateActiveFormInvoiceOptions"]),
    formDataPrefix(field) {
      return field !== "vat_id" ? "invoice_" : "";
    },
    async handleFormSuccess($event) {
      this.$emit("form-success", $event);
    },
    // used by DynamicList.vue component
    async beforeComponentChange() {
      const {
        $refs: { invoiceDetailsRef },
      } = this;
      if (invoiceDetailsRef.beforeComponentChange) {
        await invoiceDetailsRef.beforeComponentChange();
      }
    },
  },
};
</script>
