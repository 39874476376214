<template>
  <div class="step step--with-stepper">
    <template v-if="isVirtualProductActive">
      <h2 class="step-form__title">
        Jeśli chcesz otrzymać karty wirtualne w konkretnym dniu
      </h2>
      <p>Zaznacz w kalendarzu datę, w którym wyślemy karty.</p>
      <input-date-picker
        v-model="requested_delivery_date"
        :min-date="getTomorrowDate()"
        :disabled-dates="{
          weekdays: [1, 7],
        }"
      />
      <error-item :errors="errors.requested_delivery_date" />
      <info-box>
        Zanim zdefiniujesz swoje zamówienie, podaj adres e-mail, na który ma
        zostać wysłane podsumowanie zamówienia.
      </info-box>
      <div class="virtual-product__email">
        <h2 class="step-form__title">Adres do wysyłki pliku z nr kart</h2>
        <p class="step-form__subtitle">
          Podaj adres e-mail, na który zostanie przesłany plik zawierający
          numery wszystkich zamówionych kart.
        </p>
        <text-input
          v-model="virtualProductEmailAddress"
          :errors="errors.virtual_cards_main_email_address"
          required
        >
          E-mail do wysyłki pliku z nr kart
        </text-input>
      </div>
      <info-box>
        Tutaj wybierz jak chcesz złożyć zamówienie. Jeśli składasz zamówienie na
        wiele kart, najwygodniej skorzystać z opcji uzupełnienia zamówienia na
        podstawie pliku Excel.
      </info-box>

      <excel-order
        v-model="isExcelSelected"
        class="virtual-product__excel"
        @success="() => goToNextStep(3)"
      >
        Aby złożyć zamówienie na podstawie pliku Excel
        <a
          class="link text-bold"
          :href="ExcelVirtualCardsOrderTemplate"
          target="_blank"
        >
          pobierz szablon
        </a>
        i uzupełnij go zgodnie z instrukcją. Następnie dołącz plik poniżej.
      </excel-order>
    </template>
    <template v-else>
      <info-box>
        Tutaj wybierz jak chcesz złożyć zamówienie. Jeśli Twoje zamówienie
        obejmuje wiele adresów dostaw lub wiele kart z etykietami, najwygodniej
        skorzystać z opcji uzupełnienia zamówienia na podstawie pliku Excel.
      </info-box>
      <excel-order v-model="isExcelSelected" @success="() => goToNextStep(3)" />

      <info-box v-if="!isExcelSelected">
        Zanim zdefiniujesz swoje zamówienie, podaj adres lub adresy dostaw, do
        których przypiszesz zamawiane karty.
      </info-box>

      <address-input
        v-if="!isExcelSelected"
        ref="addressInputRef"
        @is-dirty="($event) => (isAddressDirty = $event)"
        @address-changed="addressChanged"
      />
      <info-box
        v-show="addressAddedSuccess"
        ref="address-added-success-message"
        class="info-box--green"
      >
        Adres dostawy został zapisany poprawnie. Możesz dodać kolejny adres
        wypełniając ponownie formularz lub przejść Dalej, jeśli adres dostawy
        jest tylko jeden.
      </info-box>

      <addresses-list />
    </template>
    <div v-if="showAddressErrorMessage" class="form-error">
      Zamówienie musi posiadać przynajmniej jeden adres do wysyłki.
    </div>
    <div class="buttons-container">
      <action-button secondary @click="handlePreviousFormStep">
        Wstecz
      </action-button>
      <action-button
        :disabled="isNextStepBtnDisabled"
        :loading="loading"
        @click="goToNextStep"
      >
        Dalej
      </action-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

import updateOrderMixin from "@/mixins/updateOrderMixin";

import AddressInput from "@/views/components/formFields/AddressInput.vue";
import InputDatePicker from "@/views/components/formFields/InputDatePicker.vue";
import AddressesList from "@/views/components/formFields/AddressesList.vue";
import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";

import ExcelOrder from "@/views/components/personalizations/ExcelOrder/ExcelOrder.vue";
import TextInput from "@/views/components/formFields/TextInput.vue";

import ExcelVirtualCardsOrderTemplate from "@/xlsx/FormularzZamowieniaKartWirtualnych.xlsx";

export default {
  name: "FormStep2",
  components: {
    AddressInput,
    ExcelOrder,
    AddressesList,
    InfoBox,
    TextInput,
    InputDatePicker,
  },
  mixins: [updateOrderMixin],
  data() {
    return {
      showAddressErrorMessage: false,
      isExcelSelected: false,
      addressAddedSuccess: false,
      isAddressDirty: false,
      errors: {},
      virtualProductEmailAddress: "",
      loading: false,
      ExcelVirtualCardsOrderTemplate,
      requested_delivery_date: undefined,
    };
  },
  computed: {
    ...mapState("user", ["userContract"]),
    ...mapState("orders", ["activeForm", "activeFormStep"]),
    ...mapGetters("orders", ["isVirtualProductActive"]),
    isNextStepBtnDisabled() {
      return (
        this.isVirtualProductActive &&
        ((this.isExcelSelected && !this.activeForm.ordered_cards.length) ||
          !this.virtualProductEmailAddress.length)
      );
    },
  },
  mounted() {
    this.virtualProductEmailAddress =
      this.activeForm.virtual_cards_main_email_address;
    this.requested_delivery_date = this.activeForm.requested_delivery_date;
  },
  methods: {
    ...mapActions("orders", ["handlePreviousFormStep"]),
    ...mapMutations("orders", ["setActiveFormStep"]),
    async goToNextStep(stepIndex = this.activeFormStep + 1) {
      const {
        $refs: { addressInputRef },
        isExcelSelected,
        isAddressDirty,
      } = this;

      if (this.isVirtualProductActive) {
        if (!this.virtualProductEmailAddress) {
          this.errors = {
            virtual_cards_main_email_address: "To pole jest wymagane",
          };
          return;
        }

        if (isExcelSelected && !this.activeForm.ordered_cards.length) {
          return;
        }

        this.loading = true;
        await this.updateOrder({
          virtual_cards_main_email_address: this.virtualProductEmailAddress,
          requested_delivery_date: this.requested_delivery_date,
        });
        this.loading = false;
        if (this.errors && Object.keys(this.errors).length) {
          return;
        }
      } else {
        if (!this.activeForm["delivery_addresses"].length && isExcelSelected) {
          this.showAddressErrorMessage = true;
          return;
        }

        if (isAddressDirty && addressInputRef) {
          const errors = await addressInputRef.addAddress();
          if (errors) {
            return;
          }
        }

        if (!this.activeForm["delivery_addresses"].length) {
          this.showAddressErrorMessage = true;
          return;
        }
      }

      this.clearErrorMessage();
      this.setActiveFormStep(stepIndex);
    },
    addressChanged() {
      this.clearErrorMessage();
      this.showAddressSuccessMessage();
    },
    clearErrorMessage() {
      this.showAddressErrorMessage = false;
    },
    showAddressSuccessMessage() {
      this.addressAddedSuccess = true;
      this.$nextTick(() => {
        const successMessage = this.$refs["address-added-success-message"].$el;
        successMessage.scrollIntoView({ behavior: "smooth", block: "end" });
      });
    },
    getTomorrowDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-error {
  text-align: center;
  margin-top: 1rem;
}

.virtual-product__email {
  max-width: 44%;
}

.virtual-product__email {
  margin-bottom: 32px;
}

.virtual-product__excel {
  padding-left: 0;
  margin-top: 32px;
}
</style>
