<template>
  <div>
    <template v-if="canChangeAtmWithdrawal">
      <input-radio-group
        v-model="activeAtmWithdrawChoice"
        :items="atmWithdrawChoices"
        name="mba-radio"
        :active-radio="activeAtmWithdrawChoice"
        class="mba-radio"
      />

      <error-item
        v-show="errors.allow_atm_withdrawal"
        :errors="errors.allow_atm_withdrawal"
      />
    </template>

    <loading-indicator :loading="networkTypesLoading" />
    <template v-if="!networkTypesLoading && mbaNetworkTypes.length">
      <info-box>
        Karta będzie obsługiwana w wybranych poniżej miejscach
      </info-box>

      <input-radio-group
        :value="activeMbaNetworkType"
        :items="mbaNetworkTypes"
        name="mba-network-types"
        :active-radio="activeMbaNetworkType"
        :disabled="updatingOrder || networkTypesLoading"
        label-key="name"
        value-key="id"
        @input="handleNetworkTypeUpdate"
      />

      <error-item :errors="mbaNetworkErrors" />
    </template>

    <error-item
      v-show="mbaNetworkErrors.non_field_errors || errors.non_field_errors"
      :errors="mbaNetworkErrors.non_field_errors || errors.non_field_errors"
    />

    <loading-indicator :loading="updatingOrder" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import updateOrderMixin from "@/mixins/updateOrderMixin";

import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";
import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";

import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";

import { obtainFetchError, FormValidationError } from "@/utils/errors";

export default {
  name: "MbaNetwork",
  components: {
    LoadingIndicator,
    InfoBox,
    InputRadioGroup,
  },
  mixins: [updateOrderMixin],
  data() {
    return {
      mbaNetworkTypes: [],
      activeMbaNetworkType: undefined,
      atmWithdrawChoices: [
        { label: "Nie pozwalaj na wypłatę z bankomatu", value: false },
        { label: "Pozwól na wypłatę z bankomatu", value: true },
      ],
      activeAtmWithdrawChoice: null,
      mbaNetworkErrors: [],
      networkTypesLoading: true,
      initialized: false,
    };
  },
  computed: {
    ...mapState("nof", ["activeNofContract"]),
    ...mapGetters("orders", ["getActiveCardOrderId", "getCardTypeId"]),
    ...mapState("orders", ["activeForm"]),
    ...mapState("user", ["userContract"]),
    cardTypes() {
      // if it's nof
      if (this.activeNofContract) {
        return this.activeNofContract.card_types;
      }
      return this.userContract.card_types;
    },
    canChangeAtmWithdrawal() {
      const { cardTypes, getCardTypeId } = this;
      if (!getCardTypeId || !cardTypes) {
        return false;
      }
      const cardType = cardTypes.find((type) => type.id === getCardTypeId);
      const { loop_restriction_type, pin, atm_withdraw } = cardType;
      return (
        atm_withdraw &&
        (loop_restriction_type === "mba" ||
          loop_restriction_type === "flexiloop") &&
        pin
      );
    },
    hasValidationErrors() {
      const { allow_atm_withdrawal } = this.activeForm;
      if (this.canChangeAtmWithdrawal && allow_atm_withdrawal === null) {
        return true;
      }

      if (this.mbaNetworkTypes.length && !this.activeMbaNetworkType) {
        return true;
      }

      return false;
    },
  },
  watch: {
    getActiveCardOrderId() {
      this.setMbaNetworkTypes();
    },
    getCardTypeId() {
      this.setMbaNetworkTypes();
    },
    activeAtmWithdrawChoice(choice) {
      this.errors = [];
      this.updateOrder({ allow_atm_withdrawal: choice });
      this.updateBillingData();
    },
    hasValidationErrors(hasErrors) {
      if (!this.initialized) {
        return;
      }

      if (hasErrors) {
        this.$emit("form-error");
        return;
      }
      this.$emit("form-success");
    },
  },
  async beforeMount() {
    const {
      activeForm: { allow_atm_withdrawal },
    } = this;
    this.activeAtmWithdrawChoice = allow_atm_withdrawal;
    await this.setMbaNetworkTypes();
    if (!this.hasValidationErrors) {
      this.$emit("form-success");
    }
    this.initialized = true;
  },
  methods: {
    ...mapActions("orders", [
      "getMbaNetworkTypes",
      "setMbaNetworkType",
      "updateBillingData",
    ]),
    async setMbaNetworkTypes() {
      try {
        const results = await this.getMbaNetworkTypes();
        this.mbaNetworkTypes = results;
        this.activeMbaNetworkType = results.find((v) => v.selected)?.id;
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.mbaNetworkErrors = errors;
      } finally {
        this.networkTypesLoading = false;
      }
    },
    async handleNetworkTypeUpdate(mbaId) {
      const { setMbaNetworkType } = this;
      this.updatingOrder = true;

      try {
        await setMbaNetworkType({ selected: true, id: mbaId });
        this.updateBillingData();
        this.mbaNetworkErrors = [];
        this.activeMbaNetworkType = mbaId;
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.mbaNetworkErrors = errors;
      }
      this.updatingOrder = false;
    },
    beforeComponentChange() {
      if (this.hasValidationErrors) {
        throw new FormValidationError(
          "MbaNetwork dynamic list validation error",
        );
      }
    },
  },
};
</script>

<style scoped>
.label-checkbox {
  margin-bottom: 10px;
}

.mba-radio {
  margin-bottom: 25px;
}
</style>
