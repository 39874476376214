import _debounce from "lodash.debounce";

export const subscribeViewport = ({ commit, state: { breakpoints } }) => {
  const setViewportData = () => {
    const { innerWidth, innerHeight } = window;
    commit("setWidth", innerWidth);
    commit("setHeight", innerHeight);

    const viewportTypes = ["xs", "sm", "md", "lg"];

    const viewport = {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      gt: {
        xs: false,
        sm: false,
        md: false,
        lg: false,
      },
      lt: {
        xs: false,
        sm: false,
        md: false,
        lg: false,
      },
    };

    const getViewportName = (width) => {
      let viewportName;
      if (width >= breakpoints.md) {
        viewportName = "lg";
      } else if (width >= breakpoints.sm) {
        viewportName = "md";
      } else if (width >= breakpoints.xs) {
        viewportName = "sm";
      } else {
        viewportName = "xs";
      }
      return viewportName;
    };

    const viewportName = getViewportName(innerWidth);
    viewport[viewportName] = true;
    viewport.name = viewportName;

    const otherViewportsNames = viewportTypes.filter((v) => v !== viewportName);
    otherViewportsNames.forEach((v) => {
      viewport[v] = false;
    });

    const viewportIndex = viewportTypes.indexOf(viewportName);

    const lowerViewports = viewportTypes.slice(0, viewportIndex);
    lowerViewports.forEach((v) => {
      viewport.gt[v] = true;
    });

    const higherViewports = viewportTypes.slice(
      viewportIndex + 1,
      viewportTypes.length,
    );
    higherViewports.forEach((v) => {
      viewport.lt[v] = true;
    });
    commit("setViewport", viewport);
  };

  setViewportData();

  const debouncedSetViewportData = _debounce(setViewportData, 200);

  window.addEventListener("resize", debouncedSetViewportData);
};
