<template>
  <div>
    <info-box
      >Jeśli chcesz zmodyfikować list przewodni do indywidualnych potrzeb
      zaznacz opcję poniżej, a my skontaktujemy się z Tobą w sprawie szczegółów
      po złożeniu zamówienia.</info-box
    >
    <input-radio-group
      v-model="hasCarrier"
      :items="carrierChoices"
      name="has_card_carrier_radio"
      :active-radio="hasCarrier"
      :disabled="updatingOrder"
    />
    <loading-indicator :loading="updatingOrder" />
    <error-item :errors="errors" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { FormValidationError } from "@/utils/errors";

import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";
import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";

import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

import updateOrderMixin from "@/mixins/updateOrderMixin";

export default {
  name: "CardCarrier",
  components: { InputRadioGroup, LoadingIndicator, InfoBox },
  mixins: [updateOrderMixin],
  data() {
    return {
      hasCarrier: null,
      carrierChoices: [
        { label: "Karta bez indywidualnego listu przewodniego", value: false },
        { label: "Karta z indywidualnym listem przewodnim", value: true },
      ],
    };
  },
  computed: {
    ...mapState("orders", ["activeForm"]),
    hasValidationErrors() {
      const { has_card_carrier } = this.activeForm;
      if (has_card_carrier === null) {
        return true;
      }
      return false;
    },
  },
  watch: {
    async hasCarrier(value) {
      await this.updateOrder({ has_card_carrier: value });
      this.updateBillingData();
    },
    hasValidationErrors(hasErrors) {
      if (hasErrors) {
        this.$emit("form-error");
        return;
      }
      this.$emit("form-success");
    },
  },
  beforeMount() {
    const {
      activeForm: { has_card_carrier },
    } = this;
    this.hasCarrier = has_card_carrier;

    if (!this.hasValidationErrors) {
      this.$emit("form-success");
    }
  },
  methods: {
    ...mapActions("orders", ["updateBillingData"]),
    beforeComponentChange() {
      if (this.hasValidationErrors) {
        throw new FormValidationError(
          "CardCarrier dynamic list validation error",
        );
      }
    },
  },
};
</script>
