<template>
  <ordering-person-form
    ref="orderingPersonRef"
    :query-params="`card_order=${getActiveCardOrderId}`"
    :form-data-object="getActiveFormOrderingPerson"
    @form-success="handleFormSuccess"
    @request-update="reloadActiveFormData"
    @is-dirty="$emit('is-dirty')"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import OrderingPersonForm from "@/views/components/formFields/ordering_person/OrderingPersonForm.vue";

export default {
  name: "CardOrderOrderingPerson",
  components: { OrderingPersonForm },
  computed: {
    ...mapGetters("orders", [
      "getActiveCardOrderId",
      "getActiveFormOrderingPerson",
    ]),
  },
  methods: {
    ...mapActions("orders", ["reloadActiveFormData"]),
    // used by DynamicList.vue component
    async beforeComponentChange() {
      const {
        $refs: {
          orderingPersonRef: { beforeComponentChange },
        },
      } = this;
      if (beforeComponentChange) {
        await beforeComponentChange();
      }
    },
    async handleFormSuccess($event) {
      this.$emit("form-success", $event);
    },
  },
};
</script>
