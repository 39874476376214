<template>
  <div>
    <h2 v-if="showOrderAgreements" class="agreements__title step-form__title">
      Oświadczenia i zgody
    </h2>
    <loading-indicator v-if="loading" :loading="loading" />
    <template v-else>
      <agreements-order
        :show-order-agreements.sync="showOrderAgreements"
        :active-order="activeOrder"
        @agreement-checked="handleOrderAgreementChecked"
        @fetch-error="($event) => $emit('fetch-error', $event)"
      />
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import AgreementsOrder from "@/views/components/formFields/agreements/AgreementsOrder.vue";

import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

export default {
  name: "TheAgreements",
  components: {
    AgreementsOrder,
    LoadingIndicator,
  },
  props: {
    orderAgreements: {
      type: Object,
      default() {
        return {};
      },
    },
    activeOrder: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOrderAgreements: false,
      localOrderAgreements: { ...this.orderAgreements },
    };
  },
  computed: {
    orderBoundToReloadOrder() {
      const {
        activeOrder: { order },
      } = this;
      return order;
    },
  },
  methods: {
    ...mapActions("orders", ["updateBillingData", "patchOrder"]),
    ...mapActions("reloads", ["updateReloadBillingData"]),
    async handleOrderAgreementChecked({ agreement, value }) {
      const localOrderAgreements = { ...this.localOrderAgreements };
      localOrderAgreements[agreement] = value;
      this.localOrderAgreements = localOrderAgreements;
      // needed to update VAT tax
      if (agreement === "non_polish") {
        if (this.orderBoundToReloadOrder) {
          // reload order
          await this.patchOrder({
            orderId: this.orderBoundToReloadOrder.id,
            cardReloadOrderId: this.activeOrder.id,
            data: { [agreement]: value },
          });
          this.updateReloadBillingData({ isBillingFinal: true });
        } else {
          await this.patchOrder({
            orderId: this.activeOrder.id,
            cardOrderId: this.activeOrder.card_order,
            data: { [agreement]: value },
          });
          this.updateBillingData({ isBillingFinal: true });
        }
      }
      this.$emit("update:orderAgreements", { ...this.localOrderAgreements });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/_breakpoints.scss";

.agreements__title {
  margin-top: 50px;

  @include breakpoint-mobile {
    margin-top: 35px;
  }
}
</style>
