<template>
  <div>
    <info-box>
      Jeśli chcesz zmodyfikować list przewodni dołączony do karty, zaznacz opcję
      poniżej. Po złożeniu zamówienia skontaktujemy się, w celu ustalenia
      szczegółów.
    </info-box>

    <input-radio-group
      v-model="activeLocation"
      :items="insertLocations"
      name="insert_location_choice"
      :active-radio="activeLocation"
      :disabled="updatingOrder"
      label-key="display_name"
      @input="handleLocationChange"
    />

    <file-upload
      v-if="activeLocation !== 'none' && activeLocation !== null"
      class="file-uploader"
      file-upload-type="textDocument"
      field-name="insert_file"
      http-method="PATCH"
      :url-to-upload="fileUploadUrl"
      :files="uploadedFiles"
      @file-removed="onFileRemoved"
      @file-uploaded-successfully="fileUploadErrors = {}"
      @file-upload-error="handleFileUploadError"
    />
    <error-item :errors="fileUploadErrors" />

    <loading-indicator :loading="updatingOrder || !insertLocations.length" />
    <error-item :errors="errors" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

import updateOrderMixin from "@/mixins/updateOrderMixin";

import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";
import FileUpload from "@/views/components/formFields/FileUpload.vue";

import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";
import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";

import { fetchFromApi } from "@/utils/api";
import { obtainFetchError, FormValidationError } from "@/utils/errors";

import { cardOrderAPIPoint } from "@/apiEndpoints";

export default {
  name: "TheInsert",
  components: {
    FileUpload,
    LoadingIndicator,
    InfoBox,
    InputRadioGroup,
  },
  mixins: [updateOrderMixin],
  data() {
    return {
      insertLocations: [],
      activeLocation: null,
      uploadedFiles: [],
      fileUploadErrors: {},
    };
  },
  computed: {
    ...mapGetters("orders", ["getActiveCardOrderId"]),
    ...mapState("nof", ["activeNofContract"]),
    ...mapState("orders", ["cardOrderOptions", "activeForm"]),
    ...mapState("user", ["userContract"]),
    fileUploadUrl() {
      const { getActiveCardOrderId } = this;
      return `${cardOrderAPIPoint}${getActiveCardOrderId}/`;
    },
    hasValidationErrors() {
      const { insert_location } = this.activeForm;
      if (insert_location === null) {
        return true;
      }
      return false;
    },
  },
  watch: {
    "activeForm.has_etui": {
      handler() {
        this.setChoices();
      },
    },
    hasValidationErrors(hasErrors) {
      if (hasErrors) {
        this.$emit("form-error");
        return;
      }
      this.$emit("form-success");
    },
  },
  async mounted() {
    const {
      activeForm: { insert_file },
    } = this;
    if (insert_file) {
      this.uploadedFiles = [
        {
          source: insert_file,
          options: {
            type: "local",
          },
        },
      ];
    }

    await this.setChoices();

    if (!this.hasValidationErrors) {
      this.$emit("form-success");
    }
  },
  methods: {
    ...mapActions("orders", ["updateBillingData"]),
    async clearInsertFile() {
      const { fileUploadUrl } = this;

      const formData = new FormData();
      formData.append("insert_file", "");
      try {
        await fetchFromApi(fileUploadUrl, {
          method: "PATCH",
          body: formData,
        });
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      }
    },
    onFileRemoved() {
      this.clearInsertFile();
    },
    sortChoicesAccordingToSpec(choices) {
      // moves element with value 'none' to the start of the array
      const oldIndex = choices.findIndex((el) => el.value === "none");
      if (oldIndex === -1) {
        return choices;
      }
      const newIndex = 0;
      const choicesCopy = [...choices];
      choicesCopy.splice(newIndex, 0, choicesCopy.splice(oldIndex, 1)[0]);
      return choicesCopy;
    },
    filterChoicesBasedOnEtui(choices) {
      const canAddEtui = this.activeNofContract
        ? this.activeNofContract.can_add_etui
        : this.userContract.can_add_etui;

      if (canAddEtui && this.activeForm.has_etui) {
        return choices;
      }
      return choices.filter((choice) => choice.value !== "etui");
    },
    async setChoices() {
      const {
        activeForm: { insert_location },
      } = this;

      const {
        actions: {
          POST: {
            insert_location: { choices },
          },
        },
      } = this.cardOrderOptions;
      const sortedChoices = this.sortChoicesAccordingToSpec(choices);
      this.insertLocations = this.filterChoicesBasedOnEtui(sortedChoices);

      if (
        // if location is set to value which was filtered out
        insert_location !== null &&
        !this.insertLocations.some(
          (location) => location.value === insert_location,
        )
      ) {
        const defaultValue = this.insertLocations[0].value;
        this.activeLocation = defaultValue;
        // clears file
        await this.handleLocationChange("none");
        // sets default value
        this.handleLocationChange(defaultValue);
      } else if (this.activeLocation !== insert_location) {
        this.activeLocation = insert_location;
      }
    },
    async handleLocationChange(newLocation) {
      if (newLocation === "none") {
        // clear filepond component
        this.uploadedFiles = [];
        this.fileUploadErrors = {};
      }
      await this.updateOrder({ insert_location: newLocation });
      await this.updateBillingData();
    },
    handleFileUploadError(errors) {
      this.fileUploadErrors = errors;
      this.uploadedFiles = [];
    },
    beforeComponentChange() {
      if (this.hasValidationErrors) {
        throw new FormValidationError("Insert dynamic list validation error");
      }
    },
  },
};
</script>

<style scoped>
.file-uploader {
  margin-top: 18px;
}
</style>
