import { cardReloadOrderAPIPoint, reloadedCardAPIPoint } from "@/apiEndpoints";
import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";

export default {
  async reloadActiveReloadFormData({ state: { activeReloadOrderId }, commit }) {
    const activeReloadFormData = await fetchFromApi(
      cardReloadOrderAPIPoint + activeReloadOrderId,
    );
    commit("setActiveReloadFormData", activeReloadFormData);
  },
  async updateCardReloadAmount(
    { state: { activeReloadOrderId } },
    { cardId, amount },
  ) {
    const url = `${reloadedCardAPIPoint}${cardId}/?card_reload_order=${activeReloadOrderId}`;
    return await fetchFromApi(url, {
      method: "PATCH",
      data: {
        amount,
      },
    });
  },
  async deleteCardToReload({ state: { activeReloadOrderId } }, cardId) {
    const url = `${reloadedCardAPIPoint}${cardId}/?card_reload_order=${activeReloadOrderId}`;
    return await fetchFromApi(url, {
      method: "DELETE",
    });
  },
  async getCardReloadOrderCards(
    { state: { activeReloadOrderId } },
    { page = 1 } = {},
  ) {
    const url = `${reloadedCardAPIPoint}?card_reload_order=${activeReloadOrderId}&page=${page}`;
    return await fetchFromApi(url);
  },
  async patchActiveCardReloadOrder(
    { getters: { getActiveCardReloadOrderApiPoint } },
    data,
  ) {
    return await fetchFromApi(getActiveCardReloadOrderApiPoint, {
      method: "PATCH",
      data,
    });
  },
  async submitActiveReloadOrder({
    getters: { getActiveCardReloadOrderApiPoint },
  }) {
    return await fetchFromApi(
      `${getActiveCardReloadOrderApiPoint}submit_order/`,
      {
        method: "POST",
      },
    );
  },
  async loadActiveReloadOrder({
    commit,
    getters: { getActiveCardReloadOrderApiPoint },
  }) {
    const activeOrder = await fetchFromApi(getActiveCardReloadOrderApiPoint, {
      method: "GET",
    });
    commit("setActiveReloadOrder", activeOrder);
    return activeOrder;
  },
  async validateCardReloadOrder({ state: { activeReloadOrderId } }) {
    const url = `${reloadedCardAPIPoint}validate/?card_reload_order=${activeReloadOrderId}`;
    return await fetchFromApi(url);
  },
  async handleNextReloadFormStep({
    commit,
    state: { activeReloadFormStep, reloadFormSteps },
  }) {
    if (activeReloadFormStep + 1 < reloadFormSteps.length) {
      commit("goToNextReloadFormStep");
      window.scrollTo(0, 0);
    }
  },
  async handlePreviousReloadFormStep({
    commit,
    state: { activeReloadFormStep },
  }) {
    if (activeReloadFormStep > 0) {
      commit("goToPreviousReloadFormStep");
      window.scrollTo(0, 0);
    }
  },
  async updateReloadBillingData(
    {
      commit,
      state: {
        activeReloadForm: { id: activeReloadFormId },
      },
    },
    { isBillingFinal = false } = {},
  ) {
    commit("setActiveReloadFormBillingLoading", true);
    commit("setActiveReloadFormBillingReloadError", null);

    try {
      const apiEndpoint = `${cardReloadOrderAPIPoint}${activeReloadFormId}/billing/?verbose=true`;
      const billingResponse = await fetchFromApi(
        `${apiEndpoint}${isBillingFinal ? "&final=true" : ""}`,
      );

      commit("setActiveReloadFormBilling", {
        billingArray: billingResponse.billing,
        isBillingFinal,
      });
    } catch (errorObj) {
      const { errors } = obtainFetchError(errorObj);
      commit("setActiveReloadFormBillingReloadError", errors);
    } finally {
      commit("setActiveReloadFormBillingLoading", false);
    }
  },
  async resetReloadBillingData({ commit }) {
    commit("setActiveReloadFormBillingLoading", false);
    commit("setActiveReloadFormBillingReloadError", null);
    commit("setActiveReloadFormBilling", {
      billingArray: [],
      isBillingFinal: false,
    });
  },
};
