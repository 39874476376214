import { getDefaultState } from "./state";

export default {
  resetNofState(state) {
    Object.assign(state, getDefaultState());
  },
  updateActiveFormInvoiceOptions(state, payload) {
    state.activeFormInvoiceOptions = {
      ...state.activeFormInvoiceOptions,
      ...payload,
    };
  },
  goToNextFormStep(state) {
    state.activeFormStep++;
  },
  goToPreviousFormStep(state) {
    state.activeFormStep--;
  },
  setActiveFormStep(state, step) {
    state.activeFormStep = step;
  },
  setActiveNofContract(state, contractObj) {
    state.activeNofContract = contractObj;
  },
  setNofOrderNotAuthorizedError(state, error) {
    state.nofOrderNotAuthorizedError = error;
  },
};
