<template>
  <button
    :type="type"
    :class="['button', secondary ? 'button--secondary' : 'button--primary']"
    :disabled="isDisabled"
    @click="$emit('click')"
  >
    <slot v-if="!loading"></slot>
    <loading-indicator :loading="loading" />
  </button>
</template>

<script>
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

export default {
  name: "ActionButton",
  components: { LoadingIndicator },
  props: {
    secondary: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: "button" },
  },
  computed: {
    isDisabled() {
      return this.loading || this.disabled;
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  border-radius: $button-radius;
  border: 1px solid $color-primary;
  font-size: $basic-font-size;
  font-weight: $font-weight-bold;
  padding: 12px 35px;
  text-align: center;
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
  &:disabled,
  &.disabled {
    background-color: $disabled-button-color;
    border-color: $disabled-button-color;
    color: white;
    pointer-events: none;
  }

  &--primary {
    background-color: $color-primary;
    color: white;
  }
  &--secondary {
    background-color: white;
    color: $color-primary;
  }
}
</style>
