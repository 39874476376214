<template>
  <div class="step step--with-stepper">
    <h2 class="step-form__title">Dane</h2>
    <dynamic-list
      ref="dynamicStepList"
      :steps="steps"
      :loading.sync="loading"
    />
    <div class="buttons-container">
      <action-button secondary @click="handlePreviousFormStep">
        Wstecz
      </action-button>
      <action-button :loading="loading" @click="handleGoToNextStep">
        Dalej
      </action-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import DynamicList from "@/views/components/formFields/DynamicList.vue";

import NofOrderInvoiceDetails from "@/views/nof/NofOrderInvoiceDetails.vue";
import NofAddressForm from "@/views/nof/NofAddressForm.vue";

import OrderInvoiceRecipientDetails from "@/views/components/formFields/invoice_details/OrderInvoiceRecipientDetails.vue";
import CardOrderOrderingPerson from "@/views/components/formFields/ordering_person/CardOrderOrderingPerson.vue";
import CardOrderAdditionalDetails from "@/views/components/formFields/additional_details/CardOrderAdditionalDetails.vue";

import iconCardInvoice from "@/images/icon-card-invoice.svg";
import iconCard from "@/images/icon-card.svg";
import iconUser from "@/images/icon-user.svg";
import iconRecipient from "@/images/icon-recipient.svg";
import iconMoreData from "@/images/icon-user-more.svg";

export default {
  name: "NofMultistepFormStep2",
  components: { DynamicList },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("nof", ["getActiveFormInvoiceOptions"]),
    ...mapGetters("orders", ["getActiveFormOrderingPerson"]),
    steps() {
      return [
        {
          icon: iconCardInvoice,
          name: "Dane do wysyłki",
          component: NofAddressForm,
        },
        {
          icon: iconCard,
          name: "Dane do faktury",
          component: NofOrderInvoiceDetails,
        },
        {
          icon: iconUser,
          name: "Osoba składająca zamówienie",
          component: CardOrderOrderingPerson,
        },
        {
          icon: iconRecipient,
          name: "Dane odbiorcy",
          component: OrderInvoiceRecipientDetails,
          hide: !this.getActiveFormInvoiceOptions.showRecipient,
        },
        {
          icon: iconMoreData,
          name: "Informacja o rachunku bankowym",
          component: CardOrderAdditionalDetails,
        },
      ];
    },
  },
  methods: {
    ...mapActions("nof", ["handleNextFormStep", "handlePreviousFormStep"]),
    async handleGoToNextStep() {
      this.loading = true;
      try {
        await this.$refs.dynamicStepList.validate();
        this.handleNextFormStep();
      } catch (error) {
        if (error.name !== "DynamicListValidationError") {
          throw error;
        }
        console.error({
          message: "Nof form step 2 dynamic list validation error",
          error,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
