<template>
  <div class="contact-person">
    <div class="contact-person__left">
      <img
        class="contact-person__icon"
        src="@/images/icon-contact-person.svg"
        alt=""
      />
    </div>
    <div class="contact-person__right">
      <p
        v-if="manager.first_name || manager.last_name"
        class="contact-person__name"
      >
        {{ manager.first_name }} {{ manager.last_name }}
      </p>
      <p class="contact-person__mail">
        <a v-if="manager.email" :href="`mailto:${manager.email}`">
          {{ manager.email }}
        </a>
      </p>
      <a
        v-if="manager.phone"
        :href="`tel:${manager.phone}`"
        class="contact-person__phone"
      >
        tel.: {{ manager.phone }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppContactPersonListItem",
  props: {
    manager: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-person {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.contact-person__left {
  flex-shrink: 0;
  margin-right: 11px;
}

.contact-person__right {
  p {
    margin: 0;
    line-height: 1.3;
    margin-bottom: 1rem;

    &:first-child {
      margin-top: 4px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    display: inline-block;
    color: inherit;
  }
}

.contact-person__name {
  font-weight: $font-weight-bolder;
}

.contact-person__mail {
  margin-bottom: 1rem;

  a {
    text-decoration: underline;
  }
}
</style>
