<template>
  <div class="address-wrapper">
    <div class="columns">
      <div class="column">
        <h2 class="step-form__title">Adresy do wysyłki</h2>
        <p class="step-form__subtitle">
          Możesz dodać 1 lub&nbsp;więcej adresów wysyłki. Jeżeli dodajesz wiele
          adresów dostaw, zadbaj aby pole Nazwa firmy było unikalne
          (np.&nbsp;Oddział Warszawa). W&nbsp;takim wypadku w&nbsp;polu Nazwa
          alternatywna możesz podać faktyczną nazwę firmy, na&nbsp;jaką
          zaadresowana będzie przesyłka.
        </p>
        <input-text-select
          v-model="newAddress.name"
          :errors="errors.name"
          :items="clientDeliveryAddresses"
          item-label="name"
          required
          @item-selected="handleSavedDeliveryAddressSelect"
        >
          Nazwa firmy
        </input-text-select>
        <text-input v-model="newAddress.name_alt" :errors="errors.name_alt">
          Nazwa alternatywna
        </text-input>
        <input-select-country
          v-model="newAddress.country"
          class="form-group"
          :errors="errors.country"
        />
        <text-input
          v-model="newAddress.address"
          :errors="errors.address"
          required
        >
          Adres
        </text-input>
        <div class="form-group-double">
          <text-input
            v-model="newAddress.zip_code"
            :errors="errors.zip_code"
            required
          >
            Kod pocztowy
          </text-input>
          <text-input v-model="newAddress.city" :errors="errors.city" required>
            Miasto
          </text-input>
        </div>
        <div>
          <text-input
            v-model="newAddress.authorized_person_email"
            :errors="errors.authorized_person_email"
            required
          >
            E-mail do wysyłki pliku z nr kart
          </text-input>
        </div>
      </div>
      <div class="column">
        <h2 class="step-form__title">Osoba upoważniona do odbioru przesyłki</h2>
        <p class="step-form__subtitle">
          Tylko ta&nbsp;osoba otrzyma sms z PIN-em do&nbsp;odbioru paczki
          z&nbsp;kartami.
        </p>
        <div class="first-person">
          <text-input
            v-model="newAddress.authorized_person_first_name"
            :errors="errors.authorized_person_first_name"
            required
          >
            Imię
          </text-input>
        </div>
        <div class="first-person">
          <text-input
            v-model="newAddress.authorized_person_last_name"
            :errors="errors.authorized_person_last_name"
            required
          >
            Nazwisko
          </text-input>
        </div>
        <div class="first-person">
          <text-input
            v-model="newAddress.authorized_person_phone"
            :errors="errors.authorized_person_phone"
            required
          >
            Numer telefonu komórkowego
          </text-input>
        </div>
      </div>
    </div>
    <error-item :errors="errors.non_field_errors" />

    <div class="buttons-container--center">
      <action-button
        v-if="canAddAddress"
        :disabled="loadingAddedAddress"
        :loading="loadingAddedAddress"
        secondary
        @click="addAddress"
      >
        {{ getCurrentAddressesLength ? "Dodaj kolejny adres" : "Dodaj adres" }}
      </action-button>
    </div>
  </div>
</template>

<script>
import _isEqual from "lodash.isequal";
import { mapGetters, mapActions } from "vuex";

import { addressAPIPoint } from "@/apiEndpoints";
import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";
import { formObjectHasValues } from "@/utils/utilityFunctions";

import TextInput from "@/views/components/formFields/TextInput.vue";
import InputTextSelect from "@/views/components/formFields/InputTextSelect.vue";
import InputSelectCountry from "@/views/components/formFields/InputSelectCountry.vue";

export default {
  name: "AddressInput",
  components: { InputTextSelect, TextInput, InputSelectCountry },
  data() {
    return {
      initialAddress: {},
      newAddress: {},
      loadingAddedAddress: false,
      errors: {},
    };
  },
  computed: {
    ...mapGetters("user", ["clientDeliveryAddresses"]),
    ...mapGetters("orders", [
      "getActiveCardOrderId",
      "getCurrentAddressesLength",
      "getActiveProduct",
    ]),
    isDirty() {
      const { newAddress, initialAddress } = this;
      return (
        !_isEqual(newAddress, initialAddress) && formObjectHasValues(newAddress)
      );
    },
    canAddMultipleAddresses() {
      return this.getActiveProduct?.multiple_addresses_available || false;
    },
    canAddAddress() {
      return this.canAddMultipleAddresses || this.getCurrentAddressesLength < 1;
    },
  },
  watch: {
    isDirty(val) {
      if (val) {
        this.$emit("is-dirty", true);
      } else {
        this.$emit("is-dirty", false);
      }
    },
  },
  methods: {
    ...mapActions("orders", ["reloadActiveFormData", "updateBillingData"]),
    async addAddress() {
      if (!this.canAddAddress) {
        return;
      }

      const {
        getActiveCardOrderId,
        newAddress,
        reloadActiveFormData,
        updateBillingData,
      } = this;

      const addressPayload = {
        ...newAddress,
      };

      this.loadingAddedAddress = true;
      try {
        await fetchFromApi(
          `${addressAPIPoint}?card_order=${getActiveCardOrderId}`,
          {
            method: "POST",
            data: {
              card_order: getActiveCardOrderId,
              ...addressPayload,
            },
          },
        );

        this.newAddress = {};
        this.errors = {};
        await reloadActiveFormData();
        await updateBillingData();
        this.$emit("address-changed");
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
        return errors;
      } finally {
        this.loadingAddedAddress = false;
      }
    },
    handleSavedDeliveryAddressSelect(addressObj) {
      this.newAddress = {
        ...addressObj,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_mixins.scss";
@import "~@/scss/_breakpoints.scss";

.columns {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  @include breakpoint-mobile {
    flex-direction: column;
  }
}

.column {
  width: 50%;
  padding: 0 5%;
  @include breakpoint-mobile {
    width: 100%;
    padding: 40px 0 0;
  }
}

.address-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.first-person {
  position: relative;
  &::after {
    font-size: $label-font-size;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
