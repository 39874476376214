<template>
  <fieldset class="card-type-form">
    <div class="description">
      <h2 class="step-form__title">Typ karty</h2>
    </div>
    <div v-if="options !== null">
      <div v-for="(option, i) of options" :key="`option-${i}`" class="radio">
        <label class="label-block label-radio">
          <input
            :id="`option-${i}`"
            v-model="value"
            type="radio"
            :value="option.id"
            @change="handleChange"
          />
          {{ option.public_name || option.name }}
        </label>
      </div>
    </div>
    <loading-indicator :loading="options === null || updatingOrder" />
    <error-item :errors="errors" />
  </fieldset>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";
import updateOrderMixin from "@/mixins/updateOrderMixin";

export default {
  name: "CardType",
  components: { LoadingIndicator },
  mixins: [updateOrderMixin],
  data() {
    return {
      value: null,
      options: null,
      errors: {},
    };
  },
  computed: {
    ...mapGetters("orders", ["getCardTypeId", "getActiveProductTypeId"]),
  },
  watch: {
    getActiveProductTypeId: {
      handler() {
        this.getContractCards();
      },
      immediate: true,
    },
  },
  created() {
    this.value = this.getCardTypeId;
  },
  beforeMount() {
    this.getContractCards();
  },
  methods: {
    ...mapActions("orders", ["getAvailableCardTypes", "updateBillingData"]),
    async getContractCards() {
      const { getAvailableCardTypes } = this;
      const cardTypes = await getAvailableCardTypes();
      const { available_cards } = cardTypes;
      this.options = available_cards;
      this.$emit("cards-loaded");
    },
    async handleChange() {
      await this.updateOrder({ card_type: this.value });
      this.updateBillingData();
    },
  },
};
</script>

<style scoped lang="scss">
.card-type-form {
  border-width: 0;
  padding: 0;
  margin: 0;
}

.label-block {
  display: block;
  margin-bottom: 10px;
}

.label-radio {
  display: flex;
  align-items: flex-start;
  font-size: $radio-font-size;

  input {
    flex-shrink: 0;
  }
}
</style>
