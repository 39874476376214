<template>
  <div class="excel-order">
    <h2 class="step-form__title">Wybierz sposób wypełnienia formularza</h2>

    <input-radio-group
      v-model="isExcelSelected"
      :items="excelSelectItems"
      name="excel-select-radio-btns"
      :active-radio="isExcelSelected"
      :disabled="loading"
    />

    <template v-if="isExcelSelected">
      <info-box class="info-box">
        <slot>
          Aby złożyć zamówienie na podstawie pliku Excel
          <a class="link text-bold" :href="ExcelOrderTemplate" target="_blank"
            >pobierz szablon</a
          >
          i uzupełnij go zgodnie z instrukcją. Następnie dołącz plik poniżej.
        </slot>
      </info-box>

      <file-upload
        v-show="isExcelSelected"
        class="file-uploader"
        file-upload-type="excel"
        field-name="import_source_file"
        http-method="PATCH"
        :timeout="undefined"
        :url-to-upload="fileUploadUrl"
        :files="uploadedFiles"
        @file-removed="onFileRemoved"
        @file-uploaded-successfully="handleFileUploadSuccess"
        @file-upload-error="handleFileUploadError"
      />

      <error-item :errors="fileUploadErrors" />
    </template>

    <loading-indicator :loading="loading" />
    <error-item :errors="errors" />

    <popup-excel-success
      v-if="showExcelSuccess"
      :success-data="successData"
      @popup-close="handleExcelOrderSuccess"
    />

    <popup-excel-error
      v-if="showExcelError"
      :errors-data="excelErrors"
      @popup-close="handleErrorPopupClose"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

import updateOrderMixin from "@/mixins/updateOrderMixin";

import FileUpload from "@/views/components/formFields/FileUpload.vue";
import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";

import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";
import PopupExcelSuccess from "@/views/components/personalizations/ExcelOrder/PopupExcelSuccess.vue";
import PopupExcelError from "@/views/components/personalizations/ExcelOrder/PopupExcelError.vue";

import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";

import { cardOrderAPIPoint } from "@/apiEndpoints";

import ExcelOrderTemplate from "@/xlsx/specyfikacja_dostaw.xlsx";

export default {
  name: "ExcelOrder",
  components: {
    FileUpload,
    LoadingIndicator,
    InfoBox,
    InputRadioGroup,
    PopupExcelSuccess,
    PopupExcelError,
  },
  mixins: [updateOrderMixin],
  props: {
    // controlls isExcelSelected value - see isExcelSelected computed
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ExcelOrderTemplate,
      excelSelectItems: [
        { label: "Kontynuuj z użyciem tego formularza", value: false },
        { label: "Zamówienie na podstawie pliku Excel", value: true },
      ],
      uploadedFiles: [],
      loading: false,
      showExcelSuccess: false,
      successData: {},
      showExcelError: false,
      excelErrors: {},
      fileUploadErrors: {},
    };
  },
  computed: {
    ...mapGetters("orders", ["getActiveCardOrderId", "isVirtualProductActive"]),
    ...mapState("orders", ["activeForm"]),
    fileUploadUrl() {
      const { getActiveCardOrderId } = this;
      return `${cardOrderAPIPoint}${getActiveCardOrderId}/`;
    },
    isExcelSelected: {
      get() {
        return this.value;
      },
      set(isSelected) {
        this.$emit("input", isSelected);
      },
    },
  },
  watch: {
    async isExcelSelected(isSelected) {
      const { clearFile } = this;
      if (!isSelected) {
        await clearFile();
        this.uploadedFiles = [];
      }
    },
  },
  async mounted() {
    const {
      activeForm: { import_source_file },
    } = this;

    if (import_source_file) {
      this.isExcelSelected = true;
      this.uploadedFiles = [
        {
          source: import_source_file,
          options: {
            type: "local",
          },
        },
      ];
    }
  },
  methods: {
    ...mapActions("orders", [
      "handleExcelOrder",
      "reloadActiveFormData",
      "updateBillingData",
    ]),
    ...mapMutations("orders", ["setActiveFormStep"]),
    clearFile() {
      const { fileUploadUrl } = this;
      this.loading = true;
      const formData = new FormData();
      formData.append("import_source_file", "");
      try {
        fetchFromApi(fileUploadUrl, {
          method: "PATCH",
          body: formData,
          json: false,
        });
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      }
      this.loading = false;
    },
    onFileRemoved() {
      this.clearFile();
    },
    async handleFileUploadSuccess() {
      const {
        handleExcelOrder,
        clearFile,
        reloadActiveFormData,
        updateBillingData,
      } = this;
      this.fileUploadErrors = {};
      this.loading = true;
      try {
        const response = await handleExcelOrder({
          virtual: this.isVirtualProductActive,
        });
        this.excelErrors = {};
        const {
          created: { addresses_count, cards_count, summed_amount },
        } = response;
        this.successData = {
          addresses_count,
          cards_count,
          summed_amount,
        };
        await Promise.all([reloadActiveFormData(), updateBillingData()]);
        this.showExcelSuccess = true;
      } catch (errorObj) {
        // internal server error
        if (errorObj.status === 500) {
          this.fileUploadErrors = {
            non_field_errors: [
              "Wystąpił problem w czasie zapisywania danych na serwerze",
            ],
          };
          await clearFile();
          this.uploadedFiles = [];
          return;
        }

        const { errors } = obtainFetchError(errorObj);
        this.excelErrors = errors;
        this.showExcelError = true;
        await clearFile();
        this.uploadedFiles = [];
      }
      this.loading = false;
    },
    handleExcelOrderSuccess() {
      this.successData = {};
      this.showExcelSuccess = false;
      this.$emit("success");
    },
    async handleErrorPopupClose() {
      this.showExcelError = false;
      this.excelErrors = {};
    },
    handleFileUploadError(errorsObj) {
      this.fileUploadErrors = errorsObj;
      this.uploadedFiles = [];
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/scss/_breakpoints.scss";

.excel-order {
  max-width: 350px;
  box-sizing: content-box;
  margin-bottom: 64px;
}

.info-box {
  margin-top: 20px;
  margin-bottom: 17px;
}

@include breakpoint-mobile-up {
  .excel-order {
    padding: 0 5%;
  }
}
</style>
