<template>
  <div>
    <h2 class="step-form__title">Określ wielkość przedsiębiorstwa</h2>
    <div v-for="option in options" :key="`company-size-${option.id}`">
      <label>
        <input
          :id="option.name"
          :checked="value === option.id"
          name="pickedOption"
          type="radio"
          :value="option.id"
          @input="$emit('input', option.id)"
        />
        {{ option.name }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanySize",
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      options: [
        { id: 1, name: "do 9 pracowników" },
        { id: 2, name: "10 - 50 pracowników" },
        { id: 3, name: "51 - 200 pracowników" },
        { id: 4, name: "201 - 500 pracowników" },
        { id: 5, name: "> 500 pracowników" },
      ],
      pickedOption: null,
    };
  },
};
</script>
