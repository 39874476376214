<template>
  <div>
    <info-box>
      Jeśli chcesz zamieścić własny napis na karcie skorzystaj z tej opcji. W
      jednej linii możesz dodać maksymalnie 21 znaków.
    </info-box>

    <input-radio-group
      v-model="isCutomTextSelected"
      :items="items"
      name="radio-btns"
      :active-radio="isCutomTextSelected"
    />

    <div v-show="isCutomTextSelected" class="inputs-wrap">
      <text-input
        :value="form.inscription_line_1"
        maxlength="21"
        @input="(val) => (form.inscription_line_1 = uppercase(val))"
      >
        Linia 1
      </text-input>
      <error-item :errors="errors.inscription_line_1" />

      <template v-if="!showOnlyOneLine">
        <text-input
          :value="form.inscription_line_2"
          maxlength="21"
          @input="(val) => (form.inscription_line_2 = uppercase(val))"
        >
          Linia 2
        </text-input>
        <error-item :errors="errors.inscription_line_2" />
      </template>
    </div>

    <loading-indicator :loading="updatingOrder" />

    <error-item :errors="errors.non_field_errors" />
  </div>
</template>

<script>
import _debounce from "lodash.debounce";
import { mapGetters, mapActions, mapState } from "vuex";

import { FormValidationError } from "@/utils/errors";

import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";
import TextInput from "@/views/components/formFields/TextInput.vue";

import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";
import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";
import updateOrderMixin from "@/mixins/updateOrderMixin";

export default {
  name: "CustomText",
  components: {
    LoadingIndicator,
    InputRadioGroup,
    TextInput,
    InfoBox,
  },
  mixins: [updateOrderMixin],
  data() {
    return {
      items: [
        { label: "Karta bez napisu", value: false },
        { label: "Karta z napisem", value: true },
      ],
      isCutomTextSelected: null,
      form: {
        inscription_line_1: "",
        inscription_line_2: "",
      },
    };
  },
  computed: {
    ...mapState("orders", ["activeForm"]),
    ...mapGetters("orders", ["getActiveProduct"]),
    showOnlyOneLine() {
      const {
        getActiveProduct: { can_have_only_one_inscription_line },
      } = this;
      return can_have_only_one_inscription_line;
    },
    hasValidationErrors() {
      const { has_inscription } = this.activeForm;

      return has_inscription === null;
    },
  },
  watch: {
    async isCutomTextSelected(value) {
      await this.updateOrder({ has_inscription: value });
      this.updateBillingData();
    },
    form: {
      handler() {
        this.debouncedSendForm();
      },
      deep: true,
    },
    hasValidationErrors(hasErrors) {
      if (hasErrors) {
        this.$emit("form-error");
        return;
      }
      this.$emit("form-success");
    },
  },
  mounted() {
    const { inscription_line_1, inscription_line_2, has_inscription } =
      this.activeForm;
    this.form = {
      inscription_line_1,
      inscription_line_2,
    };
    this.isCutomTextSelected = has_inscription;

    if (!this.hasValidationErrors) {
      this.$emit("form-success");
    }
  },
  methods: {
    ...mapActions("orders", ["updateBillingData"]),
    async sendForm() {
      const { updateOrder, form, showOnlyOneLine } = this;
      if (!this.isCutomTextSelected) {
        return;
      }
      if (showOnlyOneLine) {
        await updateOrder({ inscription_line_1: form.inscription_line_1 });
        return;
      }
      await updateOrder({ ...form });
    },
    debouncedSendForm: _debounce(function () {
      this.sendForm();
    }, 200),
    uppercase(val) {
      if (!val) return "";
      return val.toUpperCase();
    },
    beforeComponentChange() {
      if (this.hasValidationErrors) {
        throw new FormValidationError(
          "CustomText dynamic list validation error",
        );
      }
    },
  },
};
</script>

<style scoped>
.inputs-wrap {
  margin-top: 28px;
}
</style>
