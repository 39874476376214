<template>
  <div v-if="Object.keys(orderAgreements).length">
    <agreement-item
      v-for="(agreement, agreementKey) in orderAgreements"
      :key="agreementKey"
      :agreement="[agreementKey, agreement.text]"
      :value="agreement.value"
      :has-checkbox="checkboxAgreements[agreementKey]"
      :required="agreement.required"
      :choices="agreement.choices"
      @agreement-checked="($event) => $emit('agreement-checked', $event)"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { obtainFetchError } from "@/utils/errors";

import AgreementItem from "@/views/components/formFields/AgreementItem.vue";

export default {
  name: "AgreementsOrder",
  components: {
    AgreementItem,
  },
  props: {
    showOrderAgreements: {
      type: Boolean,
      default: false,
    },
    activeOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orderAgreements: {},
      checkboxAgreements: {
        data_storage_agreement: true,
        terms_of_service: true,
        privacy_policy: true,
      },
    };
  },
  computed: {
    orderBoundToReloadOrder() {
      const {
        activeOrder: { order },
      } = this;
      return order;
    },
  },
  async mounted() {
    await this.getOrderAgreements();
  },
  methods: {
    ...mapActions("orders", ["fetchOrderOptions"]),
    async getOrderAgreements() {
      const { activeOrder, fetchOrderOptions, orderBoundToReloadOrder } = this;

      try {
        let orderOptions;
        if (orderBoundToReloadOrder) {
          // reload order
          orderOptions = await fetchOrderOptions({
            orderId: orderBoundToReloadOrder.id,
            cardReloadOrderId: activeOrder.id,
          });
        } else {
          orderOptions = await fetchOrderOptions({
            orderId: activeOrder.id,
            cardOrderId: activeOrder.card_order,
          });
        }

        const {
          agreements,
          actions: { PUT },
        } = orderOptions;

        const agreementsObj = {};
        Object.keys(agreements).forEach((key) => {
          const agreement = agreements[key];
          const { text, required } = agreement;
          const { choices } = PUT[key];
          const value = orderBoundToReloadOrder
            ? orderBoundToReloadOrder[key]
            : activeOrder[key];

          agreementsObj[key] = {
            text,
            required,
            value,
            choices,
          };
          this.$emit("agreement-checked", { value, agreement: key });
        });
        this.orderAgreements = agreementsObj;
        if (Object.keys(agreementsObj).length) {
          this.$emit("update:showOrderAgreements", true);
        }
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.$emit("fetch-error", errors);
        throw errorObj;
      }
    },
  },
};
</script>
