<template>
  <pulse-loader :loading="loading" :color="color" />
</template>

<script>
import { PulseLoader } from "vue-spinner/dist/vue-spinner.min";

export default {
  name: "LoadingIndicator",
  components: { PulseLoader },
  props: {
    loading: {
      type: Boolean,
      requred: false,
      default() {
        return false;
      },
    },
  },
  data() {
    return { color: "#96d9fb" };
  },
};
</script>
