<template>
  <div>
    <info-box>
      Etykieta składa się maksymalnie z pięciu linii i może zostać umieszczona
      na kopercie lub ozdobnym etui. Najczęściej zawiera ona informacje o
      imieniu, nazwisku, dziale lub numerze pracownika. Wybierz tę opcję, a
      następnie podaj informacje do Twojej etykiety.
    </info-box>
    <envelope-label-location />
    <envelope-label-description
      v-if="getLabelLocation && getLabelLocation !== 'none'"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { FormValidationError } from "@/utils/errors";
import EnvelopeLabelLocation from "./EnvelopeLabelLocation.vue";
import EnvelopeLabelDescription from "./EnvelopeLabelDescription.vue";
import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";

export default {
  name: "EnvelopeLabel",
  components: {
    EnvelopeLabelLocation,
    EnvelopeLabelDescription,
    InfoBox,
  },
  computed: {
    ...mapGetters("orders", ["getLabelLocation"]),
    ...mapState("orders", ["activeForm"]),
    ...mapState("user", ["userContract"]),
    hasValidationErrors() {
      const { label_location, label_descriptions } = this.activeForm;
      return (
        label_location === null ||
        (label_location !== "none" && !label_descriptions.length)
      );
    },
  },
  watch: {
    hasValidationErrors(hasErrors) {
      if (hasErrors) {
        this.$emit("form-error");
        return;
      }
      this.$emit("form-success");
    },
  },
  created() {
    if (!this.hasValidationErrors) {
      this.$emit("form-success");
    }
  },
  methods: {
    beforeComponentChange() {
      if (this.hasValidationErrors) {
        throw new FormValidationError(
          "EnvelopeLabel dynamic list validation error",
        );
      }
    },
  },
};
</script>
