<template>
  <div class="main-app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
