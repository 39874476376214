<template>
  <div class="step step--with-stepper">
    <div class="step__content-row">
      <div class="step__content-column wide-column">
        <card-picture
          title="Wybrana karta"
          :card-type="getActiveNofContractCardType.public_name"
          :available-pictures="getActiveNofContractProductsPictures"
        />
      </div>
      <div
        class="step__content-column narrow-column step-1-header-personalizations"
      >
        <nof-add-cards
          ref="nofAddCarrdsRef"
          @is-dirty="($event) => (isCardAddingDirty = $event)"
        />
        <error-item :errors="noCardsError" />
      </div>
    </div>

    <order-cards-list :is-address-visible="false" />

    <template v-if="hasVisiblePersonalizationItems">
      <h2 v-if="personalizationItems" class="step-form__title">
        Dodatkowe opcje
      </h2>
      <dynamic-list
        ref="dynamicStepList"
        :loading.sync="isNextStepLoading"
        :steps="personalizationItems"
      />
    </template>
    <div class="buttons-container--center">
      <action-button
        :loading="isNextStepLoading"
        @click="handleNextStepButtonClick"
      >
        Dalej
      </action-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

import NofAddCards from "@/views/nof/NofAddCards.vue";
import OrderCardsList from "@/views/components/formFields/OrderCardsList.vue";

import DynamicList from "@/views/components/formFields/DynamicList.vue";

import CardCarrier from "@/views/components/personalizations/CardCarrier.vue";
import CustomEnvelope from "@/views/components/personalizations/CustomEnvelope.vue";
import TheInsert from "@/views/components/personalizations/TheInsert.vue";
import CardPicture from "@/views/components/personalizations/CardPicture.vue";
import MbaNetwork from "@/views/components/personalizations/MbaNetwork.vue";
import CustomText from "@/views/components/personalizations/CustomText.vue";
import CustomLogo from "@/views/components/personalizations/CustomLogo.vue";
import CustomPicture from "@/views/components/personalizations/CustomPicture.vue";
import CustomCase from "@/views/components/personalizations/CustomCase.vue";

import iconPayer from "@/images/icon-payer.svg";
import iconPhotoDouble from "@/images/icon-photo-double.svg";
import iconDocument from "@/images/icon-document.svg";
import iconCase from "@/images/icon-case.svg";

export default {
  name: "NofMultistepFormStep1",
  components: {
    CardPicture,
    NofAddCards,
    OrderCardsList,
    DynamicList,
  },
  data() {
    return {
      isCardAddingDirty: false,
      noCardsError: "",
      isNextStepLoading: false,
    };
  },
  computed: {
    ...mapState("user", ["userContract"]),
    ...mapState("nof", ["activeNofContract"]),
    ...mapGetters("orders", ["getCardTypeId", "getOrderedCards"]),
    ...mapGetters("nof", [
      "getActiveNofContractProductsPictures",
      "getActiveNofContractCardType",
    ]),
    hasVisiblePersonalizationItems() {
      const { personalizationItems } = this;
      return personalizationItems.some((item) => !item.hide);
    },
    isMbaNetworkVisible() {
      const {
        activeNofContract: { can_set_mbanetwork, card_types } = {},
        getCardTypeId,
      } = this;
      if (!getCardTypeId) {
        return false;
      }
      const cardType = card_types.find((type) => type.id === getCardTypeId);
      const { loop_restriction_type } = cardType;
      return (
        (loop_restriction_type === "mba" ||
          loop_restriction_type === "flexiloop") &&
        can_set_mbanetwork
      );
    },
    personalizationItems() {
      const { activeNofContract: contract = {}, isMbaNetworkVisible } = this;
      const personalizationItems = [
        {
          icon: iconCase,
          name: "Ozdobne etui",
          component: CustomCase,
          hide: !contract.can_add_etui,
        },
        {
          icon: iconPayer,
          name: "Karta z własnym logo",
          component: CustomLogo,
          hide: !contract.can_add_logo,
        },
        {
          icon: iconPhotoDouble,
          name: "Karta z własnym wizerunkiem",
          component: CustomPicture,
          hide: !contract.can_add_picture_card,
        },
        {
          icon: iconPayer,
          name: "Napis na karcie",
          component: CustomText,
          hide: !contract.can_add_inscription,
        },
        {
          icon: iconDocument,
          name: "List lub życzenia",
          component: TheInsert,
          hide: !contract.can_add_insert,
        },
        {
          icon: iconPayer,
          name: "Indywidualna koperta",
          component: CustomEnvelope,
          hide: !contract.can_add_individual_envelope,
        },
        {
          icon: iconPayer,
          name: "Indywidualny list przewodni",
          component: CardCarrier,
          hide: !contract.can_add_card_carrier,
        },
        {
          icon: iconPayer,
          name: "Zakres akceptacji",
          component: MbaNetwork,
          hide: !isMbaNetworkVisible,
        },
      ];
      return personalizationItems;
    },
  },
  async beforeMount() {
    await this.loadContracts();
    this.updateBillingData();
  },
  methods: {
    ...mapMutations("nof", ["setActiveNofContract"]),
    ...mapActions("nof", ["handleNextFormStep"]),
    ...mapActions("orders", ["reloadActiveFormData", "updateBillingData"]),
    ...mapActions("user", ["loadContracts"]),
    async handleNextStepButtonClick() {
      this.isNextStepLoading = true;
      if (!this.isCardAddingDirty && !this.getOrderedCards.length) {
        this.noCardsError = "Dodaj przynajmniej jedną kartę";
        this.scrollToCardAdding();
        this.isNextStepLoading = false;
        return;
      }

      if (this.isCardAddingDirty) {
        const errors = await this.$refs.nofAddCarrdsRef.addCards();
        if (errors) {
          this.scrollToCardAdding();
          this.isNextStepLoading = false;
          return;
        }
        this.updateBillingData();
        this.noCardsError = "";
      }

      try {
        if (this.hasVisiblePersonalizationItems) {
          await this.$refs.dynamicStepList.validate();
        }
        this.handleNextFormStep();
      } catch (error) {
        if (error.name !== "DynamicListValidationError") {
          throw error;
        }
        console.error({
          message: "Nof form step 1 dynamic list validation error",
          error,
        });
      } finally {
        this.isNextStepLoading = false;
      }
    },
    scrollToCardAdding() {
      this.$refs["nofAddCarrdsRef"].$el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.step-1-header-personalizations {
  display: flex;
  flex-direction: column;
}
</style>
