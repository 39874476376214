<template>
  <div>
    <div
      v-if="cards.length"
      class="data-list defined-cards-list"
      :class="{ 'has-hidden-address': !isAddressVisibleComputed }"
    >
      <h2 class="data-list-title">
        {{
          isReloadOrder ? "Lista kart do doładowania" : "Lista zamówionych kart"
        }}
      </h2>
      <div
        v-if="showHeaders"
        class="data-list-header"
        :class="{ 'has-padding-right': isModifiable }"
      >
        <div class="data-list-header-column">
          {{ isReloadOrder ? "" : "Rodzaj karty" }}
        </div>
        <div v-if="isAddressVisibleComputed" class="data-list-header-column">
          {{
            isVirtualProductActive
              ? "Adres e-mail przypisany do karty"
              : "Adres wysyłki"
          }}
        </div>
        <div class="data-list-header-column">Wartość doładowania/szt.</div>
        <div class="data-list-header-column">Liczba</div>
        <div class="data-list-header-column">Suma</div>
      </div>
      <order-cards-list-item
        v-for="(card, i) in displayedCards"
        :key="`card-${i}`"
        :card="card"
        :loading="deleteCardLoading[card.id]"
        :is-deletable="isModifiable"
        :is-address-visible="isAddressVisibleComputed"
        :virtual="isVirtualProductActive"
        :label="
          isReloadOrder
            ? `Doładowanie kart${card.quantity === 1 ? 'y' : ''}`
            : ''
        "
        @delete-card="deleteCard(card.id)"
      />
      <div
        v-if="showTotal"
        class="data-list-item order-cards-list__total"
        :class="{ 'has-hidden-address': !isAddressVisibleComputed }"
      >
        <div class="data-list-column">Razem:</div>
        <div v-if="isAddressVisibleComputed" class="data-list-column"></div>
        <div class="data-list-column"></div>
        <div class="data-list-column">{{ totalQuantity }} szt.</div>
        <div class="data-list-column">{{ totalTopUp | toPln }}</div>
      </div>
    </div>

    <error-item :errors="errors.non_field_errors" />
  </div>
</template>

<script>
import _groupBy from "lodash.groupby";

import { mapGetters, mapActions } from "vuex";

import { orderedCardAPIPoint } from "@/apiEndpoints";

import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";

import OrderCardsListItem from "@/views/components/formFields/OrderCardsListItem.vue";

export default {
  components: {
    OrderCardsListItem,
  },
  props: {
    orderedCards: {
      type: Array,
      default: null,
    },
    showHeaders: {
      type: Boolean,
      default: true,
    },
    isModifiable: {
      type: Boolean,
      default: true,
    },
    isAddressVisible: {
      type: Boolean,
      default: true,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    totalTopUp: {
      type: [String, Number],
      default: "",
    },
    totalQuantity: {
      type: [String, Number],
      default: "",
    },
    isReloadOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      definedCardsList: [],
      errors: {},
      deleteCardLoading: {},
    };
  },
  computed: {
    ...mapGetters("orders", [
      "getOrderedCards",
      "getActiveCardOrderId",
      "isVirtualProductActive",
    ]),
    isAddressVisibleComputed() {
      return this.isAddressVisible && !this.isVirtualProductActive;
    },
    cards() {
      if (this.orderedCards) {
        return this.orderedCards;
      } else {
        return this.getOrderedCards;
      }
    },
    displayedCards() {
      const cardsByLabel = _groupBy(this.cards, "has_label");
      const [cardsWithoutLabel, cardsWithLabel] = [
        cardsByLabel.false,
        cardsByLabel.true,
      ];

      const addUpCards = (cards) =>
        cards.reduce((summedCards, card) => {
          return {
            ...card,
            quantity: summedCards.quantity
              ? summedCards.quantity + card.quantity
              : card.quantity,
          };
        }, {});

      const addUpCardsByAmount = (cards) => {
        const groupedByAmount = _groupBy(cards, "amount");
        return Object.keys(groupedByAmount).reduce((acc, amount) => {
          const cards = groupedByAmount[amount];
          const summed = addUpCards(cards);
          acc.push(summed);
          return acc;
        }, []);
      };

      const getSummedCards = (cards) => {
        const groupedByAddress = _groupBy(cards, "delivery_address");

        return Object.keys(groupedByAddress).reduce(
          (accumulatedCards, addressId) => {
            const currentCards = groupedByAddress[addressId];
            accumulatedCards = [
              ...accumulatedCards,
              ...addUpCardsByAmount(currentCards),
            ];
            return accumulatedCards;
          },
          [],
        );
      };

      return [
        ...getSummedCards(cardsWithoutLabel),
        ...getSummedCards(cardsWithLabel),
      ];
    },
  },
  methods: {
    ...mapActions("orders", ["reloadActiveFormData", "updateBillingData"]),
    async deleteCard(cardId) {
      if (this.deleteCardLoading[cardId]) {
        return;
      }

      this.deleteCardLoading = {
        ...this.deleteCardLoading,
        [cardId]: true,
      };

      try {
        await fetchFromApi(
          `${orderedCardAPIPoint}${cardId}/?card_order=${this.getActiveCardOrderId}`,
          {
            method: "DELETE",
          },
        );

        this.errors = {};
        this.reloadActiveFormData();
        this.updateBillingData();
        this.$emit("card-removed");
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      }

      this.deleteCardLoading = {
        ...this.deleteCardLoading,
        [cardId]: false,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.defined-cards-list {
  margin-top: 50px;

  .data-list-header-column {
    flex-shrink: 0;
    text-align: center;
    width: 17.5%;
    padding: 10px 25px;

    &:first-child {
      width: 30%;
      text-align: left;
      padding-left: 25px;
    }
  }

  &.has-hidden-address {
    .data-list-header-column {
      width: 23.3%;
      &:first-child {
        width: 30%;
      }
    }
  }
}

.data-list-header {
  padding-right: 0;
  padding-bottom: 0;

  &.has-padding-right {
    padding-right: 46px;
  }
}

.order-cards-list__total {
  .data-list-column {
    flex-shrink: 0;
    text-align: center;
    width: 17.5%;
    font-weight: $font-weight-bold;
    color: $color-primary;

    &:first-child {
      width: 30%;
      font-size: 1rem;
      text-align: left;
      justify-content: flex-start;
    }
  }

  &.has-hidden-address {
    .data-list-column {
      width: 23.3%;

      &:first-child {
        width: 30%;
      }
    }
  }
}
</style>
