export default {
  setActiveFormStep(state, payload) {
    state.activeFormStep = payload;
  },
  setDeclaration(state, payload) {
    state.declaration = payload;
  },
  setRepresentatives(state, payload) {
    state.representatives = payload;
  },
  setAuthorizedPersons(state, payload) {
    state.authorizedPersons = payload;
  },
  setBeneficialOwners(state, payload) {
    state.beneficialOwners = payload;
  },
  setSubmitters(state, payload) {
    state.submitters = payload;
  },
};
