import Vue from "vue";

import { hasAuthToken } from "@/utils/authToken";
import { fetchFromApi } from "@/utils/api";
import {
  clientAPIPoint,
  contractAPIPoint,
  userAPIPoint,
  clientContactPersonAPIPoint,
  clientDeliveryAddressAPIPoint,
  resetPasswordAPIPoint,
  setNewPasswordAPIPoint,
  changeCurrentPasswordAPIPoint,
} from "@/apiEndpoints";

export default {
  async initializeUserData({ dispatch }) {
    if (hasAuthToken()) {
      await dispatch("loadAuthentication");
      await dispatch("loadUserDetails");
      await Promise.all([
        dispatch("loadClientDetails"),
        dispatch("loadContracts"),
      ]);
    }
  },
  loadAuthentication({ commit }) {
    commit("setIsAuthenticated", hasAuthToken());
  },
  async loadClientDetails({
    commit,
    state: { selectedClient: { id: selectedClientId } = {} },
  }) {
    if (!selectedClientId) {
      return;
    }
    const clientDetail = await fetchFromApi(clientAPIPoint + selectedClientId);
    commit("setClientDetails", clientDetail);
  },
  async fetchContractByProductSlug(context, productSlug) {
    return await fetchFromApi(
      `${contractAPIPoint}?commercial_products_set__product_configuration__slug=${productSlug}`,
    );
  },
  async loadContracts({ commit, rootGetters }) {
    const activeProductTypeId = rootGetters["orders/getActiveProductTypeId"];
    let clientContractsApiPoint;
    if (activeProductTypeId) {
      // TODO: remove commercial_product_id from query params
      // commercial_product_id is deprecated, we use commercial_products_set__product_configuration instead
      clientContractsApiPoint =
        `${contractAPIPoint}?commercial_product_id=${activeProductTypeId}` +
        `&commercial_products_set__product_configuration=${activeProductTypeId}`;
    } else {
      clientContractsApiPoint = contractAPIPoint;
    }
    const allDefaultContractsApiPoint = `${contractAPIPoint}all_default`;
    const [clientContracts, defaultContracts] = await Promise.all([
      fetchFromApi(clientContractsApiPoint),
      fetchFromApi(allDefaultContractsApiPoint),
    ]);
    commit("setDefaultContracts", defaultContracts);

    const defaultClientContracts = [...clientContracts];
    const dedicatedContractIndex = clientContracts.findIndex(
      (contract) => !contract.is_default,
    );
    if (dedicatedContractIndex !== -1) {
      const dedicatedContract = { ...clientContracts[dedicatedContractIndex] };
      defaultClientContracts.splice(dedicatedContractIndex, 1);
      commit("setUserContract", dedicatedContract);
    } else {
      commit("setUserContract", {});
    }

    commit("setDefaultClientContracts", defaultClientContracts);
  },
  async loadSelectedClientFromStorage({ getters: { getAvailableClients } }) {
    const storageClientId =
      sessionStorage.getItem("selectedClientId") ||
      localStorage.getItem("selectedClientId");
    if (storageClientId) {
      return getAvailableClients.find(
        (client) => client.id === Number(storageClientId),
      );
    }
  },
  async loadUserDetails({ commit, dispatch, state: { selectedClient } }) {
    const userDetails = await fetchFromApi(userAPIPoint);
    commit("setUserDetails", userDetails);
    // if there's no client or if it's updating after nof account creation
    if (!selectedClient || !selectedClient.name) {
      const storageClient = await dispatch("loadSelectedClientFromStorage");
      if (storageClient) {
        dispatch("selectClient", { client: storageClient });
        return;
      }
      const { owned_clients = [] } = userDetails;
      const defaultClient = owned_clients[0];
      if (!defaultClient) {
        return;
      }
      dispatch("selectClient", { client: defaultClient });
    }
  },
  async deleteContactPerson({ commit }, personId) {
    await fetchFromApi(`${clientContactPersonAPIPoint}${personId}/`, {
      method: "DELETE",
    });
    commit("deleteContactPerson", personId);
  },
  async deleteDeliveryAddress({ commit }, addressId) {
    await fetchFromApi(`${clientDeliveryAddressAPIPoint}${addressId}/`, {
      method: "DELETE",
    });
    commit("deleteDeliveryAddress", addressId);
  },
  async selectClient({ commit, dispatch }, { client, storeReset = false }) {
    commit("setSelectedClient", client);
    sessionStorage.setItem("selectedClientId", client.id);
    localStorage.setItem("selectedClientId", client.id);
    if (storeReset) {
      await dispatch("initialize", null, { root: true });
    }
  },
  async resetPassword(state, email) {
    return await fetchFromApi(resetPasswordAPIPoint, {
      method: "POST",
      data: {
        user_email: email,
      },
    });
  },
  async setNewPassword(state, { email, token, password1, password2 }) {
    return await fetchFromApi(setNewPasswordAPIPoint, {
      method: "POST",
      data: {
        email,
        token,
        password1,
        password2,
      },
    });
  },
  async changeCurrentPassword(
    state,
    { current_password, password1, password2 },
  ) {
    return await fetchFromApi(changeCurrentPasswordAPIPoint, {
      method: "POST",
      data: {
        current_password,
        password1,
        password2,
      },
    });
  },
  setCookiesConsent({ commit }, consentValue) {
    Vue.$cookies.set("cookiesConsent", consentValue, "1y");
    commit("setCookiesConsent", consentValue);
  },
  async setUserEmail(context, { email, password1, password2 }) {
    return fetchFromApi(`${userAPIPoint}/set_email/`, {
      method: "POST",
      data: {
        email,
        password1,
        password2,
      },
    });
  },
};
