<template>
  <div class="reload-all-items">
    <div class="form-group">
      <div class="label">Wartość doładowania</div>
      <input v-model="newAmount" class="input" type="number" />
    </div>
    <action-button
      secondary
      :loading="loadingCardsReload"
      @click="applyAmountToAllCards"
    >
      Zastosuj do wszystkich
    </action-button>
    <error-item :errors="errors" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { reloadedCardAPIPoint } from "@/apiEndpoints";
import { fetchFromApi } from "@/utils/api";
import { obtainFetchError } from "@/utils/errors";

export default {
  name: "ReloadAllCards",
  data() {
    return {
      loadingCardsReload: false,
      newAmount: null,
      errors: {},
    };
  },
  computed: {
    ...mapState("reloads", ["activeReloadOrderId"]),
    reloadAllCardAPIPoint() {
      return `${reloadedCardAPIPoint}bulk_update/?card_reload_order=${this.activeReloadOrderId}`;
    },
  },
  methods: {
    ...mapActions("reloads", ["updateReloadBillingData"]),
    async applyAmountToAllCards() {
      if (!this.newAmount) {
        return;
      }
      this.loadingCardsReload = true;

      try {
        await fetchFromApi(this.reloadAllCardAPIPoint, {
          method: "PATCH",
          data: { amount: this.newAmount },
        });
        this.updateReloadBillingData();
        this.newAmount = null;
        this.errors = {};
        this.$emit("reload-cards-to-reload");
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      }

      this.loadingCardsReload = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.reload-all-items {
  .form-group {
    display: inline-block;
    width: 180px;
    padding-right: 40px;
  }
}
</style>
