export default {
  viewport: {
    gt: {},
    st: {},
  },
  width: 0,
  height: 0,
  // maximum pixel width for each
  breakpoints: {
    xs: 501,
    sm: 769,
    md: 1101,
  },
};
