<template>
  <invoice-details-form
    ref="invoiceDetailsRef"
    invoice-details-type="order"
    :api-endpoint="apiEndpoint"
    :form-data-object="getActiveFormReloadInvoiceData"
    :form-data-object-prefix="formDataPrefix"
    @form-success="handleFormSuccess"
    @request-update="reloadActiveReloadFormData"
    @is-dirty="$emit('is-dirty')"
  >
    <div class="form-group">
      <label class="label-checkbox">
        <input
          v-model="showRecipient"
          type="checkbox"
          :disabled="
            !!getActiveReloadFormRecipientInvoiceData.invoice_recipient_vat_id
          "
        />
        <span> Dane odbiorcy na fakturze są inne niż nabywcy </span>
      </label>
    </div>
  </invoice-details-form>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import { orderAPIPoint } from "@/apiEndpoints";

import InvoiceDetailsForm from "@/views/components/formFields/invoice_details/InvoiceDetailsForm.vue";

export default {
  name: "ReloadOrderInvoiceDetails",
  components: { InvoiceDetailsForm },
  computed: {
    ...mapState("reloads", ["activeReloadOrderId"]),
    ...mapGetters("reloads", [
      "getActiveFormReloadInvoiceData",
      "getActiveReloadFormRecipientInvoiceData",
      "getActiveCardReloadOrderId",
      "getActiveReloadFormInvoiceOptions",
    ]),
    apiEndpoint() {
      return `${orderAPIPoint}${this.getActiveCardReloadOrderId}/?card_reload_order=${this.activeReloadOrderId}`;
    },
    showRecipient: {
      get() {
        return this.getActiveReloadFormInvoiceOptions.showRecipient;
      },
      set(value) {
        this.updateActiveReloadFormInvoiceOptions({ showRecipient: value });
      },
    },
  },
  methods: {
    ...mapActions("reloads", ["reloadActiveReloadFormData"]),
    ...mapMutations("reloads", ["updateActiveReloadFormInvoiceOptions"]),
    formDataPrefix(field) {
      return field !== "vat_id" ? "invoice_" : "";
    },
    handleFormSuccess(event) {
      this.$emit("form-success", event);
    },
    // used by DynamicList.vue component
    async beforeComponentChange() {
      const {
        $refs: { invoiceDetailsRef },
      } = this;
      if (invoiceDetailsRef.beforeComponentChange) {
        await invoiceDetailsRef.beforeComponentChange();
      }
    },
  },
};
</script>
