<template>
  <div class="data-list-item">
    <div v-if="showIndex" class="data-list-column-number">
      {{ addressIndex + 1 }}
    </div>
    <div
      class="data-list-column data-list-column__details"
      :class="{ 'data-list-column-wide': !showIndex }"
    >
      <div class="data-list-column__name">{{ address.name }}</div>
      <div class="data-list-column__name-alt">{{ address.name_alt }}</div>
      <div class="data-list-column__address">
        {{ address.address }} {{ address.zip_code }} {{ address.city }}
      </div>
      <div class="data-list-column__email">
        {{ address.authorized_person_email }}
      </div>
    </div>
    <div
      class="data-list-column data-list-column-name"
      :class="{ 'data-list-column-wide': !showIndex }"
    >
      <div>
        {{
          address.authorized_person_first_name +
          " " +
          address.authorized_person_last_name
        }}
      </div>
      <div>{{ address.authorized_person_phone }}</div>
    </div>
    <div
      v-if="!address.has_cards && isDeletable"
      class="delete-data-item-container"
      @click="deleteAddress"
    >
      <div v-if="!loadingAddress" class="delete-data-item"></div>
      <loading-indicator :loading="loadingAddress" />
    </div>
  </div>
</template>

<script>
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

export default {
  name: "AddressesListItem",
  components: { LoadingIndicator },
  props: {
    address: {
      type: Object,
      required: true,
    },
    addressIndex: {
      type: Number,
      required: true,
    },
    loadingAddress: {
      type: Boolean,
      default: false,
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
    showIndex: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    deleteAddress() {
      this.$emit("delete-address");
    },
  },
};
</script>
