<template>
  <invoice-details-form
    ref="invoiceDetailsRef"
    :api-endpoint="apiEndpoint"
    :form-data-object="getActiveFormRecipientInvoiceData"
    :form-data-object-prefix="formDataPrefix"
    invoice-details-type="recipient"
    @form-success="handleFormSuccess"
    @request-update="reloadActiveFormData"
    @is-dirty="$emit('is-dirty')"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { orderAPIPoint } from "@/apiEndpoints";

import InvoiceDetailsForm from "@/views/components/formFields/invoice_details/InvoiceDetailsForm.vue";

export default {
  name: "OrderInvoiceRecipientDetails",
  components: { InvoiceDetailsForm },
  computed: {
    ...mapGetters("orders", [
      "getActiveFormRecipientInvoiceData",
      "getActiveOrderId",
      "getActiveCardOrderId",
    ]),
    apiEndpoint() {
      return `${orderAPIPoint}${this.getActiveOrderId}/?card_order=${this.getActiveCardOrderId}`;
    },
  },
  methods: {
    ...mapActions("orders", ["reloadActiveFormData"]),
    formDataPrefix() {
      return "invoice_recipient_";
    },
    async handleFormSuccess($event) {
      this.$emit("form-success", $event);
    },
    // used by DynamicList.vue component
    async beforeComponentChange() {
      const {
        $refs: {
          invoiceDetailsRef: { beforeComponentChange },
        },
      } = this;
      if (beforeComponentChange) {
        await beforeComponentChange();
      }
    },
  },
};
</script>
