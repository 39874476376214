<template>
  <div class="agreement">
    <template v-if="hasCheckbox">
      <label class="agreement-checkbox">
        <input
          v-model="pickedOption"
          type="checkbox"
          @input="
            ($event) =>
              $event.target.checked ? emitChoice(true) : emitChoice(false)
          "
        />
        <span>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="agreementContent"></span> {{ required ? "*" : "" }}
        </span>
      </label>
    </template>
    <template v-if="!hasCheckbox">
      <div class="agreement-content">
        {{ agreementContent }} {{ required ? "*" : "" }}
      </div>

      <template v-if="choices && choices.length">
        <label
          v-for="(choice, choiceIndex) in choices"
          :key="`agreement-choice-${choiceIndex}`"
        >
          <input
            v-model="pickedOption"
            :name="agreementKey"
            type="radio"
            :value="choice.value"
            @input="emitChoice(choice.value)"
          />
          {{ choice.display_name }}
        </label>
      </template>
      <template v-else>
        <label :for="`${agreementKey}-true`">
          <input
            :id="`${agreementKey}-true`"
            v-model="pickedOption"
            :name="agreementKey"
            type="radio"
            value="true"
            @input="emitChoice(true)"
          />
          tak
        </label>
        <label :for="`${agreementKey}-false`">
          <input
            :id="`${agreementKey}-false`"
            v-model="pickedOption"
            :name="agreementKey"
            type="radio"
            value="false"
            @input="emitChoice(false)"
          />
          nie
        </label>
      </template>

      <text-input
        v-if="agreementKey === `agreement_4` && pickedOption === `true`"
        v-model.trim="stock_exchange"
        :errors="stock_exchange_error"
        @input-blur="checkDetailInput($event, `stock_exchange`)"
        @input="setAgreementDetail($event, `stock_exchange`)"
      >
        Nazwa i siedziba giełdy
      </text-input>
      <text-input
        v-if="agreementKey === `agreement_7` && pickedOption === `true`"
        v-model.trim="stock_exchange_company"
        :errors="stock_exchange_company_error"
        @input-blur="checkDetailInput($event, `stock_exchange_company`)"
        @input="setAgreementDetail($event, `stock_exchange_company`)"
      >
        Nazwa notowanej spółki
      </text-input>
      <text-input
        v-if="agreementKey === `agreement_7` && pickedOption === `true`"
        v-model.trim="stock_exchange_for_shareholder"
        :errors="stock_exchange_for_shareholder_error"
        @input-blur="checkDetailInput($event, `stock_exchange_for_shareholder`)"
        @input="setAgreementDetail($event, `stock_exchange_for_shareholder`)"
      >
        Nazwa i siedziba giełdy
      </text-input>
    </template>
    <error-item :errors="errors" />
  </div>
</template>

<script>
import TextInput from "@/views/components/formFields/TextInput.vue";

export default {
  name: "AgreementItem",
  components: { TextInput },
  props: {
    agreement: {
      type: Array,
      required: true,
    },
    errors: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    value: {
      type: [Boolean, String],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    choices: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      agreementKey: this.agreement[0],
      agreementContent: this.agreement[1],
      pickedOption: this.value,
      stock_exchange: "",
      stock_exchange_error: "",
      stock_exchange_company: "",
      stock_exchange_company_error: "",
      stock_exchange_for_shareholder: "",
      stock_exchange_for_shareholder_error: "",
      detailsErrors: {},
    };
  },
  methods: {
    setAgreementDetail(value, field) {
      if (value)
        this.$emit("agreement-detail-input", {
          value,
          field,
        });
    },
    checkDetailInput(event, field) {
      if (!event) {
        this[`${field}_error`] = ["to pole nie może być puste"];
      } else if (event) {
        this[`${field}_error`] = null;
      }
    },
    emitChoice(value) {
      this.$emit("change", value);
      this.$emit("agreement-checked", {
        value,
        agreement: this.agreementKey,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.agreement {
  font-size: $small-font-size;
  color: $color-primary;
  margin-bottom: 20px;
  &-content {
    margin-bottom: 7px;
  }
  label {
    font-weight: $font-weight-bold;
    display: inline-block;
    width: 48%;
  }
}

label.agreement-checkbox {
  width: 100%;
  display: flex;
  font-weight: $font-weight-regular;
}
</style>
