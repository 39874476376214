<template>
  <div>
    <info-box>
      Jeśli chcesz, aby koperta z kartą były umieszczone w ozdobnym etui zaznacz
      opcję poniżej. Po wybraniu tej opcji zobaczysz podgląd etui.
    </info-box>

    <input-radio-group
      v-model="isCaseSelected"
      :items="items"
      name="case-radio-btns"
      :active-radio="isCaseSelected"
      :disabled="updatingOrder"
    />

    <div v-show="isCaseSelected" class="carousel-wrap">
      <slick-carousel
        v-if="availableCases.length > 0"
        :key="carouselReset"
        :initial-picture-index="initialCaseIndex"
        @picture-changed="handleCaseUpdate"
      >
        <img
          v-for="caseObj in availableCases"
          :key="`case-${caseObj.id}`"
          :src="caseObj.image"
        />
      </slick-carousel>
    </div>

    <loading-indicator :loading="updatingOrder" />

    <error-item :errors="errors.non_field_errors" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { FormValidationError } from "@/utils/errors";

import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";
import SlickCarousel from "@/views/components/formFields/SlickCarousel.vue";

import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

import updateOrderMixin from "@/mixins/updateOrderMixin";

export default {
  name: "CustomCase",
  components: {
    LoadingIndicator,
    InputRadioGroup,
    SlickCarousel,
    InfoBox,
  },
  mixins: [updateOrderMixin],
  data() {
    return {
      items: [
        { label: "Standardowa koperta bez etui", value: false },
        { label: "Koperta w ozdobnym etui", value: true },
      ],
      isCaseSelected: null,
      initialCaseIndex: 0,
      carouselReset: false,
    };
  },
  computed: {
    ...mapState("user", ["userContract"]),
    ...mapState("orders", ["activeForm"]),
    ...mapState("nof", ["activeNofContract"]),
    availableCases() {
      if (this.activeNofContract) {
        return this.activeNofContract.etuis || [];
      }
      const { userContract: { etuis = [] } = {} } = this;
      return etuis;
    },
    hasValidationErrors() {
      const { has_etui, etui } = this.activeForm;
      if (has_etui === null || (has_etui && !etui && etui !== 0)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    async isCaseSelected(isSelected) {
      const {
        activeForm: { etui },
      } = this;
      if (isSelected && !etui) {
        if (!this.availableCases.length) {
          return;
        }
        // set etui to default etui
        // reset needed to make defaultCaseIndex work

        this.carouselReset = !this.carouselReset;
        const defaultCaseIndex = this.availableCases.findIndex(
          (caseObj) => caseObj.default,
        );
        const caseIndex = defaultCaseIndex !== -1 ? defaultCaseIndex : 0;
        this.initialCaseIndex = caseIndex;
        const defaultCase = this.availableCases[caseIndex];
        if (!defaultCase) {
          return;
        }

        await this.updateOrder({ etui: defaultCase.id, has_etui: isSelected });
      } else if (!isSelected) {
        // clearing etui
        await this.updateOrder({ etui: null, has_etui: isSelected });
      }
      this.updateBillingData();
    },
    hasValidationErrors(hasErrors) {
      if (hasErrors) {
        this.$emit("form-error");
        return;
      }
      this.$emit("form-success");
    },
  },
  beforeMount() {
    const {
      activeForm: { etui, has_etui },
      availableCases,
    } = this;
    this.isCaseSelected = has_etui;
    if (etui || etui === 0) {
      this.initialCaseIndex = availableCases.findIndex(
        (caseObj) => caseObj.id === etui,
      );
    }

    if (!this.hasValidationErrors) {
      this.$emit("form-success");
    }
  },
  methods: {
    ...mapActions("orders", ["updateBillingData"]),
    async handleCaseUpdate(value) {
      this.updateOrder({ etui: this.availableCases[value].id });
    },
    beforeComponentChange() {
      if (this.hasValidationErrors) {
        throw new FormValidationError(
          "CustomCase dynamic list validation error",
        );
      }
    },
  },
};
</script>

<style scoped>
.carousel-wrap {
  margin-top: 28px;
}
</style>
