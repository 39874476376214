const getDefaultState = () => ({
  activeForm: {},
  activeFormStep: 0,
  activeOrderId: null,
  cardOrderOptions: {},
  orderOptions: {},
  activeFormBilling: {
    isBillingFinal: false,
    billingArray: [],
  },
  activeFormInvoiceOptions: {
    showRecipient: false,
  },
  activeFormBillingReloadError: null,
  activeFormBillingLoading: false,
  activeOrder: {},
  orderNotAuthorizedError: undefined,
  cardsOptions: {},
});

const state = getDefaultState();

export default state;

export { getDefaultState };
