const availableProductsAPIPoint = "/commercial-product/",
  apiPrefix = "/api",
  apiVersion = "/v1",
  mainAPIPoint = `${apiPrefix}${apiVersion}`,
  generalOrderAPIPoint = "/general-order/",
  cardOrderAPIPoint = "/card-order/",
  countriesAPIPoint = "/countries",
  cardReloadOrderAPIPoint = "/card-reload-order/",
  makeReloadOrderFromFile = "import_cards_from_file/",
  cardAPIPoint = "/card/",
  cardShareAPIPoint = "/card/share/",
  cardsGetByOrdersAPIPoint = "/card/get_by_orders/",
  cardsGetFromFileAPIPoint = "/card/get_cards_from_file/",
  orderedCardAPIPoint = "/ordered-card/",
  contractAPIPoint = "/contract/",
  contractCardTypesAPIPoint = "/contract/card_types_for_commercial_product/",
  orderAPIPoint = "/order/",
  addressAPIPoint = "/delivery-address/",
  loginAPIPoint = "/api/token/",
  registerAPIPoint = "/register",
  submitOrderAPIPoint = "/submit_order/",
  orderingPersonAPIPoint = "/contact-person/",
  reloadedCardAPIPoint = "/reloaded-card/",
  clientAPIPoint = "/client/",
  userAPIPoint = "/user",
  mbaAPIPoint = "/mba-network/",
  cardOrderOverviewAPIPoint = "/card-order-overview/",
  clientContactPersonAPIPoint = "/client-contact-person/",
  clientDeliveryAddressAPIPoint = "/client-delivery-address/",
  resetPasswordAPIPoint = "/reset-password",
  setNewPasswordAPIPoint = "/set-new-password",
  changeCurrentPasswordAPIPoint = "/change-password";

export {
  availableProductsAPIPoint,
  mainAPIPoint,
  generalOrderAPIPoint,
  cardOrderAPIPoint,
  countriesAPIPoint,
  cardReloadOrderAPIPoint,
  makeReloadOrderFromFile,
  cardAPIPoint,
  cardShareAPIPoint,
  cardsGetByOrdersAPIPoint,
  cardsGetFromFileAPIPoint,
  contractAPIPoint,
  contractCardTypesAPIPoint,
  addressAPIPoint,
  loginAPIPoint,
  orderedCardAPIPoint,
  orderAPIPoint,
  registerAPIPoint,
  submitOrderAPIPoint,
  orderingPersonAPIPoint,
  reloadedCardAPIPoint,
  clientAPIPoint,
  userAPIPoint,
  mbaAPIPoint,
  cardOrderOverviewAPIPoint,
  clientContactPersonAPIPoint,
  clientDeliveryAddressAPIPoint,
  resetPasswordAPIPoint,
  setNewPasswordAPIPoint,
  changeCurrentPasswordAPIPoint,
  apiPrefix,
  apiVersion,
};
