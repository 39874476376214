import { countriesAPIPoint } from "@/apiEndpoints";
import { fetchFromApi } from "@/utils/api";

export default {
  async loadCountries({ commit }) {
    const countries = await fetchFromApi(countriesAPIPoint, {
      method: "GET",
    });
    commit("setCountries", countries);
    return countries;
  },
};
