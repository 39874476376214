<template>
  <div class="step step--with-stepper">
    <AppRepeater
      ref="repeaterRef"
      :template="representativeTemplate"
      :component="repeaterComponent"
      :delete-handler="deleteRepresentative"
      :patch-handler="patchRepresentative"
      :post-handler="postRepresentative"
      :load-handler="loadRepresentatives"
      :items="representatives"
      :loading.sync="loading"
    />
    <p class="step__footer">** - pole wymagane do przesłania formularza</p>
    <div class="buttons-container buttons-container--main">
      <action-button secondary @click="setPrevFormStep"> Wstecz </action-button>
      <action-button :loading="loading" @click="handleNextStep">
        Dalej
      </action-button>
    </div>
  </div>
</template>

<script>
import AppRepeater from "@/views/components/formFields/AppRepeater.vue";
import BoardRepresentative from "@/views/components/aml/BoardRepresentative.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "AMLFormStep2",
  components: {
    AppRepeater,
  },
  data() {
    return {
      loading: false,
      representativeTemplate: {
        name: "",
        position: "",
        pesel: "",
        birth_date: null,
        birth_place: "",
        address_of_residence: "",
      },
      repeaterComponent: BoardRepresentative,
    };
  },
  computed: {
    ...mapState("aml", ["representatives"]),
  },
  methods: {
    ...mapActions("aml", [
      "setNextFormStep",
      "setPrevFormStep",
      "postRepresentative",
      "deleteRepresentative",
      "patchRepresentative",
      "loadRepresentatives",
    ]),
    async handleNextStep() {
      try {
        await this.$refs.repeaterRef.submit();
        this.setNextFormStep();
      } catch (errorObj) {
        console.error(errorObj);
      }
    },
  },
};
</script>

<style scoped>
.buttons-container--main {
  margin-top: 32px;
}
</style>
