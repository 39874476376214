import reloadFormSteps from "./reloadFormSteps";

const getDefaultState = () => ({
  activeReloadForm: {},
  activeReloadFormStep: 0,
  activeReloadOrderId: null,
  reloadFormSteps,
  activeReloadFormInvoiceOptions: {
    showRecipient: false,
  },
  activeReloadFormBilling: {
    isBillingFinal: false,
    billingArray: [],
  },
  activeFormBillingReloadError: null,
  activeReloadFormBillingLoading: false,
  reloadOrderOptions: {},
  activeReloadOrder: {},
  reloadNotAuthorizedError: undefined,
});

const state = getDefaultState();

export default state;

export { getDefaultState };
