import Step1 from "@/views/components/formReloadSteps/formReloadStep1.vue";
import Step2 from "@/views/components/formReloadSteps/formReloadStep2.vue";
import Step3 from "@/views/components/formReloadSteps/formReloadStep3.vue";

const reloadFormSteps = [
  {
    description: "Doładuj karty",
    component: Step1,
  },
  {
    description: "Dane",
    component: Step2,
  },
  {
    description: "Podsumowanie",
    component: Step3,
  },
];

export default reloadFormSteps;
