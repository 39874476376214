// maps object to query params
// object keys are params
// values can be primitive or arrays
// if value is falsy it gets omitted
export default function (object) {
  if (typeof object !== "object") {
    throw new Error({
      message: "Expected an object",
      got: object,
    });
  }

  return Object.keys(object)
    .map((key) => {
      if (!object[key]) {
        return false;
      }
      if (Array.isArray(object[key])) {
        const paramArray = object[key];

        if (!paramArray.length || typeof paramArray[0] === "undefined") {
          return false;
        }
        return paramArray
          .map((paramValue) =>
            [key, paramValue].map(encodeURIComponent).join("="),
          )
          .join("&");
      }
      return [key, object[key]].map(encodeURIComponent).join("=");
    })
    .filter((v) => v !== false)
    .join("&");
}
