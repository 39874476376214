<template>
  <div class="reload-step step--with-stepper">
    <info-box>
      Jeśli dla wybranych kart zostały wydane duplikaty, kwota doładowania
      automatycznie zasili ostatnią wydaną kartę.
    </info-box>

    <div class="step__content-column">
      <h2 class="step-form__title">
        Jeśli chcesz doładować karty w konkretnym dniu
      </h2>
      <div class="step-form__subtitle">
        Zaznacz w&nbsp;kalendarzu datę, w&nbsp;którym zrealizujemy doładowanie*
      </div>
      <input-date-picker
        :value="dayPicked"
        :loading="dateLoading"
        :min-date="new Date()"
        @input="chooseCardsReloadDate"
      />
      <error-item :errors="dateInputErrors" />
      <div class="step-form__subtitle">
        *w innym przypadku zamówienie zostanie zrealizowane po otrzymaniu wpłaty
      </div>
    </div>

    <h2 class="step-form__title">Doładuj karty</h2>
    <div class="step-form__subtitle">
      Wpisz kwoty doładowania poszczególnych kart lub doładuj wszystkie karty za
      jednym razem, wpisując kwotę poniżej
    </div>
    <reload-all-cards @reload-cards-to-reload="fetchCards()" />

    <loading-indicator class="loader" :loading="loading" />
    <div v-if="!loading" class="cards-to-reload">
      <cards-list-item
        v-for="(card, cardIndex) in cardsToReload"
        :key="`reload-item-${card.id}`"
        :card="card"
        has-input
        :value="card.amount"
        :errors="cardsToReloadErrors[cardIndex]"
        :is-deletable="
          currentPage !== 1 || Object.keys(cardsToReload).length > 1
        "
        :is-deleting-disabled="cardLoading"
        @input="(value) => handleReloadItemInput({ cardIndex, value })"
        @blur="
          ({ cardId, value }) =>
            updateCardReloadValue({ cardIndex, cardId, value })
        "
        @delete="(cardId) => handleReloadItemDelete({ cardIndex, cardId })"
      />
    </div>
    <app-pagination
      class="pagination"
      :current-page="currentPage"
      :page-count="ordersPaginationLength"
      @click="goToPage"
    />

    <error-item class="text-center general-error" :errors="errors" />

    <div class="buttons-container--center">
      <action-button
        :disabled="hasDateErrors || hasWrongCardValues"
        :loading="cardLoading || dateLoading"
        @click="validateStep"
      >
        Dalej
      </action-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { obtainFetchError } from "@/utils/errors";

import ReloadAllCards from "@/views/components/formFields/ReloadAllCards.vue";
import CardsListItem from "@/views/components/formFields/CardsListItem.vue";
import InputDatePicker from "@/views/components/formFields/InputDatePicker.vue";

import AppPagination from "@/views/components/utilityComponents/AppPagination.vue";
import InfoBox from "@/views/components/utilityComponents/InfoBox.vue";
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

export default {
  name: "FormReloadStep1",
  components: {
    ReloadAllCards,
    AppPagination,
    InputDatePicker,
    CardsListItem,
    InfoBox,
    LoadingIndicator,
  },
  data() {
    return {
      cardsToReload: [],
      cardsToReloadErrors: {},
      dayPicked: null,
      dateInputErrors: {},
      dateLoading: false,
      errors: {},
      ordersPaginationLength: 0,
      currentPage: 1,
      cardLoading: false,
      loading: true,
    };
  },
  computed: {
    ...mapState("reloads", ["activeReloadForm"]),
    hasDateErrors() {
      const { dateInputErrors } = this;
      return !!Object.values(dateInputErrors).length;
    },
    hasWrongCardValues() {
      return this.cardsToReload.some((cardObj) => !Number(cardObj.amount));
    },
  },
  async beforeMount() {
    try {
      this.fetchCards();
      await this.reloadActiveReloadFormData();
      this.dayPicked = this.activeReloadForm.top_up_schedule_date;
      this.updateReloadBillingData();
    } catch (error) {
      // TODO change to 403 after backend implementation
      if (error.status === 404) {
        this.setReloadNotAuthorizedError(error);
      }

      throw error;
    }
  },
  methods: {
    ...mapMutations("reloads", ["setReloadNotAuthorizedError"]),
    ...mapActions("reloads", [
      "reloadActiveReloadFormData",
      "updateCardReloadAmount",
      "getCardReloadOrderCards",
      "patchActiveCardReloadOrder",
      "validateCardReloadOrder",
      "deleteCardToReload",
      "handleNextReloadFormStep",
      "updateReloadBillingData",
    ]),
    async chooseCardsReloadDate(top_up_schedule_date) {
      const { patchActiveCardReloadOrder } = this;
      this.dateInputErrors = {};
      this.dateLoading = true;
      try {
        await patchActiveCardReloadOrder({ top_up_schedule_date });
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.dateInputErrors = errors;
      }
      this.dateLoading = false;
    },
    async updateCardReloadValue({ cardIndex, cardId, value }) {
      try {
        await this.updateCardReloadAmount({ cardId, amount: value });
        this.updateReloadBillingData();
        this.cardsToReloadErrors = {
          ...this.cardsToReloadErrors,
          [cardIndex]: [],
        };
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.cardsToReloadErrors = {
          ...this.cardsToReloadErrors,
          [cardIndex]: errors,
        };
      }
    },
    async handleReloadItemDelete({ cardId, cardIndex }) {
      this.cardLoading = true;
      try {
        await this.deleteCardToReload(cardId);
        this.cardsToReloadErrors = {
          ...this.cardsToReloadErrors,
          [cardIndex]: [],
        };
        await this.updateReloadBillingData();

        // user deleted last card of current page
        if (this.cardsToReload.length === 1) {
          this.currentPage = this.currentPage - 1;
        }
        await this.fetchCards();
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.cardsToReloadErrors = {
          ...this.cardsToReloadErrors,
          [cardIndex]: errors,
        };
      }
      this.cardLoading = false;
    },
    async validateStep() {
      const {
        validateCardReloadOrder,
        reloadActiveReloadFormData,
        handleNextReloadFormStep,
      } = this;
      this.errors = {};

      try {
        const response = await validateCardReloadOrder();
        const { valid, reason } = response;
        if (valid) {
          await reloadActiveReloadFormData();
          handleNextReloadFormStep();
        } else {
          this.errors = reason;
        }
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      }
    },
    async fetchCards({ page = this.currentPage } = {}) {
      try {
        const response = await this.getCardReloadOrderCards({ page });
        const { results, count, page_size } = response;
        this.cardsToReload = results;
        this.ordersPaginationLength = Math.ceil(count / page_size);
      } catch (errorObj) {
        const { errors } = obtainFetchError(errorObj);
        this.errors = errors;
      }
      this.loading = false;
    },
    async goToPage(pageNum) {
      this.currentPage = pageNum;
      await this.fetchCards();
    },
    handleReloadItemInput({ cardIndex, value }) {
      const updatedItem = {
        ...this.cardsToReload[cardIndex],
        amount: value,
      };
      this.cardsToReload.splice(cardIndex, 1, updatedItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.cards-to-reload,
.loader {
  margin: 50px 0 30px;
}

.pagination {
  margin-bottom: 50px;
}

.button--secondary {
  width: auto;
  padding: 12px;
}

.general-error {
  margin-bottom: 26px;
}
</style>
