import { fetchFromApi } from "@/utils/api";
import mapObjectToQueryParams from "@/utils/mapObjectToQueryParams";

export default {
  getDeclarations({ rootState }, { page = 1 }) {
    const params = { client_id: rootState.user.selectedClient.id, page };
    const queryParams = mapObjectToQueryParams(params);

    return fetchFromApi(`/aml-declarations/?${queryParams}`);
  },
  createDeclaration({ rootState }) {
    return fetchFromApi(`/aml-declarations/`, {
      method: "POST",
      data: {
        client: {
          id: rootState.user.selectedClient.id,
        },
      },
    });
  },
  async loadDeclaration({ commit }, declarationId) {
    const declaration = await fetchFromApi(
      `/aml-declarations/${declarationId}/`,
    );
    commit("setDeclaration", declaration);
    return declaration;
  },

  async loadRepresentatives(
    { commit, state },
    declarationId = state?.declaration?.id,
  ) {
    const params = { aml_declaration: declarationId };
    const queryParams = mapObjectToQueryParams(params);

    const res = await fetchFromApi(
      `/aml-declaration-representatives/?${queryParams}`,
    );
    commit("setRepresentatives", res);
    return res;
  },

  async postRepresentative({ state }, data) {
    const params = { aml_declaration: state?.declaration?.id };
    const queryParams = mapObjectToQueryParams(params);

    const res = await fetchFromApi(
      `/aml-declaration-representatives/?${queryParams}`,
      {
        method: "POST",
        data: {
          aml_declaration: {
            id: state?.declaration?.id,
          },
          ...data,
        },
      },
    );
    return res;
  },

  async deleteRepresentative(context, id) {
    const res = await fetchFromApi(`/aml-declaration-representatives/${id}/`, {
      method: "DELETE",
    });
    return res;
  },

  async patchRepresentative(context, data) {
    const res = await fetchFromApi(
      `/aml-declaration-representatives/${data.id}/`,
      {
        method: "PATCH",
        data,
      },
    );
    return res;
  },

  async loadAuthorizedPersons(
    { commit, state },
    declarationId = state?.declaration?.id,
  ) {
    const params = { aml_declaration: declarationId };
    const queryParams = mapObjectToQueryParams(params);

    const res = await fetchFromApi(
      `/aml-declaration-authorized-people/?${queryParams}`,
    );
    commit("setAuthorizedPersons", res);
    return res;
  },

  async postAuthorizedPerson({ state }, data) {
    const params = { aml_declaration: state?.declaration?.id };
    const queryParams = mapObjectToQueryParams(params);

    const formData = new FormData();
    for (let key in data) {
      const field = data[key];
      if (typeof field === "undefined" || field === null) continue;
      formData.append(key, field);
    }
    formData.append("aml_declaration.id", state?.declaration?.id);
    const res = await fetchFromApi(
      `/aml-declaration-authorized-people/?${queryParams}`,
      {
        method: "POST",
        data: formData,
        formData: true,
      },
    );
    return res;
  },

  async deleteAuthorizedPerson(context, id) {
    const res = await fetchFromApi(
      `/aml-declaration-authorized-people/${id}/`,
      {
        method: "DELETE",
      },
    );
    return res;
  },

  deleteAuthorizedPersons({ dispatch }, ids) {
    const promises = ids.map((id) => dispatch("deleteAuthorizedPerson", id));
    return Promise.all(promises);
  },

  async patchAuthorizedPerson(context, data) {
    const formData = new FormData();
    for (let key in data) {
      const field = data[key];
      if (typeof field === "undefined" || field === null) continue;
      formData.append(key, field);
    }
    const res = await fetchFromApi(
      `/aml-declaration-authorized-people/${data.id}/`,
      {
        method: "PATCH",
        data: formData,
        formData: true,
      },
    );
    return res;
  },

  async loadBeneficialOwners(
    { commit, state },
    declarationId = state?.declaration?.id,
  ) {
    const params = { aml_declaration: declarationId };
    const queryParams = mapObjectToQueryParams(params);

    const res = await fetchFromApi(
      `/aml-declaration-beneficial-owners/?${queryParams}`,
    );
    commit("setBeneficialOwners", res);
    return res;
  },

  async postBeneficialOwner({ state }, data) {
    const params = { aml_declaration: state?.declaration?.id };
    const queryParams = mapObjectToQueryParams(params);

    const res = await fetchFromApi(
      `/aml-declaration-beneficial-owners/?${queryParams}`,
      {
        method: "POST",
        data: {
          aml_declaration: {
            id: state?.declaration?.id,
          },
          ...data,
        },
      },
    );
    return res;
  },

  async deleteBeneficialOwner(context, id) {
    const res = await fetchFromApi(
      `/aml-declaration-beneficial-owners/${id}/`,
      {
        method: "DELETE",
      },
    );
    return res;
  },

  async patchBeneficialOwner(context, data) {
    const res = await fetchFromApi(
      `/aml-declaration-beneficial-owners/${data.id}/`,
      {
        method: "PATCH",
        data,
      },
    );
    return res;
  },

  async loadSubmitters(
    { commit, state },
    declarationId = state?.declaration?.id,
  ) {
    const params = { aml_declaration: declarationId };
    const queryParams = mapObjectToQueryParams(params);

    const res = await fetchFromApi(
      `/aml-declaration-submitters/?${queryParams}`,
    );
    commit("setSubmitters", res);
    return res;
  },

  async postSubmitter({ state }, data) {
    const params = { aml_declaration: state?.declaration?.id };
    const queryParams = mapObjectToQueryParams(params);

    const res = await fetchFromApi(
      `/aml-declaration-submitters/?${queryParams}`,
      {
        method: "POST",
        data: {
          aml_declaration: {
            id: state?.declaration?.id,
          },
          ...data,
        },
      },
    );
    return res;
  },

  async deleteSubmitter(context, id) {
    const res = await fetchFromApi(`/aml-declaration-submitters/${id}/`, {
      method: "DELETE",
    });
    return res;
  },

  async patchSubmitter(context, data) {
    const res = await fetchFromApi(`/aml-declaration-submitters/${data.id}/`, {
      method: "PATCH",
      data,
    });
    return res;
  },

  setNextFormStep({ state, commit }) {
    const nextStep = state.activeFormStep + 1;
    if (!state.formSteps[nextStep]) return;

    commit("setActiveFormStep", nextStep);
  },
  setPrevFormStep({ state, commit }) {
    const prevStep = state.activeFormStep - 1;
    if (!state.formSteps[prevStep]) return;

    commit("setActiveFormStep", prevStep);
  },

  async patchDeclaration({ state, commit }, data) {
    const res = await fetchFromApi(
      `/aml-declarations/${state.declaration.id}/`,
      {
        method: "PATCH",
        data,
      },
    );
    commit("setDeclaration", res);
  },

  async completeDeclaration({ state, commit }) {
    const res = await fetchFromApi(
      `/aml-declarations/${state.declaration.id}/complete_data/`,
      {
        method: "POST",
      },
    );
    commit("setDeclaration", res);
  },

  async copyDeclaration(context, declarationId) {
    const res = await fetchFromApi(
      `/aml-declarations/${declarationId}/create_copy/`,
      {
        method: "POST",
      },
    );
    return res;
  },
};
