<template>
  <div>
    <label v-if="label || $slots.default" class="label">
      <slot>{{ label }}</slot> {{ required ? "*" : "" }}
    </label>
    <div
      class="input-select"
      :class="[
        rounded ? 'is-rounded' : '',
        disabled ? 'is-disabled' : '',
        color ? `input-select--${color}` : '',
        selectClass,
      ]"
    >
      <div class="select-wrap">
        <v-select
          class="v-select"
          :value="value"
          :options="options"
          :label="labelKey"
          :clearable="clearable"
          :searchable="searchable"
          :disabled="disabled"
          :placeholder="placeholder"
          :loading="loading"
          @input="(value) => $emit('input', value)"
        ></v-select>
      </div>
      <error-item v-if="errors" :errors="errors" />
    </div>
  </div>
</template>

<script>
export default {
  name: "InputSelect",
  inheritAttrs: false,
  props: {
    errors: {
      type: [Array, Object, String],
      default() {
        return {};
      },
    },
    value: {
      type: [String, Array, Object],
      default() {
        return "";
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default() {
        return "";
      },
    },
    selectClass: {
      type: String,
      default() {
        return "";
      },
    },
    labelKey: {
      type: String,
      default() {
        return "label";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  position: relative;
  min-width: 170px;
  max-width: 260px;
  max-height: 42px;
}
</style>

<style lang="scss">
.input-select {
  &.is-rounded {
    .v-select {
      .vs__dropdown-toggle {
        border: 1px solid #162056;
        border-radius: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 30px;
        padding-left: 6px;
        z-index: 6;
        position: relative;
      }

      .vs__open-indicator {
        width: 6.7px;
        height: 12px;
        background-size: 6.7px 12px;
        right: 18px;
      }

      ul {
        z-index: 5;
        background: white;
      }
    }
  }

  &.is-disabled {
    .v-select {
      .vs__open-indicator {
        display: none;
      }
    }
  }

  &.input-select--blue {
    .v-select {
      .vs__dropdown-toggle {
        border-color: #0085ca;
        background-color: #f4fafd;
        color: #1482c5;
      }

      .vs__selected {
        color: #1482c5;
      }

      ul {
        li {
          color: #1482c5;
          &:hover {
            background: #f2f2f2;
            color: #1482c5;
          }
        }
      }

      &.vs--disabled {
        .vs__search {
          background: #f4fafd;
        }
      }
    }
  }
}

.v-select {
  .vs__spinner {
    flex-shrink: 0;
  }

  .vs__dropdown-toggle {
    border: 0;
    border-width: 0 0 2px 0;
    border-color: #162056;
    background-color: white;
    padding: 6px 26px 6px 0;
    color: #162056;
    width: 100%;
    font-size: 1.06667rem;
    border-style: solid;
    border-radius: 0;
    overflow: hidden;
  }

  .vs__selected-options {
    max-height: 28px;
  }

  .vs__search,
  .vs__selected {
    max-width: 209px;
  }

  .vs__selected {
    color: #162056;
    max-height: 28px;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ul {
    li {
      color: #162056;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 9px;
      padding-bottom: 8px;
      white-space: normal;

      &:hover {
        background: #f2f2f2;
        color: #162056;
      }
    }
  }

  .vs__dropdown-option--highlight {
    background: #f2f2f2;
    color: #162056;
  }

  .vs__open-indicator {
    position: absolute;
    width: 10px;
    height: 18px;
    right: 8px;
    bottom: 12px;
    background: url("~@/images/icon-chevron-right.svg") no-repeat;
    transform: rotateZ(90deg);
    transform-origin: center;

    path {
      display: none;
    }
  }

  &.vs--open {
    .vs__open-indicator {
      transform: rotateZ(270deg);
    }
  }

  &.vs--disabled {
    .vs__dropdown-toggle,
    .vs__selected,
    .vs__search {
      cursor: default;
    }

    .vs__search {
      background: white;
    }
  }
}
</style>
