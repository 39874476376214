import {
  cardOrderAPIPoint,
  mbaAPIPoint,
  contractCardTypesAPIPoint,
  cardReloadOrderAPIPoint,
  submitOrderAPIPoint,
  orderAPIPoint,
  cardAPIPoint,
  generalOrderAPIPoint,
  cardsGetByOrdersAPIPoint,
  orderedCardAPIPoint,
} from "@/apiEndpoints";

import { fetchFromApi } from "@/utils/api";
import { hasAuthToken } from "@/utils/authToken";
import { obtainFetchError } from "@/utils/errors";
import mapObjectToQueryParams from "@/utils/mapObjectToQueryParams";

function getOrderUrl({ orderId, cardOrderId, cardReloadOrderId }) {
  return cardOrderId
    ? `${orderAPIPoint}${orderId}/?card_order=${cardOrderId}`
    : `${orderAPIPoint}${orderId}/?card_reload_order=${cardReloadOrderId}`;
}

export default {
  async initializeOrdersData({ dispatch }) {
    if (hasAuthToken()) {
      await dispatch("loadCardOrderOptions");
    }
  },
  async loadCardOrderOptions({ commit }) {
    const cardOrderOptions = await fetchFromApi(cardOrderAPIPoint, {
      method: "OPTIONS",
    });
    commit("setCardOrderOptions", cardOrderOptions);
  },
  async loadActiveOrderOptions({
    commit,
    getters: { getActiveOrderApiPoint },
  }) {
    const orderOptions = await fetchFromApi(getActiveOrderApiPoint, {
      method: "OPTIONS",
    });
    commit("setOrderOptions", orderOptions);
    return orderOptions;
  },
  async loadActiveOrder({ commit, getters: { getActiveOrderApiPoint } }) {
    const activeOrder = await fetchFromApi(getActiveOrderApiPoint, {
      method: "GET",
    });
    commit("setActiveOrder", activeOrder);
    return activeOrder;
  },
  async patchActiveOrder({ getters: { getActiveOrderApiPoint } }, data) {
    await fetchFromApi(getActiveOrderApiPoint, {
      method: "PATCH",
      data,
    });
  },
  async fetchOrderOptions(state, { orderId, cardReloadOrderId, cardOrderId }) {
    const url = getOrderUrl({ orderId, cardReloadOrderId, cardOrderId });
    const orderOptions = await fetchFromApi(url, {
      method: "OPTIONS",
    });
    return orderOptions;
  },
  async fetchOrder(state, { orderId, cardReloadOrderId, cardOrderId }) {
    const url = getOrderUrl({ orderId, cardReloadOrderId, cardOrderId });
    const order = await fetchFromApi(url, {
      method: "GET",
    });
    return order;
  },
  async patchOrder(state, { orderId, cardReloadOrderId, cardOrderId, data }) {
    const url = getOrderUrl({ orderId, cardReloadOrderId, cardOrderId });
    await fetchFromApi(url, {
      method: "PATCH",
      data,
    });
  },
  async getMbaNetworkTypes({ getters: { getActiveCardOrderId } }) {
    return await fetchFromApi(
      `${mbaAPIPoint}?card_order=${getActiveCardOrderId}`,
    );
  },
  async setMbaNetworkType(
    { getters: { getActiveCardOrderId } },
    { selected, id },
  ) {
    return await fetchFromApi(
      `${mbaAPIPoint}${id}/?card_order=${getActiveCardOrderId}`,
      {
        method: "PATCH",
        data: {
          selected,
        },
      },
    );
  },
  async getAvailableCardTypes({ getters: { getActiveProductTypeId } }) {
    return await fetchFromApi(
      // TODO: remove commercial_product_id from query params
      // commercial_product_id is deprecated, we use commercial_products_set__product_configuration instead
      `${contractCardTypesAPIPoint}?commercial_product_id=${getActiveProductTypeId}` +
        `&commercial_products_set__product_configuration=${getActiveProductTypeId}`,
    );
  },
  async handleExcelOrder(
    { getters: { getActiveCardOrderId } },
    { virtual = false } = {},
  ) {
    const importUrl = virtual
      ? `/import_source_file_virtual/`
      : `/import_source_file/`;
    return await fetchFromApi(
      `${cardOrderAPIPoint}${getActiveCardOrderId}${importUrl}`,
      {
        method: "POST",
      },
    );
  },
  async importCardsFromOrder(state, orderId) {
    const apiPoint = `${cardReloadOrderAPIPoint}import_cards_from_order/`;
    return await fetchFromApi(apiPoint, {
      method: "POST",
      data: {
        order_id: orderId,
      },
    });
  },
  async reloadActiveFormData({ getters: { getActiveCardOrderId }, commit }) {
    const activeFormData = await fetchFromApi(
      cardOrderAPIPoint + getActiveCardOrderId,
    );
    commit("setActiveForm", activeFormData);
  },
  async updateBillingData(
    { commit, getters: { getActiveCardOrderId } },
    { isBillingFinal = false } = {},
  ) {
    commit("setActiveFormBillingLoading", true);
    commit("setActiveFormBillingReloadError", null);

    try {
      const apiEndpoint = `${cardOrderAPIPoint}${getActiveCardOrderId}/billing/?verbose=true`;
      const billingResponse = await fetchFromApi(
        `${apiEndpoint}${isBillingFinal ? "&final=true" : ""}`,
      );

      commit("setActiveFormBilling", {
        billingArray: billingResponse.billing,
        isBillingFinal,
      });
    } catch (errorObj) {
      const { errors } = obtainFetchError(errorObj);
      commit("setActiveFormBillingReloadError", errors);
      throw errorObj;
    } finally {
      commit("setActiveFormBillingLoading", false);
    }
  },
  async resetBillingData({ commit }) {
    commit("setActiveFormBillingLoading", false);
    commit("setActiveFormBillingReloadError", null);
    commit("setActiveFormBilling", {
      billingArray: [],
      isBillingFinal: false,
    });
  },
  async handleNextFormStep({
    commit,
    state: { activeFormStep },
    getters: { formSteps },
  }) {
    if (activeFormStep + 1 < formSteps.length) {
      commit("goToNextFormStep");
      window.scrollTo(0, 0);
    }
  },
  async handlePreviousFormStep({ commit, state: { activeFormStep } }) {
    if (activeFormStep > 0) {
      commit("goToPreviousFormStep");
      window.scrollTo(0, 0);
    }
  },
  async submitActiveCardOrder({ getters: { getActiveCardOrderId } }) {
    return await fetchFromApi(
      `${cardOrderAPIPoint}${getActiveCardOrderId}${submitOrderAPIPoint}`,
      {
        method: "POST",
      },
    );
  },
  async getCards(
    state,
    {
      search = "",
      page = 1,
      ordering = false,
      shared = [],
      status = [],
      reloadable,
      card_type = "",
      commercial_product = "",
      id__in = [],
    } = {},
  ) {
    const params = {
      search,
      page,
      ordering,
      status,
      shared,
      reloadable,
      card_type,
      commercial_product,
      id__in,
    };
    const queryParams = mapObjectToQueryParams(params);

    return await fetchFromApi(`${cardAPIPoint}?${queryParams}`);
  },
  async loadCardsOptions({ commit }) {
    const response = await fetchFromApi(cardAPIPoint, {
      method: "OPTIONS",
    });
    commit("setCardsOptions", response);
  },
  async getOrders(
    state,
    {
      search = "",
      page = 1,
      status,
      ordering,
      reloadable,
      order_type = "all",
    } = {},
  ) {
    const params = { search, page, status, ordering, reloadable, order_type };
    const queryParams = mapObjectToQueryParams(params);

    return await fetchFromApi(`${generalOrderAPIPoint}?${queryParams}`);
  },
  async getCardsByOrders(state, ordersIdsArray) {
    return await fetchFromApi(cardsGetByOrdersAPIPoint, {
      method: "POST",
      data: { orders: ordersIdsArray },
    });
  },
  async unshareCard(state, { cardId, clientIds }) {
    return await fetchFromApi(`${cardAPIPoint}${cardId}/unshare/`, {
      method: "POST",
      data: {
        client_ids: clientIds,
      },
    });
  },
  async createCardOrder(state, productId) {
    return await fetchFromApi(cardOrderAPIPoint, {
      method: "POST",
      data: {
        product: productId,
      },
    });
  },
  async cancelOrder(state, orderId) {
    return await fetchFromApi(`${generalOrderAPIPoint}${orderId}/cancel/`, {
      method: "POST",
    });
  },
  async updateCards({ state: { activeOrder } }, cardsArray) {
    return await fetchFromApi(
      `${orderedCardAPIPoint}bulk_update/?card_order=${activeOrder.card_order}`,
      {
        method: "POST",
        data: cardsArray,
      },
    );
  },
};
