<template>
  <div class="info-box" :style="styleObject">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'info-box__icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.26 15.816a.703.703 0 010 1.406h-.754a2.21 2.21 0 01-2.209-2.21v-3.765a.05.05 0 00-.05-.05h-.753a.703.703 0 010-1.406h.753c.804 0 1.456.652 1.456 1.456v3.766c0 .443.36.803.803.803h.753zm-1.557-8.711a1.08 1.08 0 11-2.16 0 1.08 1.08 0 012.16 0zM12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-1.406c5.851 0 10.594-4.743 10.594-10.594 0-5.851-4.743-10.594-10.594-10.594C6.149 1.406 1.406 6.149 1.406 12c0 5.851 4.743 10.594 10.594 10.594z" fill="#162056"/></svg>
    <div class="info-box__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoBox",
  props: {
    marginTop: {
      type: String,
      default: "0px",
    },
    marginBottom: {
      type: String,
      default: "25px",
    },
  },
  computed: {
    styleObject() {
      return { marginTop: this.marginTop, marginBottom: this.marginBottom };
    },
  },
};
</script>

<style scoped lang="scss">
.info-box {
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 15px 20px;
  background-color: $info-box-background;
  border-radius: 5px;
  border: 1px solid $info-box-background;

  &__icon {
    max-width: 24px;
    min-width: 21px;
    margin-right: 17px;
    transform: translateY(-3px);
  }
  &__content {
    font-size: $summary-font-size;
    line-height: 19px;
  }

  &--yellow {
    border: 1px solid $color-yellow;
    background: $yellow-info-box-background;
    padding: 10px;
    svg path {
      fill: $color-yellow;
    }
    .info-box__icon {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }
    .info-box__content {
      color: $color-yellow;
    }
  }

  &--green {
    border: 1px solid $color-active;
    background: $accept-box-background;
    padding: 20px 13px 15px 20px;
    svg path {
      fill: $color-active;
    }
    .info-box__icon {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }
    .info-box__content {
      color: $color-active;
    }
  }
}
</style>
