import { getDefaultState } from "./state";

export default {
  resetMfaState(state) {
    Object.assign(state, getDefaultState());
  },
  setMfaAuthenticated(state, mfaAuthenticated) {
    state.mfaAuthenticated = mfaAuthenticated;
  },
  setMfaDeviceId(state, mfaDeviceId) {
    state.mfaDeviceId = mfaDeviceId;
  },
  setMfaDevices(state, mfaDevices) {
    state.mfaDevices = mfaDevices;
  },
};
