<template>
  <div class="error-view">
    <div class="error__modal">
      <p class="error__text">{{ message }}<br /></p>
      <button type="button" class="error__btn" @click="$emit('action-clicked')">
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  props: {
    message: { type: String, required: true },
    buttonLabel: { type: String, required: true },
  },
};
</script>

<style scoped lang="scss">
.error-view {
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.error__text {
  font-weight: $font-weight-bold;
  font-size: 22px;
  margin: 0;
}

.error__modal {
  background: white;
  max-width: 500px;
  width: 100%;
  margin-top: 175px;
  box-shadow: 0px 0px 30px rgba(126, 142, 170, 0.1);
  border-radius: 8px;
  padding: 40px 24px;
  margin-right: 20px;
  margin-left: 20px;
}

.error__btn {
  display: block;
  margin: 32px auto 0 auto;
  max-width: 280px;
  width: 100%;
  background: $color-primary;
  border-radius: 25px;
  padding: 10px 14px 12px;
  text-align: center;
  color: white;
  line-height: 18px;
  font-size: $basic-font-size;
  font-weight: $font-weight-bold;
}
</style>
