<template>
  <div>
    <h2 class="step-form__title">
      Osoba upoważniona do działania w imieniu klienta {{ index + 1 }}
    </h2>
    <text-input v-model="form.name" :errors="errors.name">
      Imiona i nazwiska
    </text-input>
    <text-input v-model="form.citizenship" :errors="errors.citizenship">
      Obywatelstwo
    </text-input>
    <p class="label">Posiada numer PESEL</p>
    <input-radio-group
      name="has_pesel"
      v-model="form.has_pesel"
      :items="hasPeselChoices"
      :active-radio="form.has_pesel"
      :errors="errors.has_pesel"
    />
    <text-input
      v-if="form.has_pesel"
      v-model="form.pesel"
      :errors="errors.pesel"
    >
      Numer PESEL
    </text-input>
    <template v-else>
      <input-date-picker v-model="form.birth_date" :errors="errors.birth_date">
        Data urodzenia
      </input-date-picker>
      <text-input v-model="form.birth_country" :errors="errors.birth_country">
        Państwo urodzenia
      </text-input>
    </template>
    <text-input
      v-model="form.identity_document_id"
      :errors="errors.identity_document_id"
    >
      Seria i numer dokumentu potwierdzającego tożsamość
    </text-input>
    <label class="label" for="authorization_document">
      Dodaj scan pełnomocnictwa *</label
    >
    <template v-if="typeof form.authorization_document === 'string'">
      <div class="file-info">
        <a
          class="link"
          download
          :href="form.authorization_document"
          target="_blank"
        >
          Pobierz
        </a>
        <action-button @click="form.authorization_document = null">
          Wybierz inny plik
        </action-button>
      </div>
    </template>
    <file-upload
      v-else
      file-upload-type="pdf"
      field-name="authorization_document"
      :instant-upload="false"
      :timeout="undefined"
      @file-added="handleAddFile"
      @file-removed="form.authorization_document = null"
    />
    <error-item :errors="errors.authorization_document" />
  </div>
</template>

<script>
import TextInput from "@/views/components/formFields/TextInput.vue";
import InputDatePicker from "@/views/components/formFields/InputDatePicker.vue";
import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";
import FileUpload from "@/views/components/formFields/FileUpload.vue";

export default {
  name: "AuthorizedPerson",
  components: {
    TextInput,
    InputDatePicker,
    InputRadioGroup,
    FileUpload,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    errors: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        localId: "",
        id: "",
        name: "",
        citizenship: "",
        has_pesel: true,
        pesel: "",
        birth_date: null,
        birth_country: "",
        identity_document_id: "",
        authorization_document: null,
      },
      hasPeselChoices: [
        {
          label: "tak",
          value: true,
        },
        {
          label: "nie",
          value: false,
        },
      ],
    };
  },
  watch: {
    form: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  created() {
    Object.keys(this.form).forEach((key) => (this.form[key] = this.value[key]));
    if (typeof this.form.has_pesel === "undefined") {
      this.form.has_pesel = true;
    }
  },
  methods: {
    handleAddFile(files) {
      this.form.authorization_document = files[0].file;
    },
  },
};
</script>

<style scoped>
.label {
  margin-bottom: 8px;
}

.link {
  font-size: 16px;
}

.file-info {
  display: flex;
  gap: 16px;
  align-items: center;
}
</style>
