import Vue from "vue";

function setObjectPropertyFromPath(obj, newPath, value) {
  if (newPath.length === 1) {
    obj[newPath[0]] = value;
  } else {
    let newObj = obj[newPath[0]];

    if (newObj === null) {
      // create the full path even if the object wasn't created before
      newObj = {};
      Vue.set(obj, newPath[0], newObj);
    }

    setObjectPropertyFromPath(newObj, newPath.slice(1), value);
  }
}

function getObjectPropertyFromPath(obj, pathToObjectProperty) {
  if (obj === null) {
    return null;
  }
  if (pathToObjectProperty.length === 1) {
    return obj[pathToObjectProperty[0]];
  } else {
    const newObj = obj[pathToObjectProperty[0]];
    return getObjectPropertyFromPath(newObj, pathToObjectProperty.slice(1));
  }
}

/**
 * Returns a property of an object `obj` given by `path`. The property can be many levels
 * deep - levels are separated by dots. The function returns `null` if a property is missing
 * at any level of the provided path.
 */
function getByPath(obj, path) {
  return getObjectPropertyFromPath(obj, path.split("."));
}

/**
 * Puts the value `value` in a property of an object `obj` given by `path`.
 * The property can be many levels deep - levels are separated by dots.
 * If any an intermediate object given by the path doesn't exists, it is automatically created.
 */
function setByPath(obj, path, value) {
  return setObjectPropertyFromPath(obj, path.split("."), value);
}

/**
 * Checks if object has truthy values
 * It's used in form components to check if object returned from backend
 * has any non empty strings, numbers or objects with at least one value
 */
function formObjectHasValues(obj) {
  if (!obj) {
    return false;
  }

  return Object.values(obj).some((val) => {
    if (Array.isArray(val) || typeof val === "string") {
      return val.length > 0;
    }
    if (typeof val === "number") {
      return Boolean(val) || val === 0;
    }
    if (typeof val === "object" && val !== null) {
      return Object.values(val).length > 0;
    }
    return false;
  });
}

export { getByPath, setByPath, formObjectHasValues };
