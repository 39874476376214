<template>
  <div>
    <input-radio-group
      v-model="chosenValue"
      :items="labelLocationChoices"
      name="envelope_location_choice"
      :active-radio="chosenValue"
      :disabled="updatingOrder"
      label-key="display_name"
      @input="handleSelectLocation"
    />
    <loading-indicator :loading="!labelLocationChoices || updatingOrder" />
    <error-item :errors="errors" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";
import updateOrderMixin from "@/mixins/updateOrderMixin";

import InputRadioGroup from "@/views/components/formFields/InputRadioGroup.vue";

export default {
  name: "EnvelopeLabelLocation",
  components: { LoadingIndicator, InputRadioGroup },
  mixins: [updateOrderMixin],
  data() {
    return {
      labelLocationChoices: [],
      chosenValue: null,
    };
  },
  computed: {
    ...mapState("user", ["userContract"]),
    ...mapState("orders", ["activeForm", "cardOrderOptions"]),
  },
  watch: {
    "activeForm.has_etui": {
      handler() {
        this.setChoices();
      },
    },
  },
  mounted() {
    const {
      activeForm: { label_location },
    } = this;
    this.chosenValue = label_location;
    this.setChoices();
  },
  methods: {
    ...mapActions("orders", ["updateBillingData"]),
    setChoices() {
      const {
        cardOrderOptions: {
          actions: {
            POST: {
              label_location: { choices },
            },
          },
        },
        activeForm: { has_etui },
        userContract: { can_add_etui },
      } = this;

      const hasEtui = can_add_etui && has_etui;
      if (hasEtui) {
        this.labelLocationChoices = choices;
      } else {
        // deleting etui related choices
        this.labelLocationChoices = choices.filter(
          (choice) => choice.value.indexOf("etui") === -1,
        );
        // setting defaut value if someone picked etui related option
        if (this.chosenValue && this.chosenValue.indexOf("etui") !== -1) {
          this.chosenValue = this.labelLocationChoices[0].value;
          this.handleSelectLocation(this.chosenValue);
        }
      }
    },
    async handleSelectLocation(label_location) {
      await this.updateOrder({ label_location });
      this.updateBillingData();
    },
  },
};
</script>
