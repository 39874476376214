import { cardShareAPIPoint } from "@/apiEndpoints";

import { fetchFromApi } from "@/utils/api";

export default {
  async shareCards(state, { clientId, cardsIds }) {
    return await fetchFromApi(cardShareAPIPoint, {
      method: "POST",
      data: {
        company: clientId,
        cards: cardsIds,
      },
    });
  },
};
