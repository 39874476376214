<template>
  <div
    class="data-list-item"
    :class="{
      'has-hidden-address': !isAddressVisible,
      'is-deletable': isDeletable,
    }"
  >
    <div class="data-list-column data-list-column__summary">
      <div class="data-list-card-type">
        {{ displayedLabel }}
      </div>
    </div>
    <div v-if="isAddressVisible" class="data-list-column data-list__address">
      {{ card.address_display }}
    </div>
    <div class="data-list-column">{{ card.amount | toPln }}</div>
    <div class="data-list-column">{{ card.quantity }} szt.</div>
    <div class="data-list-column">
      {{ (card.quantity * card.amount) | toPln }}
    </div>
    <div
      v-if="isDeletable"
      class="delete-data-item-container"
      @click="$emit('delete-card')"
    >
      <div v-if="!loading" class="delete-data-item"></div>
      <loading-indicator class="loading-indicator" :loading="loading" />
    </div>
  </div>
</template>

<script>
import LoadingIndicator from "@/views/components/utilityComponents/LoadingIndicator.vue";

export default {
  components: { LoadingIndicator },
  props: {
    card: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tableAddons: {
      type: Boolean,
      default: true,
    },
    isAddressVisible: {
      type: Boolean,
      default: true,
    },
    virtual: {
      type: Boolean,
      default: false,
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    displayedLabel() {
      return (
        this.label ||
        `Karta ${
          this.virtual
            ? "wirtualna"
            : this.card.has_label
            ? `z etykietą`
            : `bez etykiety`
        }`
      );
    },
  },
};
</script>

<style scoped lang="scss">
.data-list-column {
  text-align: center;
  width: 17.5%;
  flex-shrink: 0;

  &:first-child {
    width: 30%;
    text-align: left;
  }
}

.data-list-column__summary {
  align-items: start;
  flex-direction: column;
}

.data-list-card-type {
  font-size: $basic-font-size;
  font-weight: 500;
}

.data-list-card-summary {
  font-size: $small-font-size;
}

.loading-indicator {
  width: 26px;
  margin-left: 20px;
}

.delete-data-item-container {
  width: 46px;
  flex-shrink: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
}

.data-list__address {
  word-break: break-all;
}

.data-list-item {
  position: relative;

  &.is-deletable {
    padding-right: 46px;
  }

  &.has-hidden-address {
    .data-list-column {
      width: 23.3%;

      &:first-child {
        width: 30%;
      }
    }
  }
}
</style>
