<template>
  <label class="label-block label-radio">
    <input
      ref="input"
      type="radio"
      :value="value"
      :disabled="disabled"
      :checked="shouldBeChecked"
      v-bind="$attrs"
      v-on="inputListeners"
    />
    <span>
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    prop: "activeRadio",
    event: "change",
  },
  props: {
    value: { type: [String, Boolean, Number], required: true },
    activeRadio: { type: [String, Boolean, Object, Number], default: null },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputListeners() {
      const { $listeners, handleChange } = this;
      return {
        ...$listeners,
        change: handleChange,
      };
    },
    shouldBeChecked() {
      return this.activeRadio === this.value;
    },
  },
  methods: {
    handleChange() {
      this.$emit("change", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.label-block {
  display: block;
  margin-bottom: 10px;
}

.label-radio {
  display: flex;
  align-items: flex-start;
  font-size: $radio-font-size;

  input {
    flex-shrink: 0;
  }
}
</style>
