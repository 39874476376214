import { getDefaultState } from "./state";

export default {
  resetUserState(state) {
    Object.assign(state, getDefaultState());
  },
  setIsAuthenticated(state, payload) {
    state.authenticated = payload;
  },
  setUserContract(state, payload) {
    state.userContract = payload;
  },
  setDefaultClientContracts(state, payload) {
    state.defaultClientContracts = payload;
  },
  setDefaultContracts(state, payload) {
    state.defaultContracts = payload;
  },
  setClientDetails(state, clientDetails) {
    state.clientDetails = clientDetails;
  },
  setClientEInvoiceConsent: (state, value) => {
    state.clientDetails.e_invoice_consent = value;
  },
  setUserDetails(state, userDetails) {
    state.userDetails = userDetails;
  },
  deleteContactPerson(state, personId) {
    const personIndex = state.clientDetails.contact_persons.findIndex(
      (person) => person.id === personId,
    );
    state.clientDetails.contact_persons.splice(personIndex, 1);
  },
  deleteDeliveryAddress(state, addressId) {
    const addressIndex = state.clientDetails.delivery_addresses.findIndex(
      (address) => address.id === addressId,
    );
    state.clientDetails.delivery_addresses.splice(addressIndex, 1);
  },
  setSelectedClient(state, selectedClient) {
    state.selectedClient = selectedClient;
  },
  setCookiesConsent(state, cookiesConsent) {
    state.cookiesConsent = cookiesConsent;
  },
};
