<template>
  <app-popup>
    <template v-slot:title> Zamówienie zostało przesłane poprawnie. </template>

    <img
      class="excel-modal__img excel-success__img"
      src="~@/images/order-final-step.svg"
      alt=""
    />
    <p class="popup__subtitle">
      Gratulujemy! Udało się poprawnie załadować i zweryfikować przesłany plik.
      Upewnij się, że ilość adresów, kart i wartość doładowania jest poprawna:
    </p>

    <div class="text-center excel-modal__info--success">
      <p>Ilość kart: {{ successData.cards_count }}</p>
      <p>Ilość adresów wysyłki: {{ successData.addresses_count }}</p>
      <p>Łączna wartość doładowania kart: {{ successData.summed_amount }} zł</p>
    </div>

    <p class="text-center">
      Teraz możesz przejść do kroku z danymi do zamówienia.
    </p>

    <template v-slot:actions>
      <button class="button--primary" @click="$emit('popup-close')">
        Przejdź do kroku 4
      </button>
    </template>
  </app-popup>
</template>

<script>
import AppPopup from "@/views/components/utilityComponents/AppPopup.vue";

export default {
  name: "PopupExcellSuccess",
  components: {
    AppPopup,
  },
  props: {
    successData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.excel-success__img {
  max-width: 210px;
}

.excel-modal__info--success {
  p {
    font-weight: $font-weight-bold;
  }
}
</style>
