const CARD_TYPES = Object.freeze({
  notSelected: 0,
  withoutInscription: 1,
  withInscription: 2,
  virtual: 3,
});

const AML_STATE = Object.freeze({
  INITIALIZING: "initializing",
  DRAFT: "draft",
  GENERATING_DOCUMENT: "generating_document",
  AWAITING_CLIENT_CONFIRMATION: "awaiting_client_confirmation",
  AWAITING_INTERNAL_VALIDATION: "awaiting_internal_validation",
  ACCEPTED_CONDITIONALLY: "accepted_conditionally",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  EXPIRED: "expired",
  CANCELED: "canceled",
  ERROR: "error",
});

export { CARD_TYPES, AML_STATE };
